import { handleActions } from 'redux-actions';
import * as AT from 'redux/actions/auth';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { State } from 'redux/types';
import { storageParams } from 'routes/login/consts';

export interface AuthState {
	apiToken: string | null;
}

const initialState: AuthState = {
	apiToken: localStorage.getItem(storageParams.accessToken)
};

export const authReducer = handleActions(
	{
		[AT.SET_API_TOKEN]: (state, action: ReturnType<typeof AT.setAPIToken>) => ({
			...state,
			apiToken: action.payload.apiToken
		}),

		[AT.SIGN_OUT]: (state) => ({ ...state, apiToken: null })
	},
	initialState
);

export const useAuthSelector: TypedUseSelectorHook<AuthState> = (selector, ...args) =>
	useSelector((state: State) => selector(state.auth), ...args);
