import { ColumnType } from 'rc-table/es/interface';
import { SessionRecord, SessionStatus } from 'routes/sessions/redux/types';
import { formatDateWithTime } from 'utils/dates';
import { numberToCurrency } from 'utils/money';
import { CheckboxColumn, ItemNotFound, renderCheckboxColumn, renderOptionalCell } from 'components/table/table-utils';
import { Granularity, formatDueDate } from 'utils/sessions';
import CustomLabel from './components/duedate-label';
import { B2CPartnerId } from 'constants/global-consts';
import { TPartnerId } from 'redux/partners-slice';

type TDetails = Record<string, string>;
type TSLADataMap = Record<string, { label: string; fieldLabels: TDetails; overdueFields?: string[] }>;

export const slaDataMapping: TSLADataMap = {
	sessions: {
		label: 'Sessions',
		fieldLabels: {
			new: 'New',
			active: 'Active',
			onTime: 'On time',
			overdue: 'Overdue'
		},
		overdueFields: ['overdue']
	},
	responsesOnTime: {
		label: 'Responses on time',
		fieldLabels: {
			pendingResponse: 'Pending response',
			deliveredOutOfPending: 'Delivered / Pending',
			overdueOutOfPending: 'Overdue / Pending',
			onTimeOutOfDelivered: 'On time / Delivered',
			overdueOutOfDelivered: 'Overdue / Delivered',
			medianResponseTime: 'Median response time',
			avgResponseTime: 'Avg. response time'
		},
		overdueFields: ['overdueOutOfPending', 'overdueOutOfDelivered']
	},
	looksOnTime: {
		label: 'Looks on time',
		fieldLabels: {
			pendingLook: 'Pending look',
			deliveredOutOfPending: 'Delivered / Pending',
			overdueOutOfPending: 'Overdue / Pending',
			onTimeOutOfDelivered: 'On time / Delivered',
			overdueOutOfDelivered: 'Overdue / Delivered',
			medianResponseTime: 'Median delivery time',
			avgResponseTime: 'Avg. response time'
		},
		overdueFields: ['overdueOutOfPending', 'overdueOutOfDelivered']
	},
	looks: {
		label: 'Looks',
		fieldLabels: {
			totalFeedback: 'Total feedback',
			loveFeedback: 'Love feedback',
			restyleFeedback: 'Restyle feedback',
			medianTimeSpent: 'Median creation time',
			timeSpent: 'Avg creation time'
		}
	},
	restyles: {
		label: 'Restyles',
		fieldLabels: {
			totalFeedback: 'Total feedback',
			loveFeedback: 'Love feedback',
			restyleFeedback: 'Restyle feedback',
			medianTimeSpent: 'Median creation time',
			timeSpent: 'Avg creation time'
		}
	},
	items: {
		label: 'Items',
		fieldLabels: {
			totalFeedback: 'Total feedback',
			loveFeedback: 'Love feedback',
			restyleFeedback: 'Restyle feedback'
		}
	}
};

export const customStyle = {
	searchInput: {
		paddingLeft: '0px',
		left: '10px'
	}
};

export const sidebarItems = [
	{
		link: '/sessions/sla',
		name: 'SLAs',
		dataTestId: 'sla-btn'
	},
	{
		link: '/sessions/list',
		name: 'All Sessions',
		dataTestId: 'sessions-btn'
	}
];

const columnsConfigurationByPartner: Record<TPartnerId | 'default', ColumnTypeSort[]> = {
	[B2CPartnerId]: [
		{
			title: 'Created',
			dataIndex: 'created',
			key: 'created',
			sortable: true,
			render: formatDateWithTime
		},
		{
			title: 'Client name',
			dataIndex: 'clientName',
			key: 'clientName',
			render: renderOptionalCell
		},
		{
			title: 'Stylist',
			dataIndex: 'stylist',
			key: 'stylist',
			render: renderOptionalCell
		},
		{
			title: 'Plan',
			dataIndex: 'plan',
			key: 'plan',
			render: renderOptionalCell
		},
		{
			title: 'Due Date',
			dataIndex: 'dueDate',
			key: 'dueDate',
			sortable: true,
			render: (_, record: SessionRecord) => {
				const hasNoDuedate = checkForNoDuedate(record);
				return renderDuedate(record.dueDate, hasNoDuedate, Granularity.Day);
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: renderOptionalCell
		},
		{
			title: 'Total',
			dataIndex: 'total',
			key: 'total',
			render: numberToCurrency
		}
	],
	combined: [
		{
			title: 'Created',
			dataIndex: 'created',
			key: 'created',
			sortable: true,
			render: formatDateWithTime
		},
		{
			title: 'Client name',
			dataIndex: 'clientName',
			key: 'clientName',
			render: renderOptionalCell
		},
		{
			title: 'Partner',
			dataIndex: 'partnerName',
			key: 'partnerName',
			render: renderOptionalCell
		},
		{
			title: 'Stylist',
			dataIndex: 'stylist',
			key: 'stylist',
			render: renderOptionalCell
		},
		{
			title: 'Due Date',
			dataIndex: 'dueDate',
			key: 'dueDate',
			sortable: true,
			render: (_, record: SessionRecord) => {
				const hasNoDuedate = checkForNoDuedate(record);
				return renderDuedate(record.dueDate, hasNoDuedate, Granularity.Hour);
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: renderOptionalCell
		}
	],
	default: [
		{
			title: 'Created',
			dataIndex: 'created',
			key: 'created',
			sortable: true,
			render: formatDateWithTime
		},
		{
			title: 'Client name',
			dataIndex: 'clientName',
			key: 'clientName',
			render: renderOptionalCell
		},
		{
			title: 'Stylist',
			dataIndex: 'stylist',
			key: 'stylist',
			render: renderOptionalCell
		},
		{
			title: 'Due Date',
			dataIndex: 'dueDate',
			key: 'dueDate',
			sortable: true,
			render: (_, record: SessionRecord) => {
				const hasNoDuedate = checkForNoDuedate(record);
				return renderDuedate(record.dueDate, hasNoDuedate, Granularity.Hour);
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: renderOptionalCell
		},
		{
			title: 'Total',
			dataIndex: 'total',
			key: 'total',
			render: numberToCurrency
		}
	]
};

export const renderDuedate = (
	dueDate: string,
	hasNoDuedate: boolean,
	granularity: Granularity,
	todayDate = new Date()
) => {
	if (hasNoDuedate || !isValidDate(dueDate)) return <ItemNotFound />;
	const { text, type } = formatDueDate(new Date(dueDate), todayDate, granularity);
	return <CustomLabel text={text} type={type} />;
};

const isValidDate = (date: string) => new Date(date).toLocaleDateString() !== 'Invalid Date';

const checkForNoDuedate = (record: SessionRecord) =>
	!record.dueDate || record.status === SessionStatus.canceled || record.status === SessionStatus.completed;

interface ColumnTypeSort extends ColumnType<SessionRecord> {
	sortable?: boolean;
}

export const getColumnsConfig = (
	partnerId: TPartnerId | undefined,
	options: CheckboxColumn<SessionRecord>
): Array<ColumnTypeSort> => {
	const id = !parseInt(partnerId?? '') ? 'combined' : partnerId;
	const columnsConfig =
		columnsConfigurationByPartner[id as TPartnerId] || columnsConfigurationByPartner.default;
	return [renderCheckboxColumn<SessionRecord>(options), ...columnsConfig];
};
