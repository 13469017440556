export const palette = {
	positiveBadgeText: '#0e6245',
	positiveBadgeBackground: '#cbf4c9',
	neutralBadgeText: '#4f66b0',
	neutralBadgeBackground: '#e3e8ee',
	negativeBadgeText: '#983705',
	negativeBadgeBackground: '#f8e5b9',
	primaryColor: '#f88379',
	dangerColor: 'rgb(223, 27, 10)',
	primaryGray: '#f4f4f4',
	lightGray: '#8e8e93',
	borderGray: '#d9d9d9',
	darkGray: '#3a3a3c',
	lightWhite: '#00000008',
	white: 'white',
	black: 'black',
	gray: '#bebebe'
};
