import { useEffect, useState } from 'react';

type ModalState = 'pending' | 'completed';

export const useAsyncModalState = (options: { isInProgress: boolean; error: string | null; onClose: () => void }) => {
	const [modalState, setModalState] = useState<ModalState>();

	useEffect(() => {
		if (!options.isInProgress && modalState === 'pending') {
			setModalState('completed');
		}

		if (!options.isInProgress && modalState === 'completed' && !options.error) {
			options.onClose();
		}
	}, [modalState, setModalState, options]);

	return { modalState, setModalState };
};
