export interface NewInventoryState {
	retailers: RetailerRecord[];
	selectedRetailer: RetailerRecord;
	loadingRetailers: boolean;
}

export enum Feed {
	cj = 'cj',
	linkshare = 'linkshare',
	partnerize = 'partnerize',
	farfetch = 'farfetch',
	pepperjam = 'pepperjam',
	default = 'default'
}
export interface RetailerRecord {
	name: string;
	active: boolean;
	feed: Feed;
	updatedAt: string;
	totalItems: number;
	externalID: string;
	fileName: string;
	id: string;
	priority: number;
	websiteDomain: string;
	status?: RetailerStatus;
}

export interface FileCategory {
	active: boolean;
	primary: string;
}

export interface ItemCategory {
	secondary: string;
	general: GeneralCategory;
	active: boolean;
	primary: string;
	itemCategoryID: string;
}

export enum GeneralCategory {
	Activewear = 'Activewear',
	Bags = 'Bags',
	Dresses = 'Dresses',
	Jackets = 'Jackets',
	Jewelry = 'Jewelry',
	Jumpsuits = 'Jumpsuits',
	Loungewear = 'Loungewear',
	Makeup = 'Makeup',
	Pants = 'Pants',
	Shirts = 'Shirts',
	Shoes = 'Shoes',
	Shorts = 'Shorts',
	Skirts = 'Skirts',
	Sweaters = 'Sweaters',
	Swimsuits = 'Swimsuits',
	Underwear = 'Underwear',
	Accessories = 'Accessories',
	None = 'None'
}
export interface InventoryUrlState {
	search?: string;
	page?: number;
	feed?: string;
	keywords?: string;
	type?: string;
}

export enum RetailerStatus {
	Active = 'Active',
	Inactive = 'Inactive'
}

export enum CollectorActionType {
	pull = 'pull',
	store = 'store',
	test = 'test',
	update = 'update'
}

export interface PageInfo {
	total: number;
	hasNextPage: boolean;
	hasPreviousPage: boolean;
	startCursor?: string;
	endCursor?: string;
}

export interface PaginationParams {
	search?: string;
	first?: number;
	after?: string;
	last?: number;
	before?: string;
}
export interface PaginatedResults<T> {
	results: T[];
	pageInfo: PageInfo;
}

export interface ItemCategoryParams {
	category: {
		active: boolean;
		general: GeneralCategory;
	};
	retailerId: string;
	categoryId: string;
}

export interface RetailerItemRecord {
	id: string;
	sku: string;
	name: string;
	brand: string;
	size: string;
	category: string;
	updatedAt: string;
}
