import React from 'react';
import styled from '@emotion/styled';
import { palette } from 'constants/styles';

const Container = styled.div`
	width: 70px;
	height: 22px;
	line-height: 22px;
	text-align: center;
	border-radius: 4px;
	font-weight: 100;
	font-size: 14px;
	background-color: ${palette.neutralBadgeBackground};
	color: ${palette.neutralBadgeText};

	&.positive {
		background-color: ${palette.positiveBadgeBackground};
		color: ${palette.positiveBadgeText};
	}
	&.negative {
		background-color: ${palette.negativeBadgeBackground};
		color: ${palette.neutralBadgeText};
	}
`;

interface IChangeRateLabel {
	percentage: string;
}

const ChangeRateLabel: React.FC<IChangeRateLabel> = ({ percentage }) => {
	const percentageNumber = parseFloat(percentage);
	const className = (percentageNumber !== 0 && (percentageNumber < 0 ? 'negative' : 'positive')) || '';

	return <Container className={className}>{percentage}</Container>;
};

export default ChangeRateLabel;
