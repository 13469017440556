import React, { useMemo } from 'react';
import DashboardCard from 'routes/dashboard/components/dashboard-card';
import { Card } from 'constants/types';
import DatePeriodPicker from '../../../components/date-period-picker';
import AutoComplete from 'components/auto-complete/search-box';
import {
	DashboardHeader,
	DashboardContainer,
	CardsContainer,
	AutoCompleteTitle,
	AutoCompleteContainer
} from '../components';
import { useGetSessionMetricsQuery } from 'apiSlices';
import { ComparePeriod, DatePeriod } from 'routes/dashboard/utils/dates';
import { useURLState } from 'hooks';
import { SingleValue } from 'react-select';
import { useGetStylistNamesQuery } from 'apiSlices/stylist-slice';
import { useSelectedPartnerId } from 'redux/partners-slice';

export const SessionsDashboard: React.FC = () => {
	const { urlState, setURLState } = useURLState();
	const selectedPartnerId = useSelectedPartnerId();
	const { data: stylists } = useGetStylistNamesQuery({ partnerId: selectedPartnerId }, { skip: !selectedPartnerId });
	const { data: metrics } = useGetSessionMetricsQuery(
		{ params: urlState },
		{ skip: !(urlState.partnerId && urlState.rangeFrom) }
	);
	const range = DatePeriod.LAST_4_WEEKS;
	const compare = ComparePeriod.PREVIOUS_PERIOD;

	const stylistNamesList = useMemo(
		() =>
			stylists?.map((stylist) => ({
				label: stylist.name,
				value: stylist.uuid
			})) ?? [],
		[stylists]
	);

	const handleStylistChange = (stylist: SingleValue<{ label: string; value: string }>) => {
		if (stylist) {
			setURLState({ ...urlState, stylistUuid: stylist.value });
		} else {
			const newUrlState = { ...urlState };
			delete newUrlState.stylistUuid;
			setURLState(newUrlState);
		}
	};

	return (
		<DashboardContainer>
			<DashboardHeader>
				<DatePeriodPicker range={range} compare={compare} />

				<AutoCompleteContainer>
					<AutoCompleteTitle>Stylist Name</AutoCompleteTitle>
					<AutoComplete
						options={stylistNamesList}
						placeholder="Enter Stylist Name"
						onChange={handleStylistChange}
						value={urlState.stylistUuid}
					/>
				</AutoCompleteContainer>
			</DashboardHeader>

			<CardsContainer>
				{metrics?.map((card: Card, index) => (
					<DashboardCard key={index} {...card} />
				))}
			</CardsContainer>
		</DashboardContainer>
	);
};
