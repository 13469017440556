import React, { useEffect, useState } from 'react';
import Button from 'components/button';
import DropDown from 'components/drop-down';
import {
	Chevron,
	RetailerHeaderContainer,
	RetailerName,
	RetailerIndication,
	DropDownItem,
	DropDownContainer
} from './retailer-details.style';
import { RetailerRecord } from 'routes/inventory/redux/types';
import GenericModalMessege from '../modals/generic-messege-modal';
import UpdateShopRankingModal from '../modals/update-shop-ranking';
import { retailerActions } from 'routes/inventory/config';
import { ActionType } from 'routes/inventory/types';
import { useUpdateRetalierShopMutation, useUpdateRetalierStatusMutation } from 'routes/inventory/redux/inventory-slice';

export const RetailerHeader: React.FC<{ retailer: RetailerRecord }> = ({ retailer }) => {
	const [showActionModal, setShowActiveModal] = useState(false);
	const [showModalOnFailure, setShowModalOnFailure] = useState(false);
	const [shopRankingModal, setShopRankingModal] = useState(false);
	const [activeAction, setActiveAction] = useState<Record<string, any>>({});
	const [isActionsDropdownVisible, setIsActionsDropdownVisible] = useState(false);

	const [updateRetailerShop, { isError: isUpdateShopFail }] = useUpdateRetalierShopMutation();
	const [updateRetalierStatus, { isError: isUpdateStatusFail }] = useUpdateRetalierStatusMutation();

	const dispatchAction = (actionKey: string) => {
		switch (actionKey) {
			case ActionType.UpdateShop:
				updateRetailerShop({ id: retailer.id });
				return;
			case ActionType.Active:
				updateRetalierStatus({ id: retailer.id, active: true });
				return;
			case ActionType.Deactivated:
				updateRetalierStatus({ id: retailer.id, active: false });
				return;
		}
	};

	const openModal = (actionKey: string, actionIndex: number) => {
		setActiveAction(retailerActions[actionIndex]);
		actionKey !== ActionType.SetRanking ? setShowActiveModal(true) : setShopRankingModal(true);
	};

	useEffect(() => {
		if (isUpdateShopFail || isUpdateStatusFail) setShowModalOnFailure(true);
	}, [isUpdateShopFail, isUpdateStatusFail]);

	return (
		<>
			{shopRankingModal && (
				<UpdateShopRankingModal
					onClose={() => setShopRankingModal(false)}
					action={activeAction}
					retailer={retailer}
				/>
			)}

			{showActionModal && (
				<GenericModalMessege
					onClose={() => setShowActiveModal(false)}
					title={activeAction.title}
					isError={false}
					buttonText={activeAction.buttonText.replace('<retailer>', retailer.name)}
					message={activeAction.message.replace('<retailer>', retailer.name)}
					buttonAction={() => {
						dispatchAction(activeAction.key);
						setShowActiveModal(false);
					}}
				/>
			)}

			{showModalOnFailure && (
				<GenericModalMessege
					onClose={() => setShowModalOnFailure(false)}
					title="Error"
					isError={true}
					buttonText="Close"
					message={activeAction.error}
				/>
			)}

			<RetailerHeaderContainer>
				<RetailerName>
					{retailer.name}
					<RetailerIndication status={retailer.status}>{retailer.status}</RetailerIndication>
				</RetailerName>

				<DropDown
					trigger={
						<Button color="primary" data-testid="action-drop-down">
							Actions <Chevron />
						</Button>
					}
					isVisible={isActionsDropdownVisible}
					setIsVisible={setIsActionsDropdownVisible}
				>
					<DropDownContainer>
						{retailerActions.map((action, index) => (
							<DropDownItem
								key={index}
								data-testid={action.key}
								onClick={() => openModal(action.key, index)}
							>
								{action.key}
							</DropDownItem>
						))}
					</DropDownContainer>
				</DropDown>
			</RetailerHeaderContainer>
		</>
	);
};
