import { CreateNoteResponse, DeleteNoteResponse, FetchNotesResponse, Note } from 'redux/types';
import {
	AddUserResponse,
	TUserId,
	UsersRequestParams,
	UsersResponse,
	UserData,
	ResetUserPasswordResponse,
	UserDetailsResponse,
	UserExpertRequestParams,
	UserExpertResponse
} from './types';
import { emptySplitApi } from 'redux/base-api';
import { TPartnerId } from 'redux/partners-slice';

const apiWithTag = emptySplitApi.enhanceEndpoints({ addTagTypes: ['Users', 'UserDetails', 'UserNotes'] });

const fixBodytypeField = (userData: UserData) => {
	return { ...userData, userBodyType: !userData.userBodyType ? null : userData.userBodyType };
};

const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getUsers: build.query<UsersResponse, UsersRequestParams>({
			query: (params) => ({
				url: '/user/paginate',
				method: 'GET',
				params
			}),
			providesTags: (result, error, params) => [{ type: 'Users', id: 'List' }]
		}),
		getUserDetails: build.query<UserDetailsResponse, { userId: TUserId }>({
			query: ({ userId }) => ({
				url: `/user/${userId}`,
				method: 'GET'
			}),
			providesTags: (result, error, { userId }) => [{ type: 'UserDetails', id: userId }]
		}),
		addUser: build.mutation<AddUserResponse, { userData: UserData }>({
			query: ({ userData }) => ({
				url: `/user`,
				method: 'POST',
				body: fixBodytypeField(userData)
			}),
			invalidatesTags: (result, error) => (error ? [] : ['Users'])
		}),
		updateUser: build.mutation<AddUserResponse, { userId: TUserId; userData: UserData }>({
			query: ({ userId, userData }) => ({
				url: `/user/${userId}`,
				method: 'PUT',
				body: fixBodytypeField(userData)
			}),
			invalidatesTags: (result, error, { userId }) =>
				error ? [] : ['Users', { type: 'UserDetails', id: userId }]
		}),
		deleteUsers: build.mutation<any, { uuids: TUserId[]; partnerId: TPartnerId }>({
			query: ({ uuids, partnerId }) => ({
				url: `/user`,
				method: 'DELETE',
				params: { uuids, partnerId }
			}),
			invalidatesTags: (result, error) => (error ? [] : ['Users', 'UserDetails'])
		}),
		resetUserPassword: build.mutation<ResetUserPasswordResponse, { userId: TUserId }>({
			query: ({ userId }) => ({
				url: `/user/${userId}/password`,
				method: 'PATCH'
			})
		}),
		makeUserStylist: build.mutation<UserExpertResponse, { userId: TUserId; params: UserExpertRequestParams }>({
			query: ({ userId, params }) => ({
				url: `/user/${userId}/expert`,
				method: 'PUT',
				body: params
			}),
			invalidatesTags: (result, error, { userId }) =>
				error ? [] : ['Users', { type: 'UserDetails', id: userId }]
		}),
		getUserNotes: build.query<Note[], { userId: TUserId }>({
			query: ({ userId }) => ({
				url: `/user/${userId}/notes`,
				method: 'GET'
			}),
			transformResponse: (response: FetchNotesResponse) => response.notes,
			providesTags: (result, error, { userId }) => [{ type: 'UserNotes', id: userId }]
		}),
		addUserNote: build.mutation<CreateNoteResponse, { userId: string; text: string }>({
			query: ({ userId, text }) => ({
				url: `/user/${userId}/note`,
				method: 'POST',
				body: { text }
			}),
			invalidatesTags: (result, error, { userId }) => (error ? [] : [{ type: 'UserNotes', id: userId }])
		}),
		deleteUserNote: build.mutation<DeleteNoteResponse, { userId: TUserId; noteId: string }>({
			query: ({ userId, noteId }) => ({
				url: `/user/${userId}/note/${noteId}`,
				method: 'DELETE'
			}),
			invalidatesTags: (result, error, { userId }) => (error ? [] : [{ type: 'UserNotes', id: userId }])
		})
	})
});

export const {
	useGetUsersQuery,
	useGetUserDetailsQuery,
	useDeleteUsersMutation,
	useAddUserMutation,
	useUpdateUserMutation,
	useMakeUserStylistMutation,
	useResetUserPasswordMutation,
	useGetUserNotesQuery,
	useAddUserNoteMutation,
	useDeleteUserNoteMutation
} = extendedApi;
