import React from 'react';
import styled from '@emotion/styled';
import TopBar from 'components/top-bar';

const PageLayout: React.FC = ({ children }) => (
	<Container>
		<TopBar />

		<Content>{children}</Content>

		<br />
	</Container>
);

const Container = styled.div`
	height: 100%;
	width: 100%;
`;

const Content = styled.div`
	padding: 0 40px;
`;

export default PageLayout;
