import { handleActions } from 'redux-actions';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { State } from 'redux/types';
import { OrderDetailsState } from 'routes/order-details/redux/types';
import * as AT from 'routes/order-details/redux/actions';

const initialState: OrderDetailsState = {
	order: null,
	notes: [],
	items: [],
	modalError: null
};

export const orderDetailsReducer = handleActions(
	{
		[AT.FETCH_ORDER_DETAILS]: (state) => ({
			...state,
			order: null,
			notes: [],
			items: []
		}),
		[AT.FETCH_ORDER_NOTES]: (state) => ({ ...state, notes: [] }),
		[AT.FETCH_ORDER_ITEMS]: (state) => ({ ...state, items: [] }),

		[AT.SET_ORDER_DETAILS]: (state, action: ReturnType<typeof AT.setOrderDetails>) => ({
			...state,
			order: action.payload
		}),

		[AT.SET_ORDER_NOTES]: (state, action: ReturnType<typeof AT.setOrderNotes>) => ({
			...state,
			notes: action.payload.notes
		}),

		[AT.SET_ORDER_ITEMS]: (state, action: ReturnType<typeof AT.setOrderItems>) => ({
			...state,
			items: action.payload.items
		}),

		[AT.SET_ORDER_ITEM_PRICE]: (state, action: ReturnType<typeof AT.setOrderItemPrice>) => ({
			...state,
			items: state.items.map((item) =>
				item.uuid === action.payload.itemId
					? { ...item, amount: { ...item.amount, price: action.payload.price } }
					: item
			)
		}),

		[AT.SET_ORDER_ITEM_ORDER_NUMBER]: (state, action: ReturnType<typeof AT.setOrderItemOrderNumber>) => ({
			...state,
			items: state.items.map((item) =>
				item.uuid === action.payload.itemId ? { ...item, orderNumber: action.payload.orderNumber } : item
			)
		}),

		[AT.SET_ORDER_ITEM_TRACKING_NUMBER]: (state, action: ReturnType<typeof AT.setOrderItemTrackingNumber>) => ({
			...state,
			items: state.items.map((item) =>
				item.uuid === action.payload.itemId ? { ...item, trackingNumber: action.payload.trackingNumber } : item
			)
		}),

		[AT.SET_ORDER_ITEM_TRACKING_STATUS]: (state, action: ReturnType<typeof AT.setOrderItemStatus>) => ({
			...state,
			items: state.items.map((item) =>
				item.uuid === action.payload.itemId ? { ...item, status: action.payload.status } : item
			)
		}),

		[AT.SET_MODAL_ERROR]: (state, action: ReturnType<typeof AT.setModalError>) => ({
			...state,
			modalError: action.payload.error
		})
	},
	initialState
);

export const useOrderDetailsSelector: TypedUseSelectorHook<OrderDetailsState> = (selector, ...args) =>
	useSelector((state: State) => selector(state.orderDetails), ...args);
