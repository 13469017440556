import styled from '@emotion/styled/macro';
import { palette } from 'constants/styles';
import React from 'react';

export type IToggleTheme = 'primary' | 'secondary';

interface IToggle {
	theme?: IToggleTheme;
	isChecked: boolean;
	id: string;
	onChange: (isChecked: boolean) => void;
}

const Toggle: React.FC<IToggle> = ({ isChecked, onChange, id, theme = 'primary' }) => {
	return (
		<CheckBoxWrapper>
			<CheckBox
				checked={isChecked}
				id={id}
				type="checkbox"
				theme={theme}
				onChange={(event) => onChange(event.target.checked)}
			/>
			<CheckBoxLabel htmlFor={id}>
				<p>{isChecked ? 'ON' : 'OFF'}</p>
			</CheckBoxLabel>
		</CheckBoxWrapper>
	);
};

const CheckBoxWrapper = styled.div`
	position: relative;
`;
const CheckBoxLabel = styled.label`
	position: absolute;
	top: 1px;
	left: 0;
	width: 42px;
	height: 26px;
	border-radius: 15px;
	background: ${palette.gray};
	cursor: pointer;
	display: flex;
	align-items: center;
	font-size: 12px;
	color: ${palette.white};
	width: fit-content;
	padding: 5px;
	p {
		padding: 2px;
		margin-top: 5px;
	}
	&::after {
		content: '';
		display: block;
		border-radius: 50%;
		width: 18px;
		height: 18px;
		margin: 3px;
		background: ${palette.white};
		box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
		transition: 0.2s;
	}
`;
const CheckBox = styled.input<{ theme: IToggleTheme }>`
	opacity: 0;
	z-index: 1;
	border-radius: 15px;
	width: 42px;
	height: 26px;
	&:checked + ${CheckBoxLabel} {
		flex-direction: row-reverse;
		background: ${({ theme }) => (theme === 'primary' ? palette.primaryColor : palette.darkGray)};
		&::after {
			content: '';
			display: block;
			border-radius: 50%;
			width: 18px;
			height: 18px;
			transition: 0.2s;
		}
	}
`;

export default Toggle;
