import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Global } from '@emotion/react';
import globalCss from 'constants/global-styles';
import Orders from 'routes/orders';
import Login from 'routes/login';
import RedirectHandler from 'routes/login/redirect-handler';
import OrderDetails from 'routes/order-details';
import Coupons from 'routes/coupons';
import store from 'redux/store';
import ProtectedRoute from 'components/protected-route';
import DashboardPage from 'routes/dashboard/dashboard-page';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import { extractUserData } from 'utils/helpers';
import { RetailerDetails } from 'routes/inventory/components/retailers/retailer-details';
import { RetailersList } from 'routes/inventory/components/retailers/retailers-list';
import { Sessions, SessionsPage, SessionsDashboard, SessionDetails, Users, UsersPage, UserDetails } from 'routes';
import B2BRoutes from './components/b2b-routes';
import { Navigate } from 'react-router';

const App = () => {
	const ldClient = useLDClient();
	useEffect(() => {
		const jwtToken = localStorage.getItem('access-token');
		if (jwtToken) {
			const userInfo = extractUserData(jwtToken);
			if (ldClient && userInfo) ldClient.identify(userInfo, undefined);
		}
	}, [ldClient]);

	return (
		<Provider store={store}>
			<BrowserRouter>
				<Global styles={globalCss} />

				<Routes>
					<Route path="/login" element={<Login />} />
					<Route path="/redirect" element={<RedirectHandler />} />

					<Route element={<ProtectedRoute />}>
						<Route path="/users" element={<Users />}>
							<Route path="/users/" element={<UsersPage />} />
							<Route path="/users/:id" element={<UserDetails />} />
						</Route>
						<Route path="/sessions" element={<Sessions />}>
							<Route path="" element={<Navigate to="sla" replace />} />
							<Route path="list" element={<SessionsPage />} />
							<Route path="sla" element={<SessionsDashboard />} />
							<Route path=":id/*" element={<SessionDetails />} />
						</Route>

						<Route path="/orders" element={<B2BRoutes />}>
							<Route path="/orders" element={<Orders />} />
							<Route path="/orders/:id" element={<OrderDetails />} />
						</Route>
						<Route path="/coupons" element={<B2BRoutes />}>
							<Route path="/coupons" element={<Coupons />} />
						</Route>
						<Route path="/inventory/retailers" element={<B2BRoutes />}>
							<Route path="/inventory/retailers" element={<RetailersList />} />
							<Route path="/inventory/retailers/:id" element={<RetailerDetails />} />
						</Route>
						<Route path="" element={<B2BRoutes />}>
							<Route path="" element={<DashboardPage />} />
						</Route>
					</Route>
				</Routes>
			</BrowserRouter>
		</Provider>
	);
};

export default withLDProvider({
	clientSideID: process.env.REACT_APP_LAUNCHDARKLEY_SDK_KEY ?? '',
	user: {
		key: '00000000-0000-0000-a'
	}
})(App);
