import React, { useState } from 'react';
import Modal, { ModalActions, ModalText } from 'components/modal';
import styled from '@emotion/styled';
import RadioGroupController from 'components/form/radio-group-controller';
import CheckboxListController from 'components/form/checkbox-list-controller';
import Button from 'components/button';
import { DateRange } from 'redux/types';
import DateRangeController from 'components/form/date-range-controller';
import { formatDateForController } from 'utils/dates';
import { useAsyncModalState } from 'utils/use-async-modal-state';

interface IExportModal {
	exportType: 'sessions' | 'users' | 'orders';
	exportFields: Array<string>;
	onClose: () => void;
	onSubmit: (form: any) => void;
	isInProgress: boolean;
}

interface ICustomDateRange {
	'customRange.from': string;
	'customRange.to': string;
}

const dateRanges: Array<DateRange> = ['today', 'all', 'currentMonth', 'custom'];

const ExportModal: React.FC<IExportModal> = ({ exportType, exportFields, onClose, onSubmit, isInProgress }) => {
	const [selectedDateRange, setSelectedDateRange] = useState(dateRanges[0]);
	const [selectedColumns, setSelectedColumns] = useState<Array<string>>([]);
	const [customDateRange, setCustomDateRange] = useState<ICustomDateRange>({
		'customRange.from': formatDateForController(),
		'customRange.to': formatDateForController()
	});

	const allColumnsSelected = selectedColumns.length === exportFields.length;

	const selectDeselect = () => {
		setSelectedColumns(allColumnsSelected ? [] : exportFields);
	};

	const { modalState, setModalState } = useAsyncModalState({
		isInProgress,
		error: null,
		onClose
	});

	const submitForm = () => {
		setModalState('pending');
		onSubmit({
			dateRange: selectedDateRange,
			columns: selectedColumns,
			...(selectedDateRange === 'custom' ? { ...customDateRange } : {})
		});
	};

	if (modalState === 'completed') {
		return (
			<Modal title={`Export ${exportType}`} onClose={onClose}>
				<ModalText>
					Your export is being processed.
					<br />
					Once it’s completed it’ll be sent to your email.
				</ModalText>
				<ModalActions>
					<Button color="primary" onClick={onClose}>
						Close
					</Button>
				</ModalActions>
			</Modal>
		);
	}

	return (
		<Modal title={`Export ${exportType}`} onClose={onClose}>
			<form
				onSubmit={(event) => {
					event.preventDefault();
					submitForm();
				}}
			>
				<ExportModalBody>
					<ExportDateRange>
						<ExportSubtitle>Date range</ExportSubtitle>
						<RadioGroupController
							name="exportDateRange"
							items={dateRanges}
							value={selectedDateRange}
							onChange={(value) => {
								setSelectedDateRange(value as DateRange);
							}}
						/>
						{selectedDateRange === 'custom' && (
							<CustomDateRange>
								<DateRangeController
									name="customRange"
									fromValue={customDateRange['customRange.from']}
									toValue={customDateRange['customRange.to']}
									onChange={(e) => {
										setCustomDateRange((prev) => ({
											...prev,
											[e.target.name]: e.target.value
										}));
									}}
								/>
							</CustomDateRange>
						)}
					</ExportDateRange>

					<ExportDateColumns>
						<ExportSubtitle>Columns</ExportSubtitle>
						<CheckboxListController
							value={selectedColumns}
							columns={3}
							items={exportFields}
							onChange={setSelectedColumns}
						/>
						<SelectDeselectButton onClick={selectDeselect}>
							{allColumnsSelected ? 'Deselect all' : 'Select all'}
						</SelectDeselectButton>
					</ExportDateColumns>

					<ModalActions>
						<Button color="primary" disabled={!selectedColumns.length}>
							Export
						</Button>
					</ModalActions>
				</ExportModalBody>
			</form>
		</Modal>
	);
};

export const ExportModalBody = styled.div`
	width: 600px;
`;

export const ExportDateRange = styled.div`
	padding: 1.5rem;
`;

export const ExportSubtitle = styled.div`
	font-size: 0.9rem;
	font-weight: 500;
	margin-bottom: 1.5rem;
`;

export const ExportDateColumns = styled.div`
	padding: 1.5rem;
`;

export const SelectDeselectButton = styled.div`
	font-size: 0.9rem;
	font-weight: 500;
	margin-top: 1.5rem;

	:hover {
		cursor: pointer;
		text-decoration: underline;
	}
`;

const CustomDateRange = styled.div`
	margin-top: 1.5rem;
	width: 66.666%;
`;

export default ExportModal;
