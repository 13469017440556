import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Button from 'components/button';
import Checkbox from 'components/checkbox';
import { palette } from 'constants/styles';
import { IFilterItem, IFilterState, TDateFilterProps, TNumberFilterProps } from 'constants/types';
import InputController from 'components/form/input-controller';
import NumberController from 'components/form/number-controller';
import DateRangeController from 'components/form/date-range-controller';
import RadioGroupController from 'components/form/radio-group-controller';
import SelectController from 'components/form/select-controller';
import AutoComplete from 'components/auto-complete/search-box';
import { AutoCompleteOptions } from './types';
import { useFiltersURLState } from '../../hooks';

type TProps = {
	filters: Array<IFilterItem>;
	onSubmit: (filterState: IFilterState) => void;
	autoCompleteOptions?: Record<string, AutoCompleteOptions[]>;
};

const TableMenuFilters: React.FC<TProps> = ({ filters, onSubmit, autoCompleteOptions }) => {
	const [filtersState, setFiltersState] = useState<IFilterState>({});
	const { filtersURLState, setFiltersURLState } = useFiltersURLState(filters);

	const selectFilter = (id: string) => {
		setFiltersState((prev) => {
			const newState = { ...prev };

			if (prev[id] !== undefined) {
				delete newState[id];
			} else {
				const filter = filters.find((filter) => filter.id === id);

				if (filter) {
					switch (filter.controller) {
						case 'number':
							newState[id] = { comparator: 'GT' } as TNumberFilterProps;
							break;
						case 'date':
							newState[id] = {} as TDateFilterProps;
							break;
						default:
							newState[id] = '';
					}
				}
			}

			return newState;
		});
	};

	const clearAllFilters = () => {
		setFiltersState({});
	};

	const isFilterChecked = (id: string) => {
		return filtersState[id] !== undefined;
	};

	const submit = () => {
		setFiltersURLState(filtersState);
		onSubmit(filtersState);
	};

	const getController = (filter: IFilterItem) => {
		const { id, controller, controllerItems = [], placeHolder } = filter;
		const filterState = filtersState[id];

		switch (controller) {
			case 'autocomplete':
				return (
					<AutoComplete
						options={autoCompleteOptions ? autoCompleteOptions[id] : []}
						placeholder={placeHolder}
						value={filterState}
						onChange={(option) =>
							setFiltersState((prev) => ({
								...prev,
								[id]: option?.value ?? ''
							}))
						}
					/>
				);

			case 'text':
				return (
					<InputController
						type="text"
						name={id}
						value={filterState as string}
						onChange={(event) =>
							setFiltersState((prev) => ({
								...prev,
								[id]: event.target.value
							}))
						}
					/>
				);

			case 'select':
				return (
					<SelectController
						name={id}
						options={controllerItems}
						value={filterState as string}
						onChange={(event) => {
							setFiltersState((prev) => ({
								...prev,
								[event.target.name]: event.target.value
							}));
						}}
					/>
				);

			case 'number':
				const { comparator, amount = [] } = filterState as TNumberFilterProps;
				return (
					<NumberController
						name={id}
						comparatorValue={comparator}
						onComparatorChange={(comparator) => {
							setFiltersState((prev) => ({
								...prev,
								[id]: { ...(prev[id] as TNumberFilterProps), comparator }
							}));
						}}
						value={amount}
						onValueChange={(newValue) => {
							setFiltersState((prev) => {
								const newFilterState = {
									...(prev[id] as TNumberFilterProps),
									amount: newValue
								};

								return {
									...prev,
									[id]: newFilterState
								};
							});
						}}
					/>
				);

			case 'date-range':
				const { from, to } = filterState as TDateFilterProps;
				return (
					<DateRangeController
						type="date"
						name={id}
						fromValue={from}
						toValue={to}
						onChange={(event) => {
							setFiltersState((prev) => {
								const { value, name } = event.target;
								const propName = name === `${id}.from` ? 'from' : 'to';
								const newFilterState = { ...(prev[id] as TDateFilterProps), [propName]: value };

								return {
									...prev,
									[id]: newFilterState
								};
							});
						}}
					/>
				);

			case 'radio-list':
				return (
					<RadioGroupController
						name={id}
						theme="secondary"
						items={controllerItems}
						value={filterState as string}
						onChange={(value) => {
							setFiltersState((prev) => ({
								...prev,
								[id]: value
							}));
						}}
					/>
				);

			case 'radio-list-column':
				return (
					<RadioGroupController
						name={id}
						theme="secondary"
						items={controllerItems}
						value={filterState as string}
						onChange={(value) => {
							setFiltersState((prev) => ({
								...prev,
								[id]: value
							}));
						}}
						column
					/>
				);
		}
	};

	useEffect(() => {
		setFiltersState(filtersURLState);
	}, [filtersURLState]);

	return (
		<Container>
			<MenuHeader>
				<Button onClick={clearAllFilters} size="small">
					Clear
				</Button>
				<Title>Filters</Title>
				<Button size="small" color="primary" onClick={submit}>
					Done
				</Button>
			</MenuHeader>

			{filters.map((filter) => {
				const { id, displayName } = filter;
				const isChecked = isFilterChecked(id);

				return (
					<FilterItem key={id}>
						<FlexDiv>
							<Checkbox isChecked={isChecked} onChange={() => selectFilter(id)} />
							<Text>{displayName}</Text>
						</FlexDiv>

						{isChecked && <FilterBody>{getController(filter)}</FilterBody>}
					</FilterItem>
				);
			})}
		</Container>
	);
};

const Container = styled.div`
	border-radius: 4px;
	box-sizing: content-box;
	min-width: 300px;
	max-width: 400px;
	border: 2px solid ${palette.primaryGray};
	background-color: white;
	box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.03);
`;

const FlexDiv = styled.label`
	display: flex;
	align-items: center;
	padding: 10px;
	cursor: pointer;
`;

const MenuHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 13px;
`;

const Title = styled.div`
	font-size: 16px;
	font-weight: 300;
`;

const Text = styled.div`
	font-size: 14px;
	font-weight: 300;
	margin-left: 7px;
`;

const FilterItem = styled.div`
	border-bottom: 1px solid ${palette.primaryGray};

	:last-child {
		border-bottom: 0;
	}
`;

const FilterBody = styled.div`
	background: ${palette.primaryGray};
	padding: 15px 10px;
`;

export default TableMenuFilters;
