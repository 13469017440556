import { FC, useRef } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as ChevronIcon } from 'assets/images/chevron.svg';
import { palette } from '../constants/styles';

import { useGetPartnersQuery } from 'apiSlices';
import { useNavigate } from 'react-router-dom';
import { TPartnerId, useSelectedPartnerId } from 'redux/partners-slice';

const Container = styled.div`
	position: relative;
	font-size: 14px;
	font-weight: 100;

	&:focus ul {
		display: block;
	}

	ul {
		display: none;
	}
`;

const CurrentSelection = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`;

const PartnerDetails = styled.div`
	display: flex;
	align-items: center;
`;

const Chevron = styled(ChevronIcon)`
	margin-left: 5px;
	cursor: pointer;
`;

const Menu = styled.ul`
	position: absolute;
	list-style: none;
	width: 254px;
	background: ${palette.white};
	border: 1px solid ${palette.primaryGray};

	li {
		padding: 4px 10px;
		cursor: pointer;

		&:hover {
			background: ${palette.primaryColor};
			color: ${palette.white};
		}
	}
`;

const PartnerLogoImage = styled.img`
	width: 28px;
	height: 28px;
	object-fit: contain;
`;

const PartnerLogoAlt = styled.div`
	width: 28px;
	height: 28px;
	border-radius: 50px;
	color: ${palette.white};
	background: ${palette.darkGray};
	text-transform: capitalize;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	padding-top: 3px;
`;

const PartnerLabel = styled.div`
	margin-left: 10px;
`;

const PartnerLogo: FC<{ url?: string; name?: string }> = ({ url, name = '' }) => {
	return url ? <PartnerLogoImage src={url} alt={name} /> : <PartnerLogoAlt>{name.substring(0, 1)}</PartnerLogoAlt>;
};

export const PartnerPicker: FC = () => {
	const containerElementRef = useRef<HTMLDivElement>(null);
	const { data: partners } = useGetPartnersQuery({});
	const navigate = useNavigate();
	const selectedPartnerId = useSelectedPartnerId();

	const selectedPartner = partners?.find(({ id }) => id === selectedPartnerId);

	const pickPartner = (partnerId: TPartnerId) => {
		(containerElementRef.current as HTMLElement).blur();
		navigate(`/?partnerId=${partnerId}`);
	};

	return partners?.length ? (
		<Container tabIndex={0} ref={containerElementRef}>
			<CurrentSelection>
				<PartnerDetails>
					<PartnerLogo url={selectedPartner?.iconLogoUrl} name={selectedPartner?.name} />
					<PartnerLabel>{selectedPartner?.name}</PartnerLabel>
				</PartnerDetails>
				<Chevron />
			</CurrentSelection>
			<Menu>
				{partners.map(({ id, name, iconLogoUrl }) => (
					<li key={id} onClick={() => pickPartner(id)}>
						<PartnerDetails>
							<PartnerLogo url={iconLogoUrl} name={name} />
							<PartnerLabel>{name}</PartnerLabel>
						</PartnerDetails>
					</li>
				))}
			</Menu>
		</Container>
	) : (
		<></>
	);
};
