import React, { useEffect, useState } from 'react';

import PageLayout from 'components/page-layout';
import Table from 'components/table/table';
import TypeFilters from 'components/table/type-filters';
import TableActions from 'components/table/table-actions';
import TableNoResults from 'components/table/table-no-results';
import { menuFilters, quickFilters } from 'routes/coupons/filters';
import { getColumnsConfig } from 'routes/coupons/config';
import { useRowSelectionState } from 'utils/use-row-selection-state';
import AddEditCouponModal from './components/add-edit-coupon-modal';
import { useNetworkSelector } from 'redux/reducers/network';
import { useCouponsSelector } from 'routes/coupons/redux/reducer';
import { CouponRecord, CreateCouponRequest } from 'routes/coupons/redux/types';
import { useDispatch } from 'react-redux';
import { useURLState } from 'hooks';
import { addCoupon, deactivateCoupons, fetchCoupons, clearModalError } from 'routes/coupons/redux/actions';
import TableResults from 'components/table-results';
import GenericActionModal from 'components/generic-action-modal';

const CouponsPage = () => {
	const { isLoading, isAddingCoupon } = useNetworkSelector((state) => ({
		isLoading: state.coupons > 0,
		isAddingCoupon: state.addCoupon > 0
	}));

	const { coupons, paginationData, modalError, campaigns } = useCouponsSelector((state) => ({
		coupons: state.coupons,
		paginationData: state.paginationMeta,
		modalError: state.modalError,
		campaigns: state.campaigns
	}));

	const dispatch = useDispatch();
	const { urlState } = useURLState();
	const rowKey = 'promoCode';

	const { selectedRowsIds, isRowSelected, toggleRow, deselectAllRows, toggleAllRows } = useRowSelectionState({
		rowKey,
		records: coupons
	});

	const [showAddCouponModal, setShowAddCouponModal] = useState(false);
	const [showDeactivateCouponsModal, setShowDeactivateCouponsModal] = useState(false);

	const columns = getColumnsConfig({
		allRowsSelected: selectedRowsIds.length === coupons.length,
		isRowSelected,
		toggleAllRows,
		toggleRow
	});

	const actionsList = [
		{
			text: 'Add new',
			onActivate: () => setShowAddCouponModal(true)
		}
	];

	useEffect(() => {
		urlState.partnerId && dispatch(fetchCoupons(urlState));
	}, [urlState, dispatch]);

	return (
		<PageLayout>
			{showAddCouponModal && (
				<AddEditCouponModal
					action="add"
					onClose={() => {
						setShowAddCouponModal(false);
						dispatch(clearModalError());
					}}
					isInProgress={isAddingCoupon}
					error={modalError}
					onSubmit={(coupon: CreateCouponRequest) => {
						dispatch(clearModalError());
						dispatch(addCoupon(coupon, urlState));
					}}
				/>
			)}

			{showDeactivateCouponsModal && (
				<GenericActionModal
					title="Deactivate coupons"
					body="This will permanently remove the coupon from the system. Make sure no one is using this coupon before deleting it."
					actionButtonText="Deactivate coupons"
					onClose={() => setShowDeactivateCouponsModal(false)}
					onSubmit={() => {
						dispatch(deactivateCoupons(selectedRowsIds, urlState));
						setShowDeactivateCouponsModal(false);
					}}
				/>
			)}

			<TableActions
				tableTitle="Coupons"
				selectedRows={selectedRowsIds}
				onDeselectAll={deselectAllRows}
				onDelete={() => setShowDeactivateCouponsModal(true)}
				quickFilters={quickFilters}
				filters={menuFilters(campaigns)}
				actionsList={actionsList}
			/>

			<TypeFilters filters={quickFilters} />

			{isLoading && 'Loading coupons...'}

			{!isLoading && paginationData.totalItems && <TableResults paginationData={paginationData} />}

			{!isLoading && coupons.length === 0 && <TableNoResults />}

			{!isLoading && coupons.length > 0 && (
				<Table<CouponRecord>
					columns={columns}
					records={coupons}
					rowKey={rowKey}
					selectedRowsIds={selectedRowsIds}
					isClickable={true}
				/>
			)}
		</PageLayout>
	);
};

export default CouponsPage;
