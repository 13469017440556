import TableNoResults from 'components/table/table-no-results';
import { RetalierTabType } from 'routes/inventory/types';
import { SessionType } from 'routes/sessions/redux/types';
import * as Tabs from './index';

interface ITabView {
	retailerId?: string;
	type: RetalierTabType | SessionType;
}

const TabView: React.FC<ITabView> = ({ retailerId, type }) => {
	const TAB_TYPES: Record<string, any> = {
		[RetalierTabType.globalCategories]: Tabs.GlobalCategories,
		[RetalierTabType.itemCategories]: Tabs.ItemCategories,
		[RetalierTabType.items]: Tabs.RetailerItems
	};

	if (!type || !Object.keys(TAB_TYPES).includes(type)) return <TableNoResults />;

	const Component = TAB_TYPES[type];

	return <Component retailerId={retailerId} />;
};

export default TabView;
