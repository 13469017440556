import { ReduxAction } from 'redux/types';

export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD' | 'OPTIONS';

type OnSuccess<ResponseBody> = (responseBody: ResponseBody) => ReduxAction;
type OnError = (error: any) => void;
type OnFetchStart = () => void;

export interface APIAction<ResponseBody> extends ReduxAction {
	meta: {
		api: true;
	};
	payload: {
		networkLabel: string;
		method: HTTPMethod;
		path: string;
		params?: any;
		baseUrl?: string;
		onSuccess?: OnSuccess<ResponseBody> | Array<OnSuccess<ResponseBody>>;
		onError?: OnError | Array<OnError>;
		onFetchStart?: OnFetchStart | Array<OnFetchStart>;
	};
}

export const NETWORK_REQUEST_START = '[network] NETWORK_REQUEST_START';
export const NETWORK_REQUEST_END = '[network] NETWORK_REQUEST_END';

export interface NetworkAction extends ReduxAction {
	payload: { label: string };
}

export const startNetworkRequest = (label: string): NetworkAction => ({
	type: NETWORK_REQUEST_START,
	payload: { label }
});

export const endNetworkRequest = (label: string): NetworkAction => ({
	type: NETWORK_REQUEST_END,
	payload: { label }
});
