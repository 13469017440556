import React from 'react';
import styled from '@emotion/styled';
import { palette } from 'constants/styles';
import { ReactComponent as CloseIcon } from 'assets/images/delete.svg';

interface IModal {
	title: string;
	onClose: () => void;
}

const Modal: React.FC<IModal> = ({ children, onClose, title }) => (
	<Container>
		<BackDrop onClick={onClose} />

		<Content>
			<ModalHeader>
				{title}

				<Close onClick={onClose} data-testid="close-modal">
					<CloseIcon width={12} height={12} />
				</Close>
			</ModalHeader>

			{children}
		</Content>
	</Container>
);

const Container = styled.div`
	position: fixed;
	inset: 0;
	z-index: 10;
`;

const BackDrop = styled.div`
	position: absolute;
	inset: 0;
	background: ${palette.primaryGray};
	opacity: 0.7;
`;

const Content = styled.div`
	background: white;
	min-width: 400px;
	min-height: 100px;
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	box-shadow: 0 0 2rem rgba(0, 0, 0, 0.15);
`;

const ModalHeader = styled.div`
	padding: 1.5rem;
	border-bottom: 1px solid #eee;
`;

export const ModalBody = styled.div`
	padding: 1.5rem;
	max-height: 70vh;
	overflow-y: auto;
`;

export const ModalFooter = styled.div`
	padding: 1.5rem;
	border-top: 1px solid #eee;
`;

const Close = styled.div`
	position: absolute;
	padding: 1.75rem 1.5rem;
	top: 0;
	right: 0;
	cursor: pointer;
`;

export const ModalText = styled.div`
	color: ${palette.lightGray};
	font-size: 0.9rem;
	font-weight: 300;
	margin-bottom: 1rem;
`;

export const ModalActions = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 0.5rem 0;
`;

export default Modal;
