import { useRef } from 'react';

type TCallback = (...args: any[]) => void;
type TimeoutId = ReturnType<typeof setTimeout>;

export const useDebounce = (callback: TCallback, delay: number) => {
	const ref = useRef<TimeoutId>();

	return (...args: any[]) => {
		// The debouncer function
		clearTimeout(ref.current as TimeoutId);
		ref.current = setTimeout(() => {
			callback(...args);
		}, delay);
	};
};
