import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAuthSelector } from 'redux/reducers/auth';
import { useDispatch } from 'react-redux';
import { fetchMetadata } from 'redux/actions';
import { fetchCouponCampaigns } from '../routes/coupons/redux/actions';
import { useURLState } from '../hooks';
import { useGetPartnersQuery } from 'apiSlices';
import { B2CPartnerId } from 'constants/global-consts';
import { selectPartner, useSelectedPartnerId } from 'redux/partners-slice';

const ProtectedRoute: React.FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const { urlState, setURLState } = useURLState();
	const selectedPartnerId = useSelectedPartnerId();
	const { data: partners } = useGetPartnersQuery({});

	useEffect(() => {
		dispatch(fetchMetadata());
		dispatch(fetchCouponCampaigns());
	}, [dispatch]);

	useEffect(() => {
		if (partners?.length) {
			if (urlState.partnerId && urlState.partnerId !== selectedPartnerId) {
				dispatch(selectPartner(urlState.partnerId));
			} else {
				setURLState({ ...urlState, partnerId: selectedPartnerId || B2CPartnerId }, true);
			}
		}
	}, [urlState, setURLState, partners, selectedPartnerId, dispatch]);

	const { apiToken } = useAuthSelector((state) => ({
		apiToken: state.apiToken
	}));

	if (!apiToken) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return selectedPartnerId ? <Outlet /> : <></>;
};

export default ProtectedRoute;
