import React, { useRef, Dispatch } from 'react';
import styled from '@emotion/styled';

interface IDropDown {
	isVisible: boolean;
	setIsVisible: Dispatch<React.SetStateAction<boolean>>;
	trigger: React.ReactElement;
}

const DropDown: React.FC<IDropDown> = ({ trigger, children, isVisible, setIsVisible }) => {
	const containerRef = useRef<HTMLDivElement>(null);

	return (
		<>
			{isVisible && <Overlay onClick={() => setIsVisible(false)} />}
			<Container ref={containerRef}>
				<TriggerContainer onClick={() => setIsVisible((prev) => !prev)}>{trigger}</TriggerContainer>
				<PopperContainer>{isVisible && children}</PopperContainer>
			</Container>
		</>
	);
};

const Overlay = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1;
`;

const Container = styled.div`
	position: relative;
	z-index: 2;
`;
const TriggerContainer = styled.div``;

const PopperContainer = styled.div`
	position: absolute;
	top: 100%;
	right: 0;
	z-index: 1;
`;

export default DropDown;
