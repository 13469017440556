import { APIAction } from 'redux/actions/network';
import { MetadataResponse } from 'redux/types';
import { ExportUserRequest } from 'routes/users/redux/types';
import { ExportSessionRequest } from 'routes/sessions/redux/types';
import { ExportOrderRequest } from 'routes/orders/redux/types';

export const SET_METADATA = '[metadata] SET_METADATA';
export const FETCH_METADATA = '[metadata] FETCH_METADATA';
export const EXPORT_USERS = '[metadata] EXPORT_USERS';
export const EXPORT_SESSIONS = '[metadata] EXPORT_SESSIONS';
export const EXPORT_ORDERS = '[metadata] EXPORT_ORDERS';

export const setMetadata = (metadata: MetadataResponse) => ({
	type: SET_METADATA,
	payload: { metadata }
});

export const fetchMetadata = (): APIAction<MetadataResponse> => ({
	type: FETCH_METADATA,
	meta: { api: true },
	payload: {
		method: 'GET',
		path: `/metadata`,
		networkLabel: 'metadata',
		onSuccess: setMetadata
	}
});

export const exportUsers = (params: ExportUserRequest): APIAction<never> => ({
	type: EXPORT_USERS,
	meta: { api: true },
	payload: {
		method: 'POST',
		path: `/export/user`,
		params,
		networkLabel: 'exportUsers'
	}
});

export const exportSessions = (params: ExportSessionRequest): APIAction<never> => ({
	type: EXPORT_SESSIONS,
	meta: { api: true },
	payload: {
		method: 'POST',
		path: `/export/session`,
		params,
		networkLabel: 'exportSessions'
	}
});

export const exportOrders = (params: ExportOrderRequest): APIAction<never> => ({
	type: EXPORT_ORDERS,
	meta: { api: true },
	payload: {
		method: 'POST',
		path: `/export/order`,
		params,
		networkLabel: 'exportOrders'
	}
});
