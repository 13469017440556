import React from 'react';
import styled from '@emotion/styled';
import { useURLState } from 'hooks';
import { ReactComponent as SortIconBlack } from 'assets/images/sortIconBlack.svg';
import { palette } from 'constants/styles';

interface ITableSort {
	title: string;
	sortKey: string;
}

const TableSort: React.FC<ITableSort> = ({ title, sortKey }) => {
	let className = '';
	const { urlState, setURLState } = useURLState();
	const { sortBy, sortOrder } = urlState;
	const handleSortClick = () => {
		const newURLState = { ...urlState };
		delete newURLState.page;

		if (sortBy === sortKey) {
			if (sortOrder === 'ASC') {
				newURLState.sortOrder = 'DESC';
			} else if (sortOrder === 'DESC') {
				delete newURLState.sortBy;
				delete newURLState.sortOrder;
			}
		} else {
			newURLState.sortBy = sortKey;
			newURLState.sortOrder = 'ASC';
		}

		setURLState(newURLState);
	};

	if (sortBy === sortKey) {
		className = sortOrder === 'ASC' ? 'asc' : 'desc';
	}

	return (
		<SortButton onClick={handleSortClick}>
			<div>{title}</div>
			<SortIcon className={className} />
		</SortButton>
	);
};

const SortButton = styled.div`
	display: flex;
	flex-direction: row;
	align-items: baseline;
	&:hover {
		cursor: pointer;
	}
`;

const SortIcon = styled(SortIconBlack)`
	height: 8px;
	width: 14px;
	margin-left: 8px;
	transform: rotate(-180deg);

	path {
		fill: ${palette.lightGray};
	}

	&.asc {
		transform: rotate(-180deg);
		path {
			fill: ${palette.black};
		}
	}

	&.desc {
		transform: rotate(0);
		path {
			fill: ${palette.black};
		}
	}
`;

export default TableSort;
