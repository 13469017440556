import { FC } from 'react';
import styled from '@emotion/styled';
import { palette } from 'constants/styles';
import { formatMoney } from 'utils/money';
import { useGetSessionOrdersQuery } from '../redux';

interface IOrders {
	sessionId: string;
}

export const OrdersTab: FC<IOrders> = ({ sessionId }) => {
	const { data: orders, isFetching } = useGetSessionOrdersQuery({ sessionId });

	if (isFetching) {
		return <div>Loading orders</div>;
	}

	if (orders?.length === 0) {
		return <div>No orders</div>;
	}

	return (
		<Container>
			{orders?.map((order, index) => (
				<Item key={index}>
					<Image src={order.imgUrl} />

					<Designer>{order.designer ?? '-'}</Designer>
					<Price>
						{formatMoney({
							amount: order.price,
							currency: order.currency
						})}
					</Price>
				</Item>
			))}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const Item = styled.div`
	border: 1px solid #dbdbdb;
	text-align: center;
	margin-right: 5px;
	margin-bottom: 5px;
`;

const Designer = styled.div`
	margin-top: 3px;
	color: ${palette.lightGray};
	font-weight: 100;
	font-size: 14px;
`;

const Price = styled.div`
	margin: 3px 0;
	font-weight: 100;
	font-size: 14px;
`;

const Image = styled.div<{ src: string }>`
	height: 180px;
	width: 150px;
	background: url(${(props) => props.src}) no-repeat center/contain;
`;
