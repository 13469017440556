import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom';
import { ActivityRecord } from 'constants/types';
import { palette } from 'constants/styles';
import { numberToCurrency } from 'utils/money';
import { formatDate, formatDateWithTime } from 'utils/dates';
import DetailsList from 'components/details-list';
import Button from 'components/button';
import Table from 'components/table/table';
import Notes from 'components/notes';
import DropDown from 'components/drop-down';
import TableActionsDropdown from 'components/table/table-actions-dropdown';
import AddNoteModal from 'components/add-note-modal';
import { ReactComponent as ChevronIcon } from 'assets/images/chevron-white.svg';

import { getUserDetailsColumnsConfig as columns } from '../config';
import {
	TUserId,
	useAddUserNoteMutation,
	useDeleteUserNoteMutation,
	useGetUserDetailsQuery,
	useGetUserNotesQuery
} from '../redux';
import { AddEditUserModal, DeleteUsersModal, MakeStylistModal, UserBadges, ResetPasswordModal } from '../components';
import { useSelectedPartnerId } from 'redux/partners-slice';
import { isB2CPartnerId } from 'utils/helpers';

export const UserDetails = () => {
	const { id: userId } = useParams();
	const selectedPartnerId = useSelectedPartnerId();
	const [isActionsDropdownVisible, setIsActionsDropdownVisible] = useState(false);
	const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
	const [showAddNoteModal, setShowAddNoteModal] = useState(false);
	const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
	const [showEditUserModal, setShowEditUserModal] = useState(false);
	const [showMakeStylistModal, setShowMakeStylistModal] = useState(false);

	const [addUserNote] = useAddUserNoteMutation();
	const [deleteUserNote] = useDeleteUserNoteMutation();

	const navigate = useNavigate();

	const { data: userDetails, isFetching: isFetchingUserDetails } = useGetUserDetailsQuery(
		{ userId: userId as TUserId },
		{ skip: !userId }
	);

	const { data: userNotes, isFetching: isFetchingUserNotes } = useGetUserNotesQuery(
		{ userId: userId as TUserId },
		{ skip: !userId }
	);

	const userDetailsActionsList = isB2CPartnerId(selectedPartnerId)
		? [
				{
					text: 'Reset password',
					onActivate: () => setShowResetPasswordModal(true)
				},
				{
					text: 'Add note',
					onActivate: () => setShowAddNoteModal(true)
				},
				{
					text: 'Edit user',
					onActivate: () => setShowEditUserModal(true)
				},
				{
					text: 'Delete user',
					onActivate: () => setShowDeleteUserModal(true)
				}
		  ]
		: [
				{
					text: 'Add note',
					onActivate: () => setShowAddNoteModal(true)
				},
				{
					text: 'Delete user',
					onActivate: () => setShowDeleteUserModal(true)
				}
		  ];

	if (!userId || !userDetails || isFetchingUserDetails) {
		return <></>;
	}

	const ageOrBirthdayLabel = isB2CPartnerId(selectedPartnerId)
		? {
				label: 'Birthday',
				value: userDetails.personalInfo.birthday ? formatDate(userDetails.personalInfo.birthday) : 'N/A'
		  }
		: {
				label: 'Age',
				value: userDetails.personalInfo.age ? userDetails.personalInfo.age : 'N/A'
		  };

	const userDeviceInfo = [
		{
			label: 'Device',
			value: userDetails.deviceInfo?.device ?? 'N/A'
		}
	];

	if (!isB2CPartnerId(selectedPartnerId)) {
		if (userDetails.deviceInfo?.browser) {
			userDeviceInfo.push({
				label: 'Browser',
				value: userDetails.deviceInfo?.browser ?? 'N/A'
			});
		}
		if (userDetails.deviceInfo?.osVersion) {
			userDeviceInfo.push({
				label: 'os-version',
				value: userDetails.deviceInfo?.osVersion ?? 'N/A'
			});
		}
	}

	return (
		<Container>
			<UserHeader>
				<UserHeaderSection>
					<Username>
						{userDetails.generalInfo.firstName} {userDetails.generalInfo.lastName}
					</Username>
					<UserBadges badges={userDetails.generalInfo.badges} />
				</UserHeaderSection>

				<UserHeaderSection>
					<ActionButtonWrapper>
						<DropDown
							trigger={
								<Button color="primary">
									Actions <Chevron />
								</Button>
							}
							isVisible={isActionsDropdownVisible}
							setIsVisible={setIsActionsDropdownVisible}
						>
							<TableActionsDropdown actionsList={userDetailsActionsList} />
						</DropDown>
					</ActionButtonWrapper>
				</UserHeaderSection>
			</UserHeader>

			<GridContainer>
				<div>
					{userNotes && (
						<Notes
							isLoading={isFetchingUserNotes}
							notes={userNotes}
							onDelete={(noteId) => {
								deleteUserNote({ userId, noteId });
							}}
						/>
					)}

					<SpaceBetweenFlexContainer>
						<Subject>Total Spend</Subject>
						<Subject>Created</Subject>
					</SpaceBetweenFlexContainer>

					<SpaceBetweenFlexContainer>
						<Value>{numberToCurrency(userDetails.generalInfo.totalSpend)}</Value>
						<Value>{formatDateWithTime(userDetails.generalInfo.createdAt)}</Value>
					</SpaceBetweenFlexContainer>
					<DetailsList
						detailsList={[
							{ label: 'Gender', value: userDetails.personalInfo.gender },
							ageOrBirthdayLabel,
							{ label: 'Location', value: userDetails.personalInfo.location },
							{ label: 'Body Type', value: userDetails.personalInfo.bodyType },
							{
								label: 'Style Preference',
								value: userDetails.personalInfo.stylePreference
							}
						]}
						title="Personal info"
						isEditable={false}
						onEditClick={() => setShowEditUserModal(true)}
					/>

					<DetailsList
						detailsList={
							isB2CPartnerId(selectedPartnerId)
								? [
										{
											label: 'Signup Method',
											value: userDetails.technicalInfo.signupMethod
										},
										{ label: 'Email', value: userDetails.technicalInfo.email },
										{
											label: 'Signup Platform',
											value: userDetails.technicalInfo.signupPlatform
										},
										{
											label: 'Firebase token',
											value: userDetails.technicalInfo.firebaseToken
										},
										{ label: 'Logins', value: userDetails.technicalInfo.logins },
										{ label: 'App Logins', value: userDetails.technicalInfo.appLogins },
										{
											label: 'Last Login',
											value: formatDateWithTime(userDetails.technicalInfo.lastLogin)
										}
								  ]
								: [
										{
											label: 'Signup Platform',
											value: userDetails.technicalInfo.signupPlatform
										},
										{
											label: 'Last Login',
											value: formatDateWithTime(userDetails.technicalInfo.lastLogin)
										},
										{
											label: 'SDK Client Version',
											value: userDetails.technicalInfo.clientVersion ?? 'N/A'
										}
								  ]
						}
						title="Technical info"
						isEditable={false}
					/>

					{!isB2CPartnerId(selectedPartnerId) && (
						<DetailsList detailsList={userDeviceInfo} title="User Device Info" isEditable={false} />
					)}
				</div>

				<div>
					<TableHeader>
						<TableTitle>Activity</TableTitle>
					</TableHeader>

					<TableWrapper>
						<Table<ActivityRecord> columns={columns} records={[]} isClickable={false} rowKey="action" />
					</TableWrapper>
				</div>
			</GridContainer>
			{showResetPasswordModal && (
				<ResetPasswordModal
					userId={userId}
					onClose={() => {
						setShowResetPasswordModal(false);
					}}
				/>
			)}

			{showAddNoteModal && (
				<AddNoteModal
					onClose={() => {
						setShowAddNoteModal(false);
					}}
					onSave={(text) => {
						addUserNote({ userId, text });
						setShowAddNoteModal(false);
					}}
				/>
			)}

			{showDeleteUserModal && (
				<DeleteUsersModal
					userIdsToDelete={[userId]}
					onClose={() => {
						setShowDeleteUserModal(false);
						navigate('/users');
					}}
				/>
			)}

			{showEditUserModal && (
				<AddEditUserModal
					userId={userId}
					action="edit"
					user={userDetails}
					onClose={() => {
						setShowEditUserModal(false);
					}}
				/>
			)}

			{showMakeStylistModal && (
				<MakeStylistModal
					userId={userId}
					onClose={() => {
						setShowMakeStylistModal(false);
					}}
				/>
			)}
		</Container>
	);
};

const Container = styled.div``;

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: 250px 1fr;
	column-gap: 160px;
`;

const UserHeader = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2rem;
`;

const UserHeaderSection = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
`;

const Username = styled.div`
	font-size: 28px;
	font-weight: 300;
`;

const TableTitle = styled.div`
	height: 35px;
	display: flex;
	align-items: center;
	font-size: 16px;
	font-weight: 500;
`;

const TableHeader = styled.div`
	display: flex;
	justify-content: space-between;
`;

const SpaceBetweenFlexContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Subject = styled.div`
	font-size: 14px;
	color: ${palette.lightGray};
	font-weight: 300;
	margin-bottom: 8px;
`;

const Value = styled.div`
	font-size: 14px;
	font-weight: 300;
`;

const Chevron = styled(ChevronIcon)`
	width: 10px;
	height: 8px;
	margin-left: 10px;
	margin-top: 5px;
`;

const ActionButtonWrapper = styled.div`
	position: relative;
`;

const TableWrapper = styled.div`
	width: 70%;
	margin-top: 55px;
`;
