import { handleActions } from 'redux-actions';
import { OrdersState } from 'routes/orders/redux/types';
import { FETCH_ORDERS, SET_ORDERS, setOrders } from 'routes/orders/redux/actions';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { State } from 'redux/types';
import { SET_METADATA, setMetadata } from 'redux/actions/metadata';

const initialState: OrdersState = {
	orders: [],
	paginationMeta: {},
	metadata: null
};

export const ordersReducer = handleActions(
	{
		[FETCH_ORDERS]: (state) => ({
			...state,
			orders: [],
			paginationMeta: {}
		}),

		[SET_ORDERS]: (state, action: ReturnType<typeof setOrders>) => ({
			...state,
			orders: action.payload.orders,
			paginationMeta: action.payload.paginationMeta
		}),

		[SET_METADATA]: (state, action: ReturnType<typeof setMetadata>) => ({
			...state,
			metadata: action.payload.metadata.orders
		})
	},
	initialState
);

export const useOrdersSelector: TypedUseSelectorHook<OrdersState> = (selector, ...args) =>
	useSelector((state: State) => selector(state.orders), ...args);
