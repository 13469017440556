import React from 'react';
import { BadgeName } from '../redux';
import styled from '@emotion/styled';
import { ReactComponent as BronzeIcon } from 'assets/images/bronze.svg';
import { ReactComponent as GoldIcon } from 'assets/images/gold.svg';
import { ReactComponent as PlatinumIcon } from 'assets/images/platinum.svg';

interface IUserBadges {
	badges: Array<BadgeName>;
}

export const UserBadges: React.FC<IUserBadges> = ({ badges }) => {
	return (
		<Container>
			{badges.includes('bronze') && <BronzeBadge />}
			{badges.includes('gold') && <GoldBadge />}
			{badges.includes('platinum') && <PlatinumBadge />}

			{badges.includes('isInactive') && <InactiveBadge>Inactive</InactiveBadge>}
			{badges.includes('isStylist') && <StylistBadge>Stylist</StylistBadge>}

			{badges.includes('isHighSpender') && <HighSpenderBadge>High spender</HighSpenderBadge>}

			{badges.includes('isHighEngager') && <HighEngagerBadge>High engager</HighEngagerBadge>}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	gap: 1rem;
`;

const BronzeBadge = styled(BronzeIcon)`
	height: 22px;
	width: 22px;
`;

const GoldBadge = styled(GoldIcon)`
	height: 22px;
	width: 22px;
`;

const PlatinumBadge = styled(PlatinumIcon)`
	height: 22px;
	width: 22px;
`;

const ColorBadge = styled.div`
	border-radius: 0.25rem;
	background: #eee;
	font-size: 0.7rem;
	padding: 0.25rem 0.75rem;
	font-weight: 300;
`;

const StylistBadge = styled(ColorBadge)`
	color: #983705;
	background: #f8e5b9;
`;

const HighSpenderBadge = styled(ColorBadge)`
	color: #0e6245;
	background: #cbf4c9;
`;

const HighEngagerBadge = styled(ColorBadge)`
	color: #cbf4c9;
	background: #006443;
`;

const InactiveBadge = styled(ColorBadge)`
	color: white;
	background: black;
`;
