import { FileCategory } from 'routes/inventory/redux/types';
import { RetailerRecord } from 'routes/inventory/redux/types';
import moment from 'moment';

const million = {
	symbol: 'm',
	value: 1000000
};

const thousand = {
	symbol: 'k',
	value: 1000
};

export const getRetailerParam = (retailerDetails?: RetailerRecord, globalCategories: FileCategory[] = []) => ({
	name: retailerDetails?.name,
	feed: retailerDetails?.feed,
	fileName: retailerDetails?.fileName,
	fileCategories: globalCategories
});

export const modalMessage = {
	globalCategories: {
		error: "Can't update global categories",
		success: 'Update retailer successfully'
	}
};

export const convertToLocalDateFormat = (date: string, format: string) => {
	if (!date) return '';
	const momentDate = moment(new Date(date));
	return momentDate.format(format);
};

export const formateNumberToString = (number: number) => {
	if (!number) return '';
	if (number < thousand.value) return number;
	const units = getUnits(number);
	return `${(number / units.value).toFixed(1)}${units.symbol}`;
};

const getUnits = (number: number) => {
	if (number >= million.value) return million;
	return thousand;
};
