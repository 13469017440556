import { ColumnType } from 'rc-table/es/interface';
import { CouponRecord } from 'routes/coupons/redux/types';
import { numberToCurrency } from 'utils/money';
import { formatDateWithTime } from 'utils/dates';
import { CheckboxColumn, renderCheckboxColumn, renderOptionalCell } from 'components/table/table-utils';

export const getColumnsConfig = (options: CheckboxColumn<CouponRecord>): Array<ColumnType<CouponRecord>> => {
	return [
		renderCheckboxColumn<CouponRecord>(options),
		{
			title: 'Created',
			dataIndex: 'created',
			key: 'created',
			render: formatDateWithTime
		},
		{
			title: 'Promo code',
			dataIndex: 'promoCode',
			key: 'promoCode',
			render: renderOptionalCell
		},
		{
			title: 'Discount',
			dataIndex: 'discount',
			key: 'discount',
			render: renderOptionalCell
		},
		{
			title: 'Campaign',
			dataIndex: 'campaign',
			key: 'campaign',
			render: renderOptionalCell
		},
		{
			title: 'Used times',
			dataIndex: 'timesUsed',
			key: 'timesUsed',
			render: renderOptionalCell
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: renderOptionalCell
		},
		{
			title: 'Exp. date',
			dataIndex: 'expirationDate',
			key: 'expirationDate',
			render: formatDateWithTime
		},
		{
			title: 'Total spend',
			dataIndex: 'total',
			key: 'total',
			render: numberToCurrency
		}
	];
};
