import { css } from '@emotion/react';

const globalCss = css`
	@font-face {
		font-family: 'Untitled Sans';
		src: url('/fonts/UntitledSans-Black.otf');
		font-weight: 900;
	}

	@font-face {
		font-family: 'Untitled Sans';
		src: url('/fonts/UntitledSans-Bold.otf');
		font-weight: 700;
	}

	@font-face {
		font-family: 'Untitled Sans';
		src: url('/fonts/UntitledSans-Medium.otf');
		font-weight: 500;
	}

	@font-face {
		font-family: 'Untitled Sans';
		src: url('/fonts/UntitledSans-Regular.otf');
		font-weight: 300;
	}

	@font-face {
		font-family: 'Untitled Sans';
		src: url('/fonts/UntitledSans-Light.otf');
		font-weight: 100;
	}

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	select:not(option) {
		background-color: white;
	}

	html,
	body,
	#root {
		height: 100%;
	}

	body,
	input,
	button {
		font-family: 'Untitled Sans', sans-serif;
	}

	svg {
		display: block;
	}
`;

export default globalCss;
