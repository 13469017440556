import React from 'react';
import { useParams } from 'react-router-dom';
import PageLayout from 'components/page-layout';
import { renderRetailerDate, renderRetailerTotalItems, retailerTabs } from 'routes/inventory/config';
import TypeFilters from 'components/table/type-filters';
import { useURLState } from 'hooks';
import {
	Container,
	RetailerDetailsContainer,
	RetailerDetailsRow,
	Subject,
	Value,
	TabsContainer
} from './retailer-details.style';
import TableNoResults from 'components/table/table-no-results';
import { useGetRetailerByIdQuery } from 'routes/inventory/redux/inventory-slice';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { RetailerStatus } from 'routes/inventory/redux/types';
import { RetailerHeader } from './retailer-header';
import { TabView } from '../tabs';
import { RetalierTabType } from 'routes/inventory/types';

export const RetailerDetails: React.FC<{}> = () => {
	const { id } = useParams();
	const { data, isLoading } = useGetRetailerByIdQuery(id ?? skipToken);
	const { urlState } = useURLState();

	if (isLoading) return <PageLayout>Loading Retailers Details...</PageLayout>;
	if (!data)
		return (
			<PageLayout>
				<TableNoResults />
			</PageLayout>
		);

	const getStatusIndication = () => {
		if (typeof data.active !== 'boolean') return RetailerStatus.Inactive;
		return data.active ? RetailerStatus.Active : RetailerStatus.Inactive;
	};

	const retailer = { ...data, status: getStatusIndication() };
	const tabType = urlState.type ?? RetalierTabType.items;

	return (
		<PageLayout>
			<RetailerHeader retailer={retailer} />

			<Container>
				<RetailerDetailsContainer>
					<RetailerDetailsRow>
						<Subject>Last updated</Subject>
						<Value>{renderRetailerDate(retailer, 'MMM D, YYYY')}</Value>
					</RetailerDetailsRow>

					<RetailerDetailsRow>
						<Subject>Affiliation</Subject>
						<Value>{retailer.feed}</Value>
					</RetailerDetailsRow>

					<RetailerDetailsRow>
						<Subject>Number Of Items</Subject>
						<Value>{renderRetailerTotalItems('', retailer)}</Value>
					</RetailerDetailsRow>

					<RetailerDetailsRow>
						<Subject>Rank In Shop</Subject>
						<Value>{retailer.priority}</Value>
					</RetailerDetailsRow>

					<RetailerDetailsRow>
						<Subject>Status</Subject>
						<Value>{retailer.active ? RetailerStatus.Active : RetailerStatus.Inactive}</Value>
					</RetailerDetailsRow>
				</RetailerDetailsContainer>
				<TabsContainer>
					<TypeFilters filters={retailerTabs} />

					<TabView type={tabType} retailerId={id} />
				</TabsContainer>
			</Container>
		</PageLayout>
	);
};
