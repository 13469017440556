import { TMetadataResponse } from './types';
import { emptySplitApi } from 'redux/base-api';

const apiWithTag = emptySplitApi.enhanceEndpoints({ addTagTypes: ['Metadata'] });

const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getMetadata: build.query<TMetadataResponse, {}>({
			query: (params) => ({
				url: '/metadata',
				method: 'GET',
				params
			}),
			providesTags: ['Metadata']
		})
	})
});

export const { useGetMetadataQuery } = extendedApi;
