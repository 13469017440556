import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { setOrderItemPrice } from 'routes/order-details/redux/actions';
import { Input } from 'components/form/input';
import { numberToCurrency } from 'utils/money';
import { OrderItemRecord } from 'routes/order-details/redux/types';

export const OrderPriceCell: React.FC<{
	record: OrderItemRecord['amount'];
	itemId: string;
}> = ({ record, itemId }) => {
	const dispatch = useDispatch();

	return (
		<Container>
			<Input
				type={'number'}
				placeholder="Add item price"
				defaultValue={record.price}
				onBlur={(event) => {
					dispatch(setOrderItemPrice(itemId, parseFloat(event.target.value)));
				}}
			/>
			<AmountCellRow>{numberToCurrency(record.tax)}</AmountCellRow>
			<AmountCellRow>{numberToCurrency(record.total)}</AmountCellRow>
		</Container>
	);
};

const Container = styled.div`
	margin-right: 20px;
`;

const AmountCellRow = styled.div`
	display: flex;
	align-items: center;
	padding: 6px 0;
`;
