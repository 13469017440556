import { IFilterItem } from 'constants/types';

export const quickFilters: IFilterItem[] = [
	{
		id: 'search',
		displayName: 'Search',
		placeHolder: 'Search',
		controller: 'text'
	},
	{
		id: 'type',
		displayName: 'Type',
		controller: 'text',
		controllerItems: [
			{ value: 'all', label: 'All' },
			{ value: 'open', label: 'Open' },
			{ value: 'ordered', label: 'Ordered' },
			{ value: 'returned', label: 'Returned' },
			{ value: 'return in progress', label: 'Return in progress' },
			{ value: 'canceled', label: 'Canceled' },
			{ value: 'oos', label: 'Out of stock' }
		]
	}
];

export const menuFilters: Array<IFilterItem> = [
	{
		id: 'created',
		displayName: 'Created date',
		controller: 'date-range'
	},
	{
		id: 'items',
		displayName: '# of items',
		controller: 'number'
	},
	{
		id: 'status',
		displayName: 'Status',
		controller: 'radio-list-column',
		controllerItems: [
			{ value: 'open', label: 'Open' },
			{ value: 'received', label: 'Received' },
			{ value: 'ordered', label: 'Ordered' },
			{ value: 'shipped', label: 'Shipped' },
			{ value: 'returned', label: 'Returned' },
			{ value: 'completed', label: 'Completed' },
			{ value: 'return in progress', label: 'Return in progress' },
			{ value: 'canceled', label: 'Canceled' },
			{ value: 'arrived', label: 'Arrived' }
		]
	}
];
