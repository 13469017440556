import styled from '@emotion/styled';

export const TimeComparisonHeader = styled.div`
	display: flex;
	align-items: center;
	font-weight: 300;
`;

export const CurrentTimeFrame = styled.span`
	font-size: 26px;
	margin-right: 18px;
`;

export const ComparedToLabel = styled.span`
	font-size: 14px;
	margin: 0 10px;
`;

export const DateInput = styled.div`
	min-width: 100px;
`;
