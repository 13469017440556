import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as ProfileIcon } from 'assets/images/profile.svg';
import DropDown from 'components/drop-down';
import { palette } from 'constants/styles';
import { signOut } from 'redux/actions/auth';
import { storageParams } from 'routes/login/consts';
import { useDispatch } from 'react-redux';

const UserPanel = () => {
	const [panelOpen, setIsPanelOpen] = useState(false);
	const dispatch = useDispatch();

	return (
		<DropDown isVisible={panelOpen} setIsVisible={setIsPanelOpen} trigger={<Person />}>
			<Container>
				<SignOutButton
					onClick={() => {
						localStorage.removeItem(storageParams.accessToken);
						dispatch(signOut());
					}}
				>
					Sign out
				</SignOutButton>
			</Container>
		</DropDown>
	);
};

const Container = styled.div`
	width: 140px;
	border-radius: 4px;
	border: 2px solid ${palette.primaryGray};
	background-color: white;
	box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.03);
`;

const Person = styled(ProfileIcon)`
	cursor: pointer;
	margin-left: 25px;
`;

const SignOutButton = styled.div`
	padding: 15px;
	color: ${palette.primaryColor};
	font-weight: 300;
	cursor: pointer;
`;

export default UserPanel;
