import styled from '@emotion/styled';
import { ReactComponent as ChevronIcon } from 'assets/images/chevron-white.svg';
import { RetailerStatus } from 'routes/inventory/redux/types';
import { palette } from '../../../../constants/styles';

interface Status {
	status?: RetailerStatus;
}

export const RetailerHeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const RetailerName = styled.label`
	font-size: 28px;
	text-align: left;
	font-weight: 300;
	font-family: 'Untitled Sans';
	align-items: center;
	display: flex;
`;

export const RetailerIndication = styled.label<Status>`
	font-size: 12px;
	margin-left: 25px;
	padding: 4px 8px 4px 8px;
	border-radius: 4px;
	color: ${palette.white};
	font-weight: 300;
	font-family: 'Untitled Sans';
	background-color: ${(props) =>
		props.status === RetailerStatus.Active ? palette.positiveBadgeText : palette.lightGray};
`;

export const Chevron = styled(ChevronIcon)`
	width: 10px;
	height: 7.5px;
	margin-left: 10px;
	margin-top: 5px;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: baseline;
`;

export const RetailerDetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 34px;
	margin-right: 80px;
	width: 160px;
`;

export const RetailerDetailsRow = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 25px;
`;

export const Subject = styled.div`
	display: inline-block;
	font-size: 14px;
	color: ${palette.lightGray};
	font-weight: 300;
	margin-bottom: 8px;
`;

export const Value = styled.div`
	display: inline-block;
	font-size: 14px;
	font-weight: 300;
	text-transform: capitalize;
`;

export const TabsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 80%;
`;

export const DropDownContainer = styled.div`
	width: 140px;
	border-radius: 4px;
	border: 2px solid ${palette.primaryGray};
	background-color: ${palette.white};
	box-shadow: 0 0 20px ${palette.lightWhite};
`;

export const DropDownItem = styled.div`
	font-size: 14px;
	color: ${palette.primaryColor};
	font-weight: 500;
	cursor: pointer;
	padding: 7px;

	&:hover {
		background-color: ${palette.primaryGray};
		color: ${palette.black};
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: end;
	margin-top: 10px;
`;

export const ButtonSpace = styled.div`
	width: 10px;
`;
