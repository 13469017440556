import React from 'react';
import styled from '@emotion/styled';
import { palette } from 'constants/styles';

interface IFilterNumber {
	val: number;
}

const FilterNumber: React.FC<IFilterNumber> = ({ val }) => {
	return val === 0 ? null : <Number>{val}</Number>;
};

const Number = styled.div`
	margin-left: 0.2rem;
	color: ${palette.dangerColor};
`;

export default FilterNumber;
