import { DashboardGroup, DashboardGroupData, DashboardResponse } from 'routes/dashboard/redux/types';
import { DashboardData, ValueType } from 'constants/types';
import { Dictionary } from 'lodash';
import { camelCaseToCapitalize } from 'utils/helpers';
import { formatDashboardValue } from './format-dashboard-value';

const groupKeyToValueTypeMap: Dictionary<Dictionary<ValueType>> = {
	[DashboardGroup.USERS]: {
		aov: 'money',
		conversion: 'percentage'
	},
	[DashboardGroup.MEMBERSHIPS]: {
		finishedTrials: 'hidden',
		trialConversionRate: 'percentage'
	},
	[DashboardGroup.SERVICE_REVENUE]: {
		total: 'money',
		sessions: 'money',
		avgPrice: 'money',
		giftCards: 'money',
		upgrades: 'money'
	},
	[DashboardGroup.SALES_REVENUE]: {
		total: 'money',
		aov: 'money',
		thirdParty: 'money',
		wishiOrders: 'money'
	},
	[DashboardGroup.GROSS_REVENUE]: {
		total: 'money',
		serviceRevenue: 'money',
		sales: 'money'
	},
	[DashboardGroup.EMAILS]: {
		styledYou: 'percentage',
		welcome: 'percentage',
		newMoodboard: 'percentage',
		referAFriend: 'percentage',
		abandonCart: 'percentage'
	}
};

const upperCaseKeys = ['aov'];

const getValueType = (group: string, key: string) => {
	return (groupKeyToValueTypeMap[group] && groupKeyToValueTypeMap[group][key]) ?? 'number';
};

export const mapToDashboardStructure = (response: DashboardResponse): DashboardData => {
	return Object.entries(response).map(([group, data]: [string, DashboardGroupData]) => ({
		label: camelCaseToCapitalize(group),
		total: formatDashboardValue({ value: data.total, type: getValueType(group, 'total') }),
		changeRate: formatDashboardValue({ value: data.diff, type: 'percentage' }),
		details: Object.entries(data.data)
			.filter((item) => getValueType(group, item[0]) !== 'hidden')
			.map(([key, value]) => ({
				label: upperCaseKeys.includes(key) ? key.toUpperCase() : camelCaseToCapitalize(key),
				value: formatDashboardValue({ value, type: getValueType(group, key) })
			}))
	}));
};
