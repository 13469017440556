import { ReactComponent as StarIcon } from 'assets/images/star.svg';
import React from 'react';
import styled from '@emotion/styled';
import { palette } from 'constants/styles';

interface IRatingStars {
	number: number;
}

const RatingStars: React.FC<IRatingStars> = ({ number }) => {
	return (
		<Container>
			{[...new Array(5)].map((_, index) => {
				if (index > number - 1) {
					return <GrayStar key={index} />;
				}
				return <Star key={index} />;
			})}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
`;

const Star = styled(StarIcon)`
	height: 17px;
	width: 17px;
	margin-right: 3px;
`;

const GrayStar = styled(StarIcon)`
	height: 17px;
	width: 17px;
	margin-right: 3px;

	path {
		fill: ${palette.primaryGray};
	}
`;

export default RatingStars;
