import { FC } from 'react';
import styled from '@emotion/styled';
import { palette } from 'constants/styles';
import { useFiltersURLState } from '../../hooks';
import { IFilterItem, TFilterControllerOption } from '../../constants/types';

type TProps = {
	filters: Array<IFilterItem>;
};

const TypeFilters: FC<TProps> = ({ filters }) => {
	const { filtersURLState, setFiltersURLState } = useFiltersURLState(filters);
	const typeFilter = filters.find(({ id }) => id === 'type');
	const typeFilterValue = filtersURLState.type;

	const setType = (value: string) => {
		filtersURLState.type = value;
		setFiltersURLState(filtersURLState);
	};

	return (
		<Container>
			{(typeFilter?.controllerItems as TFilterControllerOption[]).map(({ value, label }) => (
				<Tab
					key={value}
					isActive={typeFilterValue === value || (value === 'all' && !typeFilterValue)}
					onClick={() => setType(value)}
				>
					{label}
				</Tab>
			))}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 30px;
`;

const Tab = styled.div<{ isActive: boolean }>`
	font-size: 14px;
	margin-right: 23px;
	font-weight: ${({ isActive }) => (isActive ? 400 : 300)};
	cursor: pointer;
	text-decoration: ${({ isActive }) => (isActive ? 'underline' : 'none')};
	color: ${({ isActive }) => (isActive ? palette.primaryColor : 'black')};
	user-select: none;
`;

export default TypeFilters;
