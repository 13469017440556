import moment, { Moment } from 'moment';
import { CustomLabelType } from 'routes/sessions/redux/types';

export enum Granularity {
	Day,
	Hour
}

const getStart = (date: Date, granularity: Granularity) => {
	return granularity === Granularity.Day ? moment(date).startOf('day') : moment(date).startOf('hour');
};

const compare = (now: Moment, due: Moment, granularity: Granularity) => {
	const dif = moment.duration(now.diff(due));
	return Math.floor(granularity === Granularity.Day ? dif.asDays() : dif.asHours());
};

const difference = (dueDate: Date, todayDate: Date, granularity: Granularity) => {
	const startOfToday = getStart(todayDate, granularity);
	const startOfDueDate = getStart(dueDate, granularity);
	return compare(startOfToday, startOfDueDate, granularity);
};

const differenceInYears = (dueDate: Date, todayDate: Date) => {
	const todayYear = moment(todayDate).year();
	const dueDateYear = moment(dueDate).year();
	return todayYear - dueDateYear;
};

const dateFormat = (dueDate: Date, todayDate: Date, granularity: Granularity) => {
	const momentDate = moment(new Date(dueDate));
	const isToday = difference(dueDate, todayDate, Granularity.Day) === 0;
	const isThisYear = differenceInYears(dueDate, todayDate) === 0;
	const tmp = isToday ? '[Due Today]' : isThisYear ? 'MMM DD' : 'MMM DD, YYYY';

	const fmt = granularity === Granularity.Day ? tmp : tmp + ', h A';

	return momentDate.format(fmt);
};

export const formatDueDate = (dueDate: Date, todayDate: Date, granularity: Granularity) => {
	const type = difference(dueDate, todayDate, granularity) <= 0 ? CustomLabelType.base : CustomLabelType.danger;
	return { text: dateFormat(dueDate, todayDate, granularity), type };
};
