import React, { useState } from 'react';
import Modal, { ModalActions, ModalBody } from 'components/modal';
import Button from 'components/button';
import { Select } from 'components/form/select';
import { shopPriorityList } from 'routes/inventory/config';
import { RetailerRecord } from 'routes/inventory/redux/types';
import { Fieldset, Message, SubTitle } from './modal.style';
import { useUpdateShopPriorityMutation } from 'routes/inventory/redux/inventory-slice';

interface IUpdateShopRankingModal {
	action: Record<string, any>;
	retailer: RetailerRecord;
	onClose: () => void;
}

const UpdateShopRankingModal: React.FC<IUpdateShopRankingModal> = ({ action, retailer, onClose }) => {
	const [shopPriority, setShopPriority] = useState<number>(retailer.priority);
	const [updateShopPriority, { isError }] = useUpdateShopPriorityMutation();

	const updatePriority = () => {
		updateShopPriority({ id: retailer.id, priority: shopPriority });
		if (!isError) onClose();
	};

	return (
		<Modal title={action.title} onClose={onClose}>
			<ModalBody>
				<form onSubmit={onClose} data-testid="modal-container">
					<Fieldset>
						<SubTitle>{action.subTitle}</SubTitle>
					</Fieldset>

					<Fieldset>
						<Select
							width="120px"
							value={shopPriority}
							onChange={(event) => setShopPriority(Number(event.target.value))}
						>
							{shopPriorityList.map((key) => (
								<option value={key} key={key}>
									{key}
								</option>
							))}
						</Select>
					</Fieldset>

					<Fieldset>
						<Message isError={isError}>{isError ? action.error : action.message}</Message>
					</Fieldset>

					<ModalActions>
						<Button onClick={updatePriority} color="primary" data-testid="modal-submit-button">
							{action.buttonText}
						</Button>
					</ModalActions>
				</form>
			</ModalBody>
		</Modal>
	);
};

export default UpdateShopRankingModal;
