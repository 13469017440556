import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';

interface ICopyToClipboard {
	name: string;
	value: string;
}

const CopyToClipboard: React.FC<ICopyToClipboard> = ({ name, value }) => {
	const [hovered, setHovered] = useState(false);
	const [copied, setCopied] = useState(false);
	const copyTimeout = useRef<any>(null);

	const onClick = () => {
		navigator.clipboard.writeText(value);
		setCopied(true);
		copyTimeout.current = setTimeout(() => {
			setCopied(false);
		}, 2000);
	};

	useEffect(() => {
		return () => {
			if (copyTimeout.current) {
				clearTimeout(copyTimeout.current);
			}
		};
	}, []);

	return (
		<Container onClick={onClick} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
			{(copied || hovered) && <Tooltip>{copied ? 'Copied!' : `Copy ${name}`}</Tooltip>}
			<CopyIcon height={13} />
			<Value>{value}</Value>
		</Container>
	);
};

const Container = styled.div`
  display: flex;
  gap: .33rem;
  align-items: center;
  justify-content: space-between;
  position: relative;

  :hover {
    cursor: pointer;
    text-decoration: underline;
`;

const Tooltip = styled.div`
	background: #414452;
	color: white;
	padding: 0.25rem 0.5rem;
	border-radius: 0.25rem;
	font-size: 0.7rem;
	white-space: nowrap;
	position: absolute;
	bottom: 125%;
	left: 0;
	font-weight: 300;
`;

const Value = styled.div`
	font-size: 12px;
	font-weight: 300;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export default CopyToClipboard;
