export const loginParams = {
	message: 'message',
	token: 'token'
};

export const loginMessages = {
	success: 'success'
};

export const storageParams = {
	accessToken: 'access-token'
};
