import Table from 'components/table/table';
import React, { useEffect, useMemo, useState } from 'react';
import { getRetaliersColumnsConfig, PAGE_LIMIT } from 'routes/inventory/config';
import { RetailerRecord, PaginationParams } from 'routes/inventory/redux/types';
import { useURLState } from 'hooks';
import { useGetRetailersQuery } from 'routes/inventory/redux/inventory-slice';
import { useNavigate } from 'react-router-dom';
import TableNoResults from 'components/table/table-no-results';
import { PageNavigation } from './page-navigation';
import TableHeader from '../tabs/table-header';
import PageLayout from 'components/page-layout';

export const RetailersList: React.FC<{}> = () => {
	const [pageInfo, setPageInfo] = useState<PaginationParams>({});
	const { data, isLoading } = useGetRetailersQuery({ params: pageInfo });
	const { urlState } = useURLState();
	const navigateTo = useNavigate();
	const retailers = useMemo(() => data?.results ?? [], [data?.results]);
	const rowKey = 'id';
	const columns = getRetaliersColumnsConfig();

	useEffect(() => {
		if (!pageInfo.first && !pageInfo.before) setPageInfo({ ...pageInfo, first: PAGE_LIMIT });
	}, [pageInfo]);

	useEffect(() => {
		const params = urlState.search ? { search: urlState.search } : {};
		setPageInfo(params);
	}, [urlState.search]);

	const onRowClick = (rowRecord: RetailerRecord) => {
		navigateTo(`/inventory/retailers/${rowRecord.id}`);
	};

	if (isLoading)
		return (
			<PageLayout>
				<div>Loading Retailers Details...</div>
			</PageLayout>
		);

	if (!data)
		return (
			<PageLayout>
				<TableNoResults />
			</PageLayout>
		);

	return (
		<PageLayout>
			<TableHeader title="Inventory" total={data.pageInfo.total} />

			<Table<RetailerRecord>
				dataTestId="retailers-table"
				columns={columns}
				rowKey={rowKey}
				records={retailers}
				isClickable={true}
				onRowClick={onRowClick}
				isAllRowClickable={true}
			/>

			<PageNavigation pageInfo={data.pageInfo} setPageInfo={setPageInfo} />
		</PageLayout>
	);
};
