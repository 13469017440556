import './style.scss';

import React, { useEffect, useState } from 'react';
import Select, { ActionMeta, SingleValue } from 'react-select';

interface Option {
	label: string;
	value: string;
}
interface IAutoComplete {
	options: Option[];
	value: any;
	placeholder?: string;
	onChange: (option: SingleValue<Option>, actionMeta?: ActionMeta<Option>) => void;
	onFocus?: () => void;
	onBlur?: () => void;
}
const AutoComplete: React.FC<IAutoComplete> = ({
	options = [],
	value,
	placeholder = '',
	onChange,
	onFocus,
	onBlur
}) => {
	const [selectedOption, setSelectedOption] = useState<Option | undefined>();

	useEffect(() => {
		setSelectedOption(options.find((option: Option) => option.value === value));
	}, [options, value]);

	return (
		<div className="search-box-container">
			<Select
				id="search-box"
				inputId="search-box-input"
				className="search-box"
				classNamePrefix="select"
				placeholder={placeholder}
				options={options}
				isMulti={false}
				isClearable={true}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				controlShouldRenderValue={true}
				openMenuOnClick={false}
				value={selectedOption}
			/>
		</div>
	);
};

export default AutoComplete;
