import { capitalize, startCase } from 'lodash';
import jwt from 'jwt-decode';
import { LdUserData } from 'routes/inventory/types';
import { TComparatorValue } from '../constants/types';
import { B2CPartnerId } from 'constants/global-consts';
import { TPartnerId } from 'redux/partners-slice';

export const formatNumber = (number: number) => new Intl.NumberFormat('en-US').format(number);

export const comparatorValues: Record<TComparatorValue, string> = {
	GT: 'Greater then',
	LT: 'Lower then',
	EQ: 'Is equal to',
	BTW: 'Between'
};

export const getEnumKeys = (enumValue: any) => {
	const keys = Object.keys(enumValue).filter((property) => typeof enumValue[property as any] !== 'number');

	return keys.map((property) => enumValue[property as any]);
};

export const getDomElementIndex = (element: HTMLElement) => {
	const parent = element.parentNode as HTMLElement;
	return Array.prototype.indexOf.call(parent.children, element);
};

export const camelCaseToCapitalize = (str: string) => capitalize(startCase(str));

export const extractUserData = (jwtToken: string): LdUserData => {
	const userDetails = jwt(jwtToken) as any;

	return {
		name: userDetails.user.firstName + ' ' + userDetails.user.lastName,
		email: userDetails.user.email,
		key: userDetails.user.id
	};
};

export const fastPruneEmpty = (obj: any) => {
	let counter = 0;
	const result: any = Array.isArray(obj) ? [] : {};
	obj &&
		Object.keys(obj).forEach((key) => {
			let value = obj[key];
			const typeOfKey = typeof value;

			if (typeOfKey === 'object') {
				value = fastPruneEmpty(value);
			}

			if (typeOfKey === 'string') {
				value = value.trim();
			}

			if (value) {
				result[key] = value;
				counter++;
			}
		});

	return counter ? result : null;
};

export const isB2CPartnerId = (id?: TPartnerId) => B2CPartnerId === id;
