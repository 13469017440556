import React from 'react';
import { Note } from 'redux/types';
import styled from '@emotion/styled';
import { palette } from 'constants/styles';

interface INotes {
	isLoading: boolean;
	notes: Array<Note>;
	onDelete: (noteId: string) => void;
}

const Notes: React.FC<INotes> = ({ isLoading, notes, onDelete }) => {
	if (isLoading) {
		return <div>Loading notes...</div>;
	}

	return (
		<>
			{notes.length > 0 && (
				<Container>
					{notes.map((note) => (
						<NoteContent key={note.uuid}>
							{note.text}
							<NoteAuthor>{note.createdBy}</NoteAuthor>

							<DeleteNote onClick={() => onDelete(note.uuid)}>Delete note</DeleteNote>
						</NoteContent>
					))}
				</Container>
			)}
		</>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-bottom: 2rem;
	max-width: 400px;
`;

const NoteContent = styled.div`
	background: #fef9da;
	border: 1px solid #f8e5b9;
	padding: 0.5rem;
	font-size: 0.8rem;
	font-weight: 300;
	overflow-wrap: break-word;
`;

const NoteAuthor = styled.div`
	color: ${palette.lightGray};
	margin-top: 1rem;
`;

const DeleteNote = styled.div`
	color: ${palette.dangerColor};
	text-decoration: underline;
	font-size: 12px;
	margin-top: 10px;

	:hover {
		cursor: pointer;
		text-decoration: none;
	}
`;

export default Notes;
