import { Partner, PartnersResponse } from '../redux/types';
import { emptySplitApi } from 'redux/base-api';

const apiWithTag = emptySplitApi.enhanceEndpoints({ addTagTypes: ['Partners'] });

const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getPartners: build.query<Partner[], {}>({
			query: () => ({
				url: '/partner',
				method: 'GET'
			}),
			transformResponse: (response: PartnersResponse): Partner[] => {
				return response.partners.map((x) => {return {...x, id: x.id.toString()}});
			},
			providesTags: () => [{ type: 'Partners', id: 'PartnersList' }]
		})
	})
});

export const { useGetPartnersQuery } = extendedApi;
