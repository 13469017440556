import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import PageLayout from 'components/page-layout';
import Button from 'components/button';
import DetailsList from 'components/details-list';
import { ReactComponent as ChevronIcon } from 'assets/images/chevron-white.svg';
import TableActionsDropdown from 'components/table/table-actions-dropdown';
import Table from 'components/table/table';
import { getColumnsConfig as columns } from 'routes/order-details/config';
import { numberToCurrency } from 'utils/money';
import DropDown from 'components/drop-down';
import { useParams } from 'react-router-dom';
import { useOrderDetailsSelector } from 'routes/order-details/redux/reducer';
import { useDispatch } from 'react-redux';
import { OrderStatus as Status } from 'routes/orders/redux/types';
import {
	addOrderNote,
	clearModalError,
	deleteOrderNote,
	fetchOrderDetails,
	fetchOrderItems,
	fetchOrderNotes,
	updateOrderDetails
} from 'routes/order-details/redux/actions';
import { formatDateWithTime } from 'utils/dates';
import { useNetworkSelector } from 'redux/reducers/network';
import Notes from 'components/notes';
import AddNoteModal from 'components/add-note-modal';
import EditClientInformationModal from 'routes/order-details/components/edit-client-information-modal';
import { UpdateOrderClientDetails } from 'routes/order-details/redux/types';
import UpdateOrderModal from 'routes/orders/components/update-order-modal';
import { palette } from 'constants/styles';

const OrderDetails = () => {
	const { id: orderId } = useParams();

	const { isLoadingOrderDetails, isLoadingNotes, isLoadingItems, isUpdatingClient } = useNetworkSelector((state) => ({
		isLoadingOrderDetails: state.orderDetails > 0,
		isLoadingNotes: state.orderNotes > 0,
		isLoadingItems: state.orderItems > 0,
		isUpdatingClient: state.updateClientInfo > 0
	}));

	const { order, notes, items, modalError } = useOrderDetailsSelector((state) => ({
		order: state.order,
		notes: state.notes,
		items: state.items,
		modalError: state.modalError
	}));

	const dispatch = useDispatch();

	const [isActionsDropdownVisible, setIsActionsDropdownVisible] = useState(false);
	const [showAddNoteModal, setShowAddNoteModal] = useState(false);
	const [showRefundModal] = useState(false);
	const [showCancelModal] = useState(false);
	const [showEditClientModal, setShowEditClientModal] = useState(false);
	const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);

	const orderDetailsActionsList = [
		// { text: 'Refund', onActivate: () => setShowRefundModal(true) },
		// { text: 'Cancel', onActivate: () => setShowCancelModal(true) },
		{ text: 'Add note', onActivate: () => setShowAddNoteModal(true) },
		{ text: 'Update status', onActivate: () => setShowUpdateStatusModal(true) }
	];

	useEffect(() => {
		dispatch(fetchOrderDetails(orderId!));
		dispatch(fetchOrderNotes(orderId!));
		dispatch(fetchOrderItems(orderId!));
	}, [orderId, dispatch]);

	const updateClient = (client: UpdateOrderClientDetails) => {
		dispatch(updateOrderDetails(orderId!, { client }));
	};

	if (!order || isLoadingOrderDetails) {
		return <PageLayout />;
	}

	return (
		<PageLayout>
			{showRefundModal && <div />}

			{showCancelModal && <div />}

			{showAddNoteModal && (
				<AddNoteModal
					onClose={() => setShowAddNoteModal(false)}
					onSave={(note) => {
						dispatch(addOrderNote(orderId!, note));
						setShowAddNoteModal(false);
					}}
				/>
			)}

			{showEditClientModal && (
				<EditClientInformationModal
					onClose={() => {
						setShowEditClientModal(false);
						dispatch(clearModalError());
					}}
					order={order}
					onSubmit={updateClient}
					isInProgress={isUpdatingClient}
					error={modalError}
				/>
			)}

			{showUpdateStatusModal && (
				<UpdateOrderModal
					onClose={() => {
						setShowUpdateStatusModal(false);
					}}
					onSave={(status) => {
						setShowUpdateStatusModal(false);
						dispatch(updateOrderDetails(orderId!, { order: { status } }));
					}}
				/>
			)}

			<GridContainer>
				<DetailsContainer>
					<Notes
						isLoading={isLoadingNotes}
						notes={notes}
						onDelete={(noteId) => {
							dispatch(deleteOrderNote(orderId!, noteId));
						}}
					/>

					<OrderIdContainer>
						<OrderId>{order.generalInfo.confirmationNumber}</OrderId>
						<OrderStatus largeBadge={order.generalInfo.status === Status.returnInProgress}>
							{order.generalInfo.status}
						</OrderStatus>
					</OrderIdContainer>

					<FlexAlignContainer>
						<Subject>Total order</Subject>
						<Subject>Created</Subject>
					</FlexAlignContainer>

					<FlexAlignContainer>
						<Value>{numberToCurrency(order.paymentDetails.total)}</Value>
						<Value>{formatDateWithTime(order.generalInfo.created)}</Value>
					</FlexAlignContainer>

					<PaymentDetailsContainer>
						<PaymentDetailsHeader>Payment details</PaymentDetailsHeader>
						<PaymentDetailsRow>
							<Subject>Amount</Subject>
							<Value>{numberToCurrency(order.paymentDetails.amount)}</Value>
						</PaymentDetailsRow>
						<PaymentDetailsRow>
							<Subject>Tax</Subject>
							<Value>{numberToCurrency(order.paymentDetails.tax)}</Value>
						</PaymentDetailsRow>
						<PaymentDetailsRow>
							<Subject>Total</Subject>
							<Value>{numberToCurrency(order.paymentDetails.total)}</Value>
						</PaymentDetailsRow>
					</PaymentDetailsContainer>

					<DetailsList
						detailsList={[
							{
								label: 'Full Name',
								value: order.clientInfo.fullName,
								link: `/users/${order.clientInfo.uuid}`
							},
							{
								label: 'Shipping Address',
								value: order.clientInfo.streetAddress // TODO: concat fields
							},
							{ label: 'Email', value: order.clientInfo.email },
							{ label: 'Phone', value: order.clientInfo.phone },
							{ label: 'UUID', value: order.clientInfo.uuid },
							{
								label: 'Last Login',
								value: formatDateWithTime(order.clientInfo.lastLogin)
							}
						]}
						title="Client info"
						isEditable={true}
						onEditClick={() => setShowEditClientModal(true)}
					/>

					<DetailsList
						detailsList={[
							{ label: 'Rating', value: order.feedback.rating },
							{ label: 'Description', value: order.feedback.description }
						]}
						title="feedback"
						isEditable={false}
					/>
				</DetailsContainer>

				{isLoadingItems ? (
					'Loading items...'
				) : (
					<div>
						<TableHeader>
							<TableTitle>{items.length} items in order</TableTitle>
							<ButtonsContainer>
								<ActionButtonWrapper>
									<DropDown
										trigger={
											<Button color="primary">
												Actions <Chevron />
											</Button>
										}
										isVisible={isActionsDropdownVisible}
										setIsVisible={setIsActionsDropdownVisible}
									>
										<TableActionsDropdown actionsList={orderDetailsActionsList} />
									</DropDown>
								</ActionButtonWrapper>
							</ButtonsContainer>
						</TableHeader>

						<TableWrapper>
							<Table records={items} columns={columns} isClickable={false} rowKey="uuid" />
						</TableWrapper>
					</div>
				)}
			</GridContainer>
		</PageLayout>
	);
};

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 160px;
`;

const DetailsContainer = styled.div`
	min-width: 300px;
`;

interface Badge {
	largeBadge: boolean;
}

const OrderStatus = styled.div<Badge>`
	display: flex;
	min-width: ${(props) => (props.largeBadge ? `inherit` : `100px`)};
	width: fit-content;
	white-space: nowrap;
	padding: 0 10px;
	margin-left: 10px;
	height: 30px;
	border-radius: 4px;
	border: 1px solid #dbdbdb;
	font-size: 14px;
	font-weight: 300;
	justify-content: center;
	align-items: center;
`;

const OrderId = styled.div`
	font-size: 28px;
	font-weight: 300;
	width: 85%;
`;

const OrderIdContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 24px;
`;

const PaymentDetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 34px;
	width: 138px;
`;

const PaymentDetailsHeader = styled.div`
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 24px;
`;

const PaymentDetailsRow = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 4px;
`;

const TableTitle = styled.div`
	height: 35px;
	display: flex;
	align-items: center;
	font-size: 16px;
	font-weight: 500;
`;

const TableHeader = styled.div`
	display: flex;
	justify-content: space-between;
`;

const FlexAlignContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
`;

const Subject = styled.div`
	display: inline-block;
	font-size: 14px;
	color: ${palette.lightGray};
	font-weight: 300;
	margin-bottom: 8px;
`;

const Value = styled.div`
	display: inline-block;
	font-size: 14px;
	font-weight: 300;
`;

const Chevron = styled(ChevronIcon)`
	width: 10px;
	height: 7.5px;
	margin-left: 10px;
	margin-top: 5px;
`;

const ActionButtonWrapper = styled.div`
	position: relative;
`;

const TableWrapper = styled.div`
	width: 70%;
	margin-top: 55px;
`;

export default OrderDetails;
