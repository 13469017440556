import {
	SessionDetailsResponse,
	SessionsRequestParams,
	SessionsResponse,
	TSessionId,
	TSubscriptionId,
	CancelSessionResponse,
	CancelSubscriptionResponse,
	EndSessionResponse,
	RefundSessionResponse,
	SessionBoardsResponse,
	SessionItemsResponse,
	SessionOrderResponse,
	SessionRefund,
	SessionBoard,
	SessionItem
} from './types';
import { CreateNoteResponse, DeleteNoteResponse, FetchNotesResponse, Note } from 'redux/types';
import { emptySplitApi } from 'redux/base-api';

const apiWithTag = emptySplitApi.enhanceEndpoints({
	addTagTypes: ['Sessions', 'Session', 'SessionBoards', 'SessionItems', 'SessionOrders', 'SessionNotes']
});

const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getSessions: build.query<SessionsResponse, SessionsRequestParams>({
			query: (params) => ({
				url: '/session/paginate',
				method: 'GET',
				params
			}),
			providesTags: (result, error, params) => [{ type: 'Sessions', id: JSON.stringify(params) }]
		}),
		getSessionDetails: build.query<SessionDetailsResponse, { sessionId: TSessionId }>({
			query: ({ sessionId }) => ({
				url: `/session/${sessionId}`,
				method: 'GET'
			}),
			providesTags: (result, error, { sessionId }) => [{ type: 'Session', id: sessionId }]
		}),
		getSessionBoards: build.query<SessionBoard[], { sessionId: TSessionId }>({
			query: ({ sessionId }) => ({
				url: `/session/${sessionId}/boards`,
				method: 'GET'
			}),
			transformResponse: (response: SessionBoardsResponse) => response.boards,
			providesTags: (result, error, { sessionId }) => [{ type: 'SessionBoards', id: sessionId }]
		}),
		getSessionItems: build.query<SessionItem[], { sessionId: TSessionId }>({
			query: ({ sessionId }) => ({
				url: `/session/${sessionId}/items`,
				method: 'GET'
			}),
			transformResponse: (response: SessionItemsResponse) => response.items,
			providesTags: (result, error, { sessionId }) => [{ type: 'SessionItems', id: sessionId }]
		}),
		getSessionOrders: build.query<SessionItem[], { sessionId: TSessionId }>({
			query: ({ sessionId }) => ({
				url: `/session/${sessionId}/order`,
				method: 'GET'
			}),
			transformResponse: (response: SessionOrderResponse) => response.order,
			providesTags: (result, error, { sessionId }) => [{ type: 'SessionOrders', id: sessionId }]
		}),
		cancelSession: build.mutation<CancelSessionResponse, { sessionId: TSessionId }>({
			query: ({ sessionId }) => ({
				url: `/session/${sessionId}/cancel`,
				method: 'PUT'
			}),
			invalidatesTags: (result, error, { sessionId }) => ['Sessions', { type: 'Session', id: sessionId }]
		}),
		cancelSubscription: build.mutation<CancelSubscriptionResponse, { sessionId: TSessionId, subscriptionId: TSubscriptionId }>({
			query: ({ subscriptionId }) => ({
				url: `/subscription/${subscriptionId}`,
				method: 'DELETE'
			}),
			invalidatesTags: (result, error, { sessionId }) => ['Sessions', { type: 'Session', id: sessionId }]
		}),
		forceEndSession: build.mutation<EndSessionResponse, { sessionId: TSessionId }>({
			query: ({ sessionId }) => ({
				url: `/session/${sessionId}/end`,
				method: 'PUT'
			}),
			invalidatesTags: (result, error, { sessionId }) => ['Sessions', { type: 'Session', id: sessionId }]
		}),
		refundSession: build.mutation<RefundSessionResponse, { sessionId: TSessionId; refundOptions: SessionRefund }>({
			query: ({ sessionId, refundOptions }) => ({
				url: `/session/${sessionId}/refund`,
				method: 'POST',
				body: refundOptions
			}),
			invalidatesTags: (result, error, { sessionId }) => ['Sessions', { type: 'Session', id: sessionId }]
		}),
		getSessionNotes: build.query<Note[], { sessionId: TSessionId }>({
			query: ({ sessionId }) => ({
				url: `/session/${sessionId}/notes`,
				method: 'GET'
			}),
			transformResponse: (response: FetchNotesResponse) => response.notes,
			providesTags: (result, error, { sessionId }) => [{ type: 'SessionNotes', id: sessionId }]
		}),
		addSessionNote: build.mutation<CreateNoteResponse, { sessionId: TSessionId; text: string }>({
			query: ({ sessionId, text }) => ({
				url: `/session/${sessionId}/note`,
				method: 'POST',
				body: { text }
			}),
			invalidatesTags: (result, error, { sessionId }) => [{ type: 'SessionNotes', id: sessionId }]
		}),
		deleteSessionNote: build.mutation<DeleteNoteResponse, { sessionId: TSessionId; noteId: string }>({
			query: ({ sessionId, noteId }) => ({
				url: `/session/${sessionId}/note/${noteId}`,
				method: 'DELETE'
			}),
			invalidatesTags: (result, error, { sessionId }) => [{ type: 'SessionNotes', id: sessionId }]
		})
	})
});

export const {
	useGetSessionsQuery,
	useGetSessionDetailsQuery,
	useAddSessionNoteMutation,
	useCancelSessionMutation,
	useCancelSubscriptionMutation,
	useDeleteSessionNoteMutation,
	useGetSessionNotesQuery,
	useForceEndSessionMutation,
	useGetSessionBoardsQuery,
	useGetSessionItemsQuery,
	useGetSessionOrdersQuery,
	useRefundSessionMutation
} = extendedApi;
