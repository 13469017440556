import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { palette } from 'constants/styles';
import { IRadioTheme } from 'components/checkbox';
import { TFilterControllerOption } from '../../constants/types';

interface IRadioGroupController {
	name?: string;
	value: string | undefined;
	items: TFilterControllerOption[] | string[];
	defaultValue?: string;
	onChange: (value: string) => void;
	theme?: IRadioTheme;
	column?: boolean;
}

const RadioGroupController: React.FC<IRadioGroupController> = ({
	name,
	value,
	items,
	onChange,
	theme = 'primary',
	column = false
}) => {
	const processedItems = useMemo<TFilterControllerOption[]>(
		() => items.map((item) => (typeof item === 'string' ? { value: item, label: item } : item)),
		[items]
	);

	return (
		<Container column={column}>
			{processedItems.map((item) => (
				<Label key={item.value} column={column}>
					<RadioContainer>
						<RadioCircle active={value === item.value} theme={theme} />
						<Input
							name={name}
							type="radio"
							value={item.value}
							onChange={() => {
								onChange(item.value);
							}}
						/>
					</RadioContainer>
					<span>{item.label}</span>
				</Label>
			))}
		</Container>
	);
};

type ColumnProps = {
	column?: boolean;
};

const Container = styled.div`
	overflow-y: ${(props: ColumnProps) => (props.column ? 'scroll' : 'visible')};
	max-height: 300px;
	flex-wrap: wrap;
	display: flex;
	gap: 1.5rem;
`;

const Label = styled.label`
	display: flex;
	gap: 0.5rem;
	font-size: 14px;
	font-weight: 300;
	align-items: center;
	cursor: pointer;
	text-transform: capitalize;
	flex: ${(props: ColumnProps) => (props.column ? '100%' : 'none')};
`;

const RadioContainer = styled.div`
	position: relative;
`;

const Input = styled.input`
	position: absolute;
	inset: 0;
	opacity: 0;
`;

const RadioCircle = styled.div<{ active: boolean; theme: IRadioTheme }>`
	width: 16px;
	height: 16px;
	border: 1px solid ${({ theme }) => (theme === 'primary' ? palette.primaryColor : palette.darkGray)};
	border-radius: 50%;
	position: relative;

	${({ active, theme }) =>
		active &&
		`
  :after {
    content: '';
    border-radius: inherit;
    background: ${theme === 'primary' ? palette.primaryColor : palette.darkGray};
    width: 8px;
    height: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  `}
`;

export default RadioGroupController;
