import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { useMemo } from 'react';

export type TPartnerId = string;

export type TPartnersState = {
	selectedPartnerId?: TPartnerId;
};

const initialState: TPartnersState = {};

export const partners = createSlice({
	name: 'partners',
	initialState,
	reducers: {
		selectPartner: (state, action: PayloadAction<TPartnerId>) => {
			state.selectedPartnerId = action.payload;
		}
	}
});

export const { selectPartner } = partners.actions;

export const useSelectedPartnerId = () => {
	const selectedPartner = useSelector((state: RootState) => state.partners.selectedPartnerId);
	return useMemo(() => selectedPartner, [selectedPartner]);
};
