import React from 'react';
import styled from '@emotion/styled';
import RCTable from 'rc-table';
import { ColumnsType, TableComponents } from 'rc-table/es/interface';
import { palette } from 'constants/styles';
import { getDomElementIndex } from 'utils/helpers';
import { DefaultRecordType } from 'rc-table/lib/interface';
import TableSort from './table-sort';

interface ColumnsTypeSort extends ColumnsType<any> {
	sortable?: boolean;
	title?: string;
	key?: string;
}
interface ITable<RecordType> {
	records: Array<RecordType>;
	columns: ColumnsTypeSort;
	onRowClick?: (record: RecordType) => void;
	rowKey?: string;
	selectedRowsIds?: Array<string>;
	isClickable: boolean;
	dataTestId?: string;
	isAllRowClickable?: boolean;
}

interface IRow {
	'data-row-key': string;
	className: string;
	style: {};
	onClick: () => void;
}

function Table<RecordType extends DefaultRecordType>({
	records,
	columns,
	onRowClick,
	rowKey,
	selectedRowsIds,
	isClickable,
	dataTestId,
	isAllRowClickable
}: ITable<RecordType>) {
	const columnsWithSort = columns.map((col, index) => ({
		...col,
		onHeaderCell: (column: ColumnsTypeSort) => ({
			sortable: column.sortable,
			title: column.title,
			sortKey: column.key
		})
	}));

	const TableComponent: React.FC = ({ children, ...props }) => (
		<StyledTable role="table" isClickable={isClickable} {...props}>
			{children}
		</StyledTable>
	);

	const HeaderCell: React.FC = ({ children, ...props }) => {
		const { sortable, title, sortKey } = props as Record<string, any>;
		return (
			<StyledHeader role="th" {...props}>
				{!sortable ? children : <TableSort title={title} sortKey={sortKey} />}
			</StyledHeader>
		);
	};

	const Cell: React.FC = ({ children, ...props }) => (
		<StyledCell role="td" {...props}>
			{children}
		</StyledCell>
	);

	const Row: React.FC<IRow> = ({ children, ...props }) => (
		<StyledRow
			role="tr"
			isClickable={isClickable}
			isSelected={selectedRowsIds && selectedRowsIds.includes(props['data-row-key'])}
			{...props}
		>
			{children}
		</StyledRow>
	);

	const components: TableComponents<RecordType> = {
		table: TableComponent,

		header: {
			cell: HeaderCell
		},

		body: {
			cell: Cell,
			row: Row
		}
	};

	return (
		<RCTable<RecordType>
			data-testid={dataTestId}
			data={records}
			columns={columnsWithSort as any}
			components={components}
			onRow={(record) => ({
				onClick: (e) => {
					const index = getDomElementIndex(e.target as HTMLElement);
					(isAllRowClickable || index) && onRowClick && onRowClick(record);
				}
			})}
			rowKey={rowKey}
		/>
	);
}

const StyledTable = styled.table<{ isClickable: boolean }>`
	width: 100%;
	border-collapse: collapse;
`;

const StyledHeader = styled.th`
	text-align: start;
	padding-bottom: 16px;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
`;

const StyledCell = styled.td`
	text-align: start;
	border-bottom: solid 1px ${palette.primaryGray};
	font-size: 14px;
	font-weight: 300;
`;

const StyledRow = styled.tr<{ isSelected?: boolean; isClickable: boolean }>`
	background-color: ${(props) => (props.isSelected ? palette.primaryGray : 'white')};
	height: 36px;
	cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};

	&:hover {
		background-color: ${(props) => (props.isClickable ? palette.primaryGray : 'white')};
	}
`;

export default Table;
