import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { setOrderItemTrackingNumber } from 'routes/order-details/redux/actions';
import { Input } from 'components/form/input';

export const OrderItemTrackingNumberCell: React.FC<{
	trackingNumber: string;
	itemId: string;
}> = ({ trackingNumber, itemId }) => {
	const dispatch = useDispatch();

	return (
		<Container>
			<Input
				placeholder="Add tracking number"
				defaultValue={trackingNumber || ''}
				onBlur={(event) => {
					dispatch(setOrderItemTrackingNumber(itemId, event.target.value));
				}}
			/>
		</Container>
	);
};

const Container = styled.div`
	margin-right: 20px;
`;
