import React, { useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import {
	useGetGlobalCategoriesByIdQuery,
	useUpdateGlobalCategoriesMutation,
	useGetRetailerByIdQuery
} from 'routes/inventory/redux/inventory-slice';
import TableNoResults from 'components/table/table-no-results';
import Checkbox from 'components/checkbox';
import { ListTitle, ListItem, Label } from './tabs-view.style';
import { FileCategory } from 'routes/inventory/redux/types';
import GenericModalMessege from '../modals/generic-messege-modal';
import { modalMessage } from 'utils/inventory-utils';

const GlobalCategories: React.FC<{ retailerId: string }> = ({ retailerId }) => {
	const { data: globalCategories, isLoading } = useGetGlobalCategoriesByIdQuery(retailerId ?? skipToken);
	const { data: retailerDetails } = useGetRetailerByIdQuery(retailerId);
	const [showModal, setShowModal] = useState(false);
	const [updatedGloablCategories, { isError, isLoading: isLoadingMutation }] = useUpdateGlobalCategoriesMutation();

	if (isLoading) return <div>Loading Retailers Global Categories...</div>;
	if (!globalCategories || globalCategories.length === 0) return <TableNoResults />;

	const updateGlobalCategory = (event: boolean, category: FileCategory) => {
		const params = { ...category, active: event };
		updatedGloablCategories({
			id: retailerDetails?.id ?? '',
			params
		});
	};

	const isShowModal = !isLoadingMutation && showModal;

	return (
		<>
			{isShowModal && (
				<GenericModalMessege
					onClose={() => setShowModal(false)}
					title={''}
					isError={isError}
					buttonText={'Okay'}
					message={isError ? modalMessage.globalCategories.error : modalMessage.globalCategories.success}
				/>
			)}
			<div>
				<ListTitle>{`${retailerDetails?.name} Categories`}</ListTitle>
				<ul>
					{globalCategories.map((category, index) => (
						<ListItem key={index}>
							<Checkbox
								isChecked={category.active}
								onChange={(event) => updateGlobalCategory(event, category)}
							/>
							<Label>{category.primary}</Label>
						</ListItem>
					))}
				</ul>
			</div>
		</>
	);
};

export default GlobalCategories;
