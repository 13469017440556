import { DashboardRequestParams, DashboardResponse } from 'routes/dashboard/redux/types';
import { APIAction } from 'redux/actions/network';

export const SET_METRICS = '[dashboard] SET_METRICS';
export const FETCH_METRICS = '[dashboard] FETCH_METRICS';

export const setMetrics = (response: DashboardResponse) => ({
	type: SET_METRICS,
	payload: response
});

export const fetchMetrics = (params?: DashboardRequestParams): APIAction<DashboardResponse> => ({
	type: FETCH_METRICS,
	meta: { api: true },
	payload: {
		method: 'GET',
		path: '/analytics/dashboard',
		networkLabel: 'dashboard',
		params,
		onSuccess: setMetrics
	}
});
