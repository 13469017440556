import { useState } from 'react';
import { xor } from 'lodash';

export const useRowSelectionState = <RecordType>(options: { rowKey: string; records: Array<RecordType> }) => {
	const [selectedRowsIds, setSelectedRowsIds] = useState<Array<string>>([]);

	return {
		selectedRowsIds,
		toggleRow: (row: RecordType) => {
			setSelectedRowsIds(xor(selectedRowsIds, [(row as any)[options.rowKey]]));
		},
		deselectAllRows: () => {
			setSelectedRowsIds([]);
		},
		toggleAllRows: () => {
			if (selectedRowsIds.length === options.records.length) {
				setSelectedRowsIds([]);
			} else {
				setSelectedRowsIds(options.records.map((record) => (record as any)[options.rowKey]));
			}
		},
		isRowSelected: (row: RecordType) => {
			return selectedRowsIds.includes((row as any)[options.rowKey]);
		}
	};
};
