import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { loginMessages, loginParams, storageParams } from 'routes/login/consts';
import { useDispatch } from 'react-redux';
import { setAPIToken } from 'redux/actions/auth';

const RedirectHandler = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const token = searchParams.get(loginParams.token);
	const message = searchParams.get(loginParams.message);
	const dispatch = useDispatch();

	if (message === loginMessages.success && token) {
		localStorage.setItem(storageParams.accessToken, token);
	}

	useEffect(() => {
		if (token) {
			dispatch(setAPIToken(token));
			navigate('/', { replace: true });
			return;
		}

		const urlParams = new URLSearchParams({
			message: message ?? 'unauthorized'
		});

		navigate(`/login?${urlParams.toString()}`);
	}, [message, navigate, token, dispatch]);

	return <></>;
};
export default RedirectHandler;
