import { FC } from 'react';
import { Navigate, Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';
import { isB2CPartnerId } from 'utils/helpers';
import { useSelectedPartnerId } from 'redux/partners-slice';

const B2BRoutes: FC = () => {
	const location = useLocation();
	const selectedPartnerId = useSelectedPartnerId();

	return isB2CPartnerId(selectedPartnerId) ? <Outlet /> : <Navigate to="/users" state={{ from: location }} replace />;
};

export default B2BRoutes;
