import { APIAction } from 'redux/actions/network';
import { ExportOrderRequest, OrdersRequestParams, OrdersResponse } from 'routes/orders/redux/types';

export const SET_ORDERS = '[orders] SET_ORDERS';
export const FETCH_ORDERS = '[orders] FETCH_ORDERS';
export const EXPORT_ORDERS = '[orders] EXPORT_ORDERS';

export const setOrders = (response: OrdersResponse) => ({
	type: SET_ORDERS,
	payload: { orders: response.items, paginationMeta: response.meta }
});

export const fetchOrders = (params?: OrdersRequestParams): APIAction<OrdersResponse> => ({
	type: FETCH_ORDERS,
	meta: { api: true },
	payload: {
		method: 'GET',
		path: '/order/paginate',
		networkLabel: 'orders',
		params,
		onSuccess: setOrders
	}
});

export const exportOrders = (params: ExportOrderRequest): APIAction<never> => ({
	type: EXPORT_ORDERS,
	meta: { api: true },
	payload: {
		method: 'POST',
		path: `/export/order`,
		params,
		networkLabel: 'exportOrders'
	}
});
