import { handleActions } from 'redux-actions';
import { CouponsState } from 'routes/coupons/redux/types';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { State } from 'redux/types';
import * as AT from 'routes/coupons/redux/actions';
import { SET_METADATA, setMetadata } from 'redux/actions/metadata';

const initialState: CouponsState = {
	coupons: [],
	paginationMeta: {},
	campaigns: [],
	metadata: null,
	modalError: null
};

export const couponsReducer = handleActions(
	{
		[AT.FETCH_COUPONS]: (state) => ({
			...state,
			coupons: [],
			paginationMeta: {}
		}),

		[AT.SET_COUPONS]: (state, action: ReturnType<typeof AT.setCoupons>) => ({
			...state,
			coupons: action.payload.coupons,
			paginationMeta: action.payload.paginationMeta
		}),

		[AT.SET_COUPON_CAMPAIGNS]: (state, action: ReturnType<typeof AT.setCouponCampaigns>) => ({
			...state,
			campaigns: action.payload.campaigns
		}),

		[SET_METADATA]: (state, action: ReturnType<typeof setMetadata>) => ({
			...state,
			metadata: action.payload.metadata.coupons
		}),

		[AT.SET_MODAL_ERROR]: (state, action: ReturnType<typeof AT.setModalError>) => ({
			...state,
			modalError: action.payload.error
		})
	},
	initialState
);

export const useCouponsSelector: TypedUseSelectorHook<CouponsState> = (selector, ...args) =>
	useSelector((state: State) => selector(state.coupons), ...args);
