import React, { useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useGetItemCategoriesQuery } from 'routes/inventory/redux/inventory-slice';
import TableNoResults from 'components/table/table-no-results';
import { ItemCategory } from 'routes/inventory/redux/types';
import Table from 'components/table/table';
import { itemCategoriesColumnsConfig } from 'routes/inventory/config';
import UpdateItemCategoryModal from '../modals/update-item-category-modal';
import TableResults from 'components/table-results';
import { useURLState } from 'hooks';
import { customPagination } from 'components/table/table-utils';

const ItemCategories: React.FC<{ retailerId: string }> = ({ retailerId }) => {
	const { data: itemCategories, isLoading } = useGetItemCategoriesQuery(retailerId ?? skipToken);
	const [showModal, setShowModal] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState<ItemCategory>();
	const [currentItems, setCurrentItems] = useState<ItemCategory[]>(itemCategories ?? []);
	const rowKey = 'itemCategoryID';
	const columns = itemCategoriesColumnsConfig();
	const { urlState } = useURLState();
	const onRowClick = (rowRecord: ItemCategory) => {
		setSelectedCategory(rowRecord);
		setShowModal(true);
	};

	useEffect(() => {
		const page = urlState.page ? urlState.page : 1;
		setCurrentItems(customPagination(page, itemCategories ?? []));
	}, [urlState.page, itemCategories]);

	if (isLoading) return <div>Loading Retailers Item Categories...</div>;
	if (!itemCategories || itemCategories.length === 0) return <TableNoResults />;

	return (
		<>
			{showModal && (
				<UpdateItemCategoryModal
					category={selectedCategory as ItemCategory}
					retailerId={retailerId}
					onClose={() => setShowModal(false)}
				/>
			)}

			<TableResults paginationData={{ totalItems: itemCategories.length }} />

			<Table<ItemCategory>
				dataTestId="itemCategory-table"
				columns={columns}
				rowKey={rowKey}
				records={currentItems}
				isClickable={true}
				onRowClick={onRowClick}
				isAllRowClickable={true}
			/>
		</>
	);
};

export default ItemCategories;
