import React, { InputHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import DateController from 'components/form/date-controller';

interface IDateController extends InputHTMLAttributes<HTMLInputElement> {
	fromValue: string;
	toValue: string;
}

const DateRangeController: React.FC<IDateController> = ({ name, fromValue, toValue, onChange }) => (
	<Container>
		<InputContainer>
			<InputLabel>From:</InputLabel>
			<DateController name={`${name}.from`} value={fromValue} onChange={onChange} />
		</InputContainer>
		<InputContainer>
			<InputLabel>To:</InputLabel>
			<DateController name={`${name}.to`} value={toValue} onChange={onChange} />
		</InputContainer>
	</Container>
);

const Container = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
`;

const InputContainer = styled.div`
	flex: 1;
`;

const InputLabel = styled.label`
	font-size: 0.9rem;
	margin-bottom: 0.25rem;
	display: block;
`;

export default DateRangeController;
