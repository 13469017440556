import { FC } from 'react';
import styled from '@emotion/styled';
import { useGetSessionBoardsQuery } from '../redux';

interface IBoards {
	sessionId: string;
}

export const BoardsTab: FC<IBoards> = ({ sessionId }) => {
	const { data: boards, isFetching } = useGetSessionBoardsQuery({ sessionId });

	if (isFetching) {
		return <div>Loading boards</div>;
	}

	if (boards?.length === 0) {
		return <div>No boards</div>;
	}

	return (
		<Container>
			{boards?.map((board, index) => (
				<Image key={index} src={board.imgUrl} />
			))}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const Image = styled.div<{ src: string }>`
	border: 1px solid #dbdbdb;
	height: 270px;
	width: 270px;
	background: url(${(props) => props.src}) no-repeat center/contain;
	margin-right: 25px;
	margin-bottom: 25px;
`;
