import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as WarningIcon } from 'assets/images/warning.svg';

const ErrorBar: React.FC = ({ children }) => {
	return (
		<Container>
			<Icon />
			<div>{children}</div>
		</Container>
	);
};

const Container = styled.nav`
	display: flex;
	gap: 1rem;
	background: #fff5fa;
	padding: 1rem;
`;

const Icon = styled(WarningIcon)`
	width: 20px;
	height: auto;
`;

export default ErrorBar;
