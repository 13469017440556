import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { OrderItemStatus } from 'routes/order-details/redux/types';
import { setOrderItemStatus } from 'routes/order-details/redux/actions';
import { Select } from 'components/form/select';

export const OrderStatusCell: React.FC<{
	status: OrderItemStatus;
	itemId: string;
}> = ({ status, itemId }) => {
	const dispatch = useDispatch();

	return (
		<Container>
			<Select
				value={status}
				onChange={(event) => {
					dispatch(setOrderItemStatus(itemId, event.target.value as OrderItemStatus));
				}}
			>
				<option value="ordered">Ordered</option>
				<option value="shipped">Shipped</option>
				<option value="arrived">Arrived</option>
				<option value="cancel">Cancel</option>
				<option value="return in process">Return in process</option>
				<option value="returned">Returned</option>
				<option value="refunded">Refunded</option>
				<option value="partly refunded">Partly refunded</option>
				<option value="oos">OOS</option>
				<option value="final sale">Final Sale</option>
			</Select>
		</Container>
	);
};

const Container = styled.div`
	margin-right: 20px;
`;
