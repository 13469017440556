import React, { ChangeEvent, FC, useState } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as SearchIconSVG } from 'assets/images/search.svg';
import { palette } from 'constants/styles';
import { useDebounce } from '../hooks';

interface ISearchInput {
	value: string;
	props?: Record<string, string>;
	onChange: (newValue: string) => void;
}

interface ICustomStyle {
	paddingLeft?: string;
	left?: string;
}

const SearchInput: FC<ISearchInput> = ({ value, props, onChange }) => {
	const [innerValue, setInnerValue] = useState<string>(value || '');
	const debounce = useDebounce(onChange, 500);

	const onInnerChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setInnerValue(value);
		debounce(value);
	};

	return (
		<Container paddingLeft={props?.paddingLeft}>
			<SearchIcon left={props?.left} />
			<Input placeholder="Search" value={innerValue} onChange={onInnerChange} />
		</Container>
	);
};

const Container = styled.div<ICustomStyle>`
	display: flex;
	position: relative;
	padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '54px')};
`;

const SearchIcon = styled(SearchIconSVG)<ICustomStyle>`
	height: 13px;
	width: 13px;
	position: absolute;
	left: ${(props) => (props.left ? props.left : '68px')};
	top: 8px;
`;

const Input = styled.input`
	border-radius: 12.5px;
	background-color: ${palette.primaryGray};
	border: none;
	font-size: 14px;
	color: black;
	font-weight: 200;
	padding: 0 34px;
	width: 275px;
	height: 28px;

	::placeholder {
		font-size: 14px;
		color: black;
		font-weight: 200;
	}

	:focus {
		outline: 0;
	}
`;

export default SearchInput;
