import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { palette } from 'constants/styles';
import { FC } from 'react';
import { useSelectedPartnerId } from 'redux/partners-slice';
import { isB2CPartnerId } from 'utils/helpers';

const Navigation: FC = () => {
	const selectedPartnerId = useSelectedPartnerId();

	return (
		<Container>
			{isB2CPartnerId(selectedPartnerId) ? (
				<>
					<Link to="/">Home</Link>
					<Link to="/users">Users</Link>
					<Link to="/sessions">Sessions</Link>
					<Link to="/orders">Orders</Link>
					<Link to="/coupons">Coupons</Link>
					<Link to="/inventory/retailers">Inventory</Link>
				</>
			) : (
				<>
					<Link to="/users">Users</Link>
					<Link to="/sessions">Sessions</Link>
				</>
			)}
		</Container>
	);
};

const Container = styled.nav`
	display: flex;
	background: white;
	padding: 20px 0;
	border-bottom: solid 2px ${palette.primaryGray};
	margin: 0 40px 20px;
`;

const Link = styled(NavLink)`
	text-decoration: none;
	font-weight: 300;
	color: black;
	font-size: 14px;
	display: inline-block;
	padding: 0.25rem 1rem;
	border-radius: 100px;

	&.active {
		background: ${palette.primaryColor};
		color: white;
	}
`;

export default Navigation;
