import React, { useState } from 'react';
import styled from '@emotion/styled';
import Button from 'components/button';
import { palette } from 'constants/styles';
import TableMenuFilters from 'components/table/table-menu-filters';
import SearchInput from 'components/search-input';
import { ReactComponent as DeleteIcon } from 'assets/images/delete.svg';
import { ReactComponent as FilterIcon } from 'assets/images/filter.svg';
import { ReactComponent as ExportIcon } from 'assets/images/export.svg';
import { ReactComponent as ChevronIcon } from 'assets/images/chevron.svg';
import { IActionItem, IFilterItem } from 'constants/types';
import DropDown from 'components/drop-down';
import TableActionsDropdown from './table-actions-dropdown';
import { useFiltersURLState } from 'hooks';
import FilterNumber from 'components/table/filter-number';
import { AutoCompleteOptions } from './types';

interface ITableActionsBar {
	tableTitle: string;
	selectedRows: Array<string>;
	onDeselectAll: () => void;
	onDelete?: () => void;
	onExport?: () => void;
	quickFilters: Array<IFilterItem>;
	filters: Array<IFilterItem>;
	actionsList?: Array<IActionItem>;
	customStyle?: Record<string, any>;
	autoCompleteOptions?: Record<string, AutoCompleteOptions[]>;
}

const TableActions: React.FC<ITableActionsBar> = ({
	tableTitle,
	selectedRows,
	onDeselectAll,
	onDelete,
	onExport,
	quickFilters,
	filters,
	actionsList,
	customStyle,
	autoCompleteOptions
}) => {
	const [filtersVisible, setFiltersVisible] = useState(false);
	const [isActionsDropdownVisible, setIsActionsDropdownVisible] = useState(false);
	const { activeFiltersCount } = useFiltersURLState(filters);
	const { filtersURLState, setFiltersURLState } = useFiltersURLState(quickFilters);

	const selectedRowsState = (
		<FlexAlignContainer isAlignBaseline={false}>
			<SelectedCount>{selectedRows.length} selected</SelectedCount>
			<Divider>|</Divider>

			<Deselect onClick={onDeselectAll}>Deselect</Deselect>

			{onDelete && (
				<Button onClick={onDelete}>
					<Delete />
					Delete
				</Button>
			)}
			{onExport && (
				<Button onClick={onExport}>
					<Export /> Export
				</Button>
			)}
		</FlexAlignContainer>
	);

	const noRowsSelectedState = (
		<FlexAlignContainer isAlignBaseline={true}>
			{filters.length && (
				<FilterButton>
					<DropDown
						trigger={
							<Button>
								<Filter /> Filter
								<FilterNumber val={activeFiltersCount} />
							</Button>
						}
						isVisible={filtersVisible}
						setIsVisible={setFiltersVisible}
					>
						<TableMenuFilters
							filters={filters}
							onSubmit={() => {
								setFiltersVisible(false);
							}}
							autoCompleteOptions={autoCompleteOptions}
						/>
					</DropDown>
				</FilterButton>
			)}

			{!!actionsList?.length &&
				(actionsList.length === 1 ? (
					<Button color="primary" onClick={actionsList[0].onActivate}>
						{actionsList[0].text}
					</Button>
				) : (
					<DropDown
						trigger={
							<Button color="primary">
								Actions <Chevron />
							</Button>
						}
						isVisible={isActionsDropdownVisible}
						setIsVisible={setIsActionsDropdownVisible}
					>
						<TableActionsDropdown actionsList={actionsList} />
					</DropDown>
				))}

			{onExport && (
				<Button onClick={onExport}>
					<Export /> Export
				</Button>
			)}
		</FlexAlignContainer>
	);

	return (
		<Container>
			<FlexAlignContainer isAlignBaseline={true}>
				{tableTitle && <TableTitle>{tableTitle}</TableTitle>}
				<SearchInput
					value={filtersURLState.search}
					onChange={(value) => {
						setFiltersURLState({ ...filtersURLState, search: value });
					}}
					props={customStyle?.searchInput}
				/>
			</FlexAlignContainer>

			{selectedRows.length > 0 ? selectedRowsState : noRowsSelectedState}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 35px;
	width: 100%;
`;

const TableTitle = styled.div`
	min-width: 135px;
	font-size: 28px;
	font-weight: 300;
`;

const FlexAlignContainer = styled.div<{ isAlignBaseline: boolean }>`
	display: flex;
	align-items: ${(props) => (props.isAlignBaseline ? 'end' : 'center')};
	position: relative;
	gap: 1rem;
	min-height: 30px;
`;

const SelectedCount = styled.div`
	color: ${palette.lightGray};
	font-size: 14px;
	font-weight: 300;
`;

const Deselect = styled.div`
	color: ${palette.primaryColor};
	font-size: 14px;
	font-weight: 300;
	cursor: pointer;

	&:hover {
		color: black;
	}
`;

const Divider = styled.div`
	height: 17px;
	color: ${palette.lightGray};
	font-size: 14px;
	margin: 0 10px;
	font-weight: 300;
`;

const Delete = styled(DeleteIcon)`
	width: 9px;
	height: 9px;
	margin-right: 7px;
`;

const Filter = styled(FilterIcon)`
	width: 16px;
	height: 21px;
`;

const Export = styled(ExportIcon)`
	width: 19px;
	height: 13px;
	margin-right: 5px;
`;

const FilterButton = styled.div`
	position: relative;
`;

const Chevron = styled(ChevronIcon)`
	margin-left: 5px;
	cursor: pointer;

	g {
		fill: white;
	}
`;

export default TableActions;
