import { DateRange, MetadataResponse, PaginationMetaMixin } from 'redux/types';

export type TUserId = string;

export interface UserRecord {
	created: string;
	name: string;
	username: string;
	wishiId: string;
	stripeId: string;
	email: string;
	spend: number;
}

export interface UsersState {
	users: Array<UserRecord>;
	paginationMeta: Partial<PaginationMetaMixin>;
	metadata: MetadataResponse['users'] | null;
	modalError: string | null;
}

export interface UsersResponse {
	meta: PaginationMetaMixin;
	items: Array<UserRecord>;
}

export type UserType = 'all' | 'customers' | 'members';

export interface UsersRequestParams {
	'created.from'?: string;
	'created.to'?: string;
	'spent.amount'?: Array<number>;
	'spent.comparator'?: 'GT' | 'LT' | 'EQ' | 'BTW';
	search?: string;
	type?: UserType;
	limit?: number;
	page?: number;
}

export interface AddUserResponse {
	success: boolean;
	uuid: string;
	password: string;
}

export interface ExportUserRequest {
	'customRange.from': string;
	'customRange.to': string;
	dateRange: DateRange;
	columns:
		| 'created'
		| 'clientId'
		| 'email'
		| 'name'
		| 'gender'
		| 'spend'
		| 'age'
		| 'location'
		| 'ordersNum'
		| 'body'
		| 'brands'
		| 'signupMethod'
		| 'signupPlatform'
		| 'bookingsNum'
		| 'webLogins'
		| 'appLogins'
		| 'totalLogins';
}

export enum UserBodyEnum {
	'BODY_TYPE_CURVY',
	'BODY_TYPE_PLUS_SIZE',
	'BODY_TYPE_HOURGLASS',
	'BODY_TYPE_REVERSE_TRIANGLE',
	'BODY_TYPE_FIT',
	'BODY_TYPE_LONG_AND_LEAN',
	'BODY_TYPE_SLIM',
	'BODY_TYPE_AVERAGE',
	'BODY_TYPE_ATHLETIC',
	'BODY_TYPE_HUSKY',
	'curvy',
	'plus_size',
	'hourglass',
	'reverse_triangle',
	'fit',
	'long_and_lean'
}

export type Gender = 'female' | 'male' | 'neutral';
export type BadgeName = 'isHighSpender' | 'isHighEngager' | 'isStylist' | 'isInactive' | 'bronze' | 'gold' | 'platinum';

export interface UserData {
	userFirstName: string;
	userLastName?: string;
	userEmail: string;
	userName: string;
	userPhoneNumber?: string;
	userBirthDate?: string;
	userLocations?: string;
	userBodyType: keyof UserBodyEnum;
	userGender: Gender;
	userPassword?: string;
}

export interface UserGeneralInfo {
	firstName: string;
	lastName: string;
	username: string;
	createdAt: string;
	totalSpend: number;
	badges: Array<BadgeName>;
}

export interface UserPersonalInfo {
	gender: string;
	birthday: string;
	age: string;
	location: string;
	bodyType: string;
	stylePreference: string;
}

export interface UserTechnicalInfo {
	signupMethod: string;
	email: string;
	signupPlatform: string;
	firebaseToken: string;
	logins: number;
	appLogins: number;
	lastLogin: string;
	clientVersion?: string;
}

export interface UserDeviceInfo {
	device: string;
	osVersion: string;
	browser: string;
}
export interface UserDetailsResponse {
	generalInfo: UserGeneralInfo;
	personalInfo: UserPersonalInfo;
	technicalInfo: UserTechnicalInfo;
	deviceInfo?: UserDeviceInfo;
}

export interface ResetUserPasswordResponse {
	success: boolean;
	password: string;
}

export interface UserExpertResponse {
	success: boolean;
}

export interface UserExpertRequestParams {
	action: 'add' | 'remove';
}
