import React, { useEffect, useState } from 'react';
import { useURLState } from 'hooks';
import { formatDateForController } from 'utils/dates';
import {
	ComparePeriod,
	DatePeriod,
	getComparedPeriod,
	getPeriodDate,
	getShortComparePeriod,
	getShortDatePeriod
} from 'routes/dashboard/utils/dates';
import PeriodSelectController, { IOption } from 'components/form/period-select-controller';
import {
	ComparedToLabel,
	CurrentTimeFrame,
	DateInput,
	TimeComparisonHeader
} from 'components/styles/date-picker.style';

interface IDatePeriodPicker {
	range: DatePeriod;
	compare: ComparePeriod;
}

const DatePeriodPicker: React.FC<IDatePeriodPicker> = ({ range, compare }) => {
	const { urlState, setURLState } = useURLState();

	const [form, setForm] = useState<IDatePeriodPicker>({
		range: range,
		compare: compare
	});

	const onChange = (property?: string, value?: string) => {
		property &&
			setForm((form: IDatePeriodPicker) => ({
				...form,
				[property]: value
			}));

		if (property === 'range') {
			setForm((form: IDatePeriodPicker) => ({
				...form,
				compare: ComparePeriod.NONE
			}));
		}
	};

	const rangeOptions: IOption[] = Object.values(DatePeriod).map((option) => ({
		label: option,
		value: option,
		controlLabel: getShortDatePeriod(option)
	}));

	const compareOptions: IOption[] = Object.values(ComparePeriod).map((option) => ({
		label: option,
		value: option,
		controlLabel: option === ComparePeriod.NONE ? ComparePeriod.NONE : getShortComparePeriod(form.range, option)
	}));

	useEffect(() => {
		const { range, compare } = form;
		const rangeFrom = getPeriodDate(range);
		const rangeTo = Date.today();
		const [compareFrom, compareTo] = getComparedPeriod(range, compare);

		setURLState(
			{
				...urlState,
				rangeFrom: formatDateForController(rangeFrom),
				rangeTo: formatDateForController(rangeTo),
				compareFrom: formatDateForController(compareFrom),
				compareTo: formatDateForController(compareTo)
			},
			true
		);
	}, [form, urlState, setURLState]);

	return (
		<TimeComparisonHeader>
			<CurrentTimeFrame>Date Period</CurrentTimeFrame>
			<DateInput>
				<PeriodSelectController
					name="range"
					id="range"
					value={{
						label: form.range,
						value: form.range,
						controlLabel: getShortDatePeriod(form.range)
					}}
					options={rangeOptions}
					onChange={(newValue, actionMeta) => onChange(actionMeta.name, newValue?.value)}
				/>
			</DateInput>
			<ComparedToLabel>compared to</ComparedToLabel>
			<DateInput>
				<PeriodSelectController
					name="compare"
					id="compare"
					value={{
						label: form.compare,
						value: form.compare,
						controlLabel:
							form.compare === ComparePeriod.NONE
								? ComparePeriod.NONE
								: getShortComparePeriod(form.range, form.compare)
					}}
					options={compareOptions}
					onChange={(newValue, actionMeta) => {
						onChange(actionMeta.name, newValue?.value);
					}}
				/>
			</DateInput>
		</TimeComparisonHeader>
	);
};

export default DatePeriodPicker;
