import { IFilterItem } from 'constants/types';

export const quickFilters: IFilterItem[] = [
	{
		id: 'search',
		displayName: 'Search',
		placeHolder: 'Search',
		controller: 'text'
	},
	{
		id: 'type',
		displayName: 'Type',
		controller: 'text',
		controllerItems: [
			{ value: 'all', label: 'All' },
			{ value: 'top 10', label: 'Top 10' },
			{ value: 'loyalty', label: 'Loyalty' },
			{ value: 'attentive', label: 'Attentive' },
			{ value: 'friend referral', label: 'Friend referral' },
			{ value: 'ambassadors', label: 'Ambassadors' }
		]
	}
];

export const menuFilters = (campaigns: string[]): Array<IFilterItem> => [
	{
		id: 'created',
		displayName: 'Created date',
		controller: 'date-range'
	},
	{
		id: 'campaign',
		displayName: 'Campaign',
		controller: 'select',
		controllerItems: campaigns
	},
	{
		id: 'status',
		displayName: 'Status',
		controller: 'radio-list-column',
		controllerItems: [
			{ value: 'active', label: 'Active' },
			{ value: 'inactive', label: 'Inactive' },
			{ value: 'capped', label: 'capped' },
			{ value: 'expired', label: 'Expired' }
		]
	}
];
