import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as NoResultsIllustration } from 'assets/images/no-results.svg';
import { palette } from 'constants/styles';

const TableNoResults = () => {
	return (
		<Container data-testid="table-no-results">
			<TextContainer>
				<Header>No results found</Header>
				<Description>There aren't any results for selected filters</Description>
			</TextContainer>

			<NoResultsIllustration />
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 107px;
	padding: 0 36px;
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-right: 36px;
`;

const Header = styled.div`
	font-size: 28px;
	font-weight: 300;
	text-align: center;
	margin-bottom: 3px;
`;

const Description = styled.div`
	font-weight: 300;
	font-size: 14px;
	color: ${palette.lightGray};
	text-align: center;
`;

export default TableNoResults;
