import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from 'components/table/table';
import { UserRecord } from 'routes/users/redux/types';
import TypeFilters from 'components/table/type-filters';
import TableActions from 'components/table/table-actions';
import TableNoResults from 'components/table/table-no-results';
import { menuFilters, quickFilters } from 'routes/users/filters';
import { getColumnsConfig } from 'routes/users/config';
import { useRowSelectionState } from 'utils/use-row-selection-state';
import { AddEditUserModal, DeleteUsersModal } from '../components';
import { useNetworkSelector } from 'redux/reducers/network';
import TableResults from 'components/table-results';
import { useDispatch } from 'react-redux';
import { exportUsers } from '../redux/actions';
import ExportModal from 'components/export/export-modal';
import { useURLState } from 'hooks';
import { useGetMetadataQuery } from 'apiSlices';
import { useGetUsersQuery } from '../redux';
import styled from '@emotion/styled';
import { useSelectedPartnerId } from 'redux/partners-slice';
import { isB2CPartnerId } from 'utils/helpers';

const Container = styled.div``;

export const UsersPage: React.FC = () => {
	const { urlState } = useURLState();
	const selectedPartnerId = useSelectedPartnerId();
	const navigateTo = useNavigate();
	const [showAddNewUserModal, setShowAddNewUserModal] = useState(false);
	const [showExportModalOpen, setShowExportModalOpen] = useState(false);
	const [showDeleteUsersModal, setShowDeleteUsersModal] = useState(false);
	const rowKey = 'wishiId';
	const { data: metadata } = useGetMetadataQuery({});
	const { data: usersData, isFetching: isFetchingUsers } = useGetUsersQuery(urlState, {
		skip: !urlState.partnerId
	});

	const { isExportingUsers } = useNetworkSelector((state) => ({
		isExportingUsers: state.exportUsers > 0
	}));

	const dispatch = useDispatch();

	const { selectedRowsIds, isRowSelected, toggleRow, deselectAllRows, toggleAllRows } = useRowSelectionState({
		rowKey,
		records: usersData?.items || []
	});

	const onRowClick = (rowRecord: UserRecord) => {
		navigateTo(`/users/${rowRecord.wishiId}`);
	};

	const actionsList = isB2CPartnerId(selectedPartnerId)
		? [
				{
					text: 'Add new',
					onActivate: () => setShowAddNewUserModal(true)
				}
		  ]
		: [];

	const columns = getColumnsConfig(urlState?.partnerId, {
		allRowsSelected: selectedRowsIds.length === usersData?.items.length,
		isRowSelected,
		toggleAllRows,
		toggleRow
	});

	return (
		<Container>
			{isFetchingUsers ? (
				'Loading users...'
			) : (
				<>
					<TableActions
						tableTitle="Users"
						selectedRows={selectedRowsIds}
						onDeselectAll={deselectAllRows}
						onDelete={() => setShowDeleteUsersModal(true)}
						onExport={() => setShowExportModalOpen(true)}
						actionsList={actionsList}
						quickFilters={quickFilters}
						filters={menuFilters}
					/>

					{isB2CPartnerId(selectedPartnerId) && <TypeFilters filters={quickFilters} />}

					{usersData?.meta && <TableResults paginationData={usersData?.meta} />}

					{usersData?.items.length ? (
						<Table
							columns={columns}
							records={usersData.items}
							onRowClick={onRowClick}
							rowKey={rowKey}
							selectedRowsIds={selectedRowsIds}
							isClickable={true}
						/>
					) : (
						<TableNoResults />
					)}
				</>
			)}
			{showExportModalOpen && (
				<ExportModal
					exportType="users"
					exportFields={metadata?.users.exportFields || []}
					onClose={() => {
						setShowExportModalOpen(false);
					}}
					isInProgress={isExportingUsers}
					onSubmit={(form) => {
						dispatch(exportUsers({ ...form, partnerId: selectedPartnerId }));
					}}
				/>
			)}
			{showDeleteUsersModal && (
				<DeleteUsersModal
					onClose={() => {
						setShowDeleteUsersModal(false);
					}}
					userIdsToDelete={selectedRowsIds}
				/>
			)}
			{showAddNewUserModal && (
				<AddEditUserModal
					action="add"
					onClose={() => {
						setShowAddNewUserModal(false);
					}}
				/>
			)}
		</Container>
	);
};
