import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import DetailsList from 'components/details-list';
import Button from 'components/button';
import TableActionsDropdown from 'components/table/table-actions-dropdown';
import { numberToCurrency } from 'utils/money';
import { ReactComponent as ChevronIcon } from 'assets/images/chevron-white.svg';
import { palette } from 'constants/styles';
import DropDown from 'components/drop-down';
import { Navigate, NavLink, Route, Routes, useParams } from 'react-router-dom';
import { BoardsTab, ItemsTab, SessionRefundModal } from '../components';
import { formatDateWithTime } from 'utils/dates';
import GenericActionModal from 'components/generic-action-modal';
import Notes from 'components/notes';
import AddNoteModal from 'components/add-note-modal';
import { isOverdue } from 'routes/sessions/dashboard-utils/maps';
import {
	SessionStatus,
	SubscriptionStatus,
	TSessionId,
	useAddSessionNoteMutation,
	useCancelSessionMutation,
	useCancelSubscriptionMutation,
	useDeleteSessionNoteMutation,
	useForceEndSessionMutation,
	useGetSessionDetailsQuery,
	useGetSessionNotesQuery,
	useRefundSessionMutation
} from '../redux';
import { useSelectedPartnerId } from 'redux/partners-slice';
import { isB2CPartnerId } from 'utils/helpers';

export const SessionDetails = () => {
	const { id: sessionId } = useParams();
	const selectedPartnerId = useSelectedPartnerId();
	const isB2CPartner = isB2CPartnerId(selectedPartnerId);
	const [isActionsDropdownVisible, setIsActionsDropdownVisible] = useState(false);
	const [isCancelSessionModalVisible, setIsCancelSessionModalVisible] = useState(false);
	const [isCancelSubscriptionModalVisible, setIsCancelSubscriptionModalVisible] = useState(false);
	const [isRefundModalVisible, setIsRefundModalVisible] = useState(false);
	const [isForceEndVisible, setIsForceEndVisible] = useState(false);
	const [showAddNoteModal, setShowAddNoteModal] = useState(false);
	const [addSessionNote] = useAddSessionNoteMutation();
	const [cancelSession] = useCancelSessionMutation();
	const [cancelSubscription] = useCancelSubscriptionMutation();
	const [deleteSessionNote] = useDeleteSessionNoteMutation();
	const [forceEndSession] = useForceEndSessionMutation();
	const [refundSession] = useRefundSessionMutation();

	const { data: session, isFetching: isFetchingSession } = useGetSessionDetailsQuery(
		{ sessionId: sessionId as TSessionId },
		{
			skip: !sessionId
		}
	);

	const { data: notes, isFetching: isFetchingNotes } = useGetSessionNotesQuery(
		{ sessionId: sessionId as TSessionId },
		{
			skip: !sessionId
		}
	);

	const sessionActions = useMemo(() => {
		if (isB2CPartner) {
			const dynamicActions = [];
			if (session?.sessionInfo.status !== SessionStatus.canceled) dynamicActions.push(
				{
					text: 'Cancel booking',
					onActivate: () => setIsCancelSessionModalVisible(true)
				}
			);
			if (session?.subscriptionInfo.uuid && session.subscriptionInfo.status !== SubscriptionStatus.canceled) dynamicActions.push(
				{
					text: 'Cancel subscription',
					onActivate: () => setIsCancelSubscriptionModalVisible(true)
				}
			);
			return dynamicActions.concat([
				{
					text: 'Refund',
					onActivate: () => setIsRefundModalVisible(true)
				},
				{
					text: 'Force end',
					onActivate: () => setIsForceEndVisible(true)
				},
				{
					text: 'Add note',
					onActivate: () => setShowAddNoteModal(true)
				}
			]);
		} else {
			return [
				{
					text: 'Add note',
					onActivate: () => setShowAddNoteModal(true)
				}
			];
		}
	}, [isB2CPartner, session]);

	const sessionInfo = useMemo(() => {
		const { sessionInfo } = session || {};

		if (!sessionInfo) {
			return [];
		}

		const { stylist, client, status, plan, coupon, pricingModel, sla } = sessionInfo;

		if (isB2CPartner) {
			return [
				{ label: 'Stylist', value: stylist },
				{ label: 'Client', value: client, link: `/users/${sessionInfo.clientUuid}` },
				{ label: 'Status', value: status },
				{ label: 'Plan', value: plan },
				{ label: 'Coupon', value: coupon },
				{ label: 'Pricing Model', value: pricingModel },
				{ label: 'SLA', value: sla, isDanger: sla ? isOverdue(sla) : false }
			];
		} else {
			return [
				{ label: 'Stylist', value: stylist },
				{ label: 'Client', value: client, link: `/users/${sessionInfo.clientUuid}` },
				{ label: 'Status', value: status },
				{ label: 'SLA', value: sla, isDanger: sla ? isOverdue(sla) : false }
			];
		}
	}, [session, isB2CPartner]);

	if (!sessionId || !session || isFetchingSession) {
		return <></>;
	}

	return (
		<Container>
			<SessionHeader>
				<SessionHeaderSection>
					<SessionKey>{session.generalInfo.uuid}</SessionKey>
				</SessionHeaderSection>

				<SessionHeaderSection>
					<ActionButtonWrapper>
						<DropDown
							trigger={
								<Button color="primary">
									Actions <Chevron />
								</Button>
							}
							isVisible={isActionsDropdownVisible}
							setIsVisible={setIsActionsDropdownVisible}
						>
							<TableActionsDropdown actionsList={sessionActions} />
						</DropDown>
					</ActionButtonWrapper>
				</SessionHeaderSection>
			</SessionHeader>

			<GridContainer>
				<div>
					{notes && (
						<Notes
							isLoading={isFetchingNotes}
							notes={notes}
							onDelete={(noteId) => {
								deleteSessionNote({ sessionId, noteId });
							}}
						/>
					)}

					<SpaceBetweenFlexContainer>
						<Subject>Total Spend</Subject>
						<Subject>Created</Subject>
					</SpaceBetweenFlexContainer>

					<SpaceBetweenFlexContainer>
						<Value>{numberToCurrency(session.generalInfo.total)}</Value>
						<Value>{formatDateWithTime(session.generalInfo.created)}</Value>
					</SpaceBetweenFlexContainer>

					<PaymentsDetailsContainer>
						<PaymentDetailsTitle>Payment details</PaymentDetailsTitle>

						<PaymentDetail>
							<PaymentDetailTitle>Amount:</PaymentDetailTitle>
							<PaymentDetailValue>
								{numberToCurrency(Number(session.paymentDetails.amount))}
							</PaymentDetailValue>
						</PaymentDetail>

						<PaymentDetail>
							<PaymentDetailTitle>Tax:</PaymentDetailTitle>
							<PaymentDetailValue>
								{numberToCurrency(Number(session.paymentDetails.tax))}
							</PaymentDetailValue>
						</PaymentDetail>

						<PaymentDetail>
							<PaymentDetailTitle>Total:</PaymentDetailTitle>
							<PaymentDetailValue>
								{numberToCurrency(Number(session.paymentDetails.total))}
							</PaymentDetailValue>
						</PaymentDetail>
					</PaymentsDetailsContainer>

					<DetailsList detailsList={sessionInfo} title="Session info" isEditable={false} />

					<DetailsList
						detailsList={[
							{ label: 'Satisfaction', value: session.feedback.satisfaction },
							{ label: 'Rating', value: session.feedback.rating },
							{ label: 'Description', value: session.feedback.description }
						]}
						title="Feedback"
						isEditable={false}
					/>
				</div>

				<div>
					<Nav>
						<NavItem to="boards">Boards</NavItem>
						<NavItem to="items">Items</NavItem>
					</Nav>

					<RoutesWrapper>
						<Routes>
							<Route path="boards" element={<BoardsTab sessionId={sessionId} />} />
							<Route path="items" element={<ItemsTab sessionId={sessionId} />} />
							<Route path="" element={<Navigate replace to="boards" />} />
						</Routes>
					</RoutesWrapper>
				</div>
			</GridContainer>

			{isCancelSessionModalVisible && (
				<GenericActionModal
					title="Cancel booking"
					body="Canceling the booking will terminate it immediately"
					actionButtonText="Cancel booking"
					onClose={() => setIsCancelSessionModalVisible(false)}
					onSubmit={() => {
						cancelSession({ sessionId });
						setIsCancelSessionModalVisible(false);
					}}
				/>
			)}

			{isCancelSubscriptionModalVisible && (
				<GenericActionModal
					title="Cancel subscription"
					body="Canceling a trialing subscription will terminate it immediately"
					actionButtonText="Cancel subscription"
					onClose={() => setIsCancelSubscriptionModalVisible(false)}
					onSubmit={() => {
						cancelSubscription({ sessionId, subscriptionId: session?.subscriptionInfo.uuid });
						setIsCancelSubscriptionModalVisible(false);
					}}
				/>
			)}

			{isRefundModalVisible && (
				<SessionRefundModal
					onClose={() => setIsRefundModalVisible(false)}
					onSubmit={(amount, reason, description) => {
						refundSession({
							sessionId,
							refundOptions: {
								refundAmountInCents: amount,
								cancellationReason: reason,
								detailedDescription: description
							}
						});

						setIsRefundModalVisible(false);
					}}
				/>
			)}

			{isForceEndVisible && (
				<GenericActionModal
					title="Force end session"
					body="This will end your session without getting your client’s approval."
					actionButtonText="Force end session"
					onClose={() => setIsForceEndVisible(false)}
					onSubmit={() => {
						forceEndSession({ sessionId });
						setIsForceEndVisible(false);
					}}
				/>
			)}

			{showAddNoteModal && (
				<AddNoteModal
					onClose={() => setShowAddNoteModal(false)}
					onSave={(text) => {
						addSessionNote({ sessionId, text });
						setShowAddNoteModal(false);
					}}
				/>
			)}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: 250px 1fr;
	column-gap: 160px;
`;

const SessionHeader = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2rem;
`;

const SessionHeaderSection = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
`;

const SessionKey = styled.div`
	font-size: 28px;
	font-weight: 300;
`;

const NavItem = styled(NavLink)`
	height: 35px;
	display: flex;
	align-items: center;
	font-size: 16px;
	font-weight: 300;
	color: black;
	text-decoration: none;

	&.active {
		font-weight: 500;
	}
`;

const Nav = styled.div`
	display: flex;
	gap: 1.5rem;
`;

const SpaceBetweenFlexContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Subject = styled.div`
	font-size: 14px;
	color: ${palette.lightGray};
	font-weight: 300;
	margin-bottom: 8px;
`;

const Value = styled.div`
	font-size: 14px;
	font-weight: 300;
`;

const Chevron = styled(ChevronIcon)`
	width: 10px;
	height: 8px;
	margin-left: 10px;
	margin-top: 5px;
`;

const ActionButtonWrapper = styled.div`
	position: relative;
`;

const RoutesWrapper = styled.div`
	width: 70%;
	margin-top: 55px;
`;

const PaymentsDetailsContainer = styled.div`
	margin-top: 55px;
	display: flex;
	flex-direction: column;
	gap: 0.333rem;
`;

const PaymentDetailsTitle = styled.div`
	margin-bottom: 1rem;
	text-transform: uppercase;
	font-size: 14px;
`;

const PaymentDetail = styled.div`
	display: flex;
	gap: 1rem;
	font-size: 14px;
	font-weight: 300;
`;

const PaymentDetailTitle = styled.div`
	min-width: 100px;
`;

const PaymentDetailValue = styled.div``;
