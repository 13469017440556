import React from 'react';
import { ResultsNumber, TableHeaderContainer, TableTitle } from './tabs-view.style';
import { formatNumber } from 'utils/helpers';
import SearchInput from 'components/search-input';
import { useFiltersURLState } from 'hooks';
import { quickFilters } from '../../filters';

interface ITableHeader {
	title?: string;
	total: number;
}

const customStyle = {
	paddingLeft: '0px',
	left: '10px'
};

const TableHeader: React.FC<ITableHeader> = ({ title, total }) => {
	const { filtersURLState, setFiltersURLState } = useFiltersURLState(quickFilters);

	return (
		<>
			<TableHeaderContainer>
				{title && <TableTitle>{title}</TableTitle>}
				<ResultsNumber>
					{total >= 10000 ? '> ' : ''}
					{formatNumber(total)} results
				</ResultsNumber>
				<SearchInput
					value={filtersURLState.search}
					onChange={(value) => setFiltersURLState({ ...filtersURLState, search: value })}
					props={customStyle}
				/>
			</TableHeaderContainer>
		</>
	);
};

export default TableHeader;
