import React from 'react';
import Modal, { ModalActions, ModalBody } from 'components/modal';
import Button from 'components/button';
import { Fieldset, Message } from './modal.style';

interface IGenericModalMessage {
	title: string;
	isError: boolean;
	message: string;
	buttonText: string;
	onClose: () => void;
	buttonAction?: () => void;
}

const GenericModalMessege: React.FC<IGenericModalMessage> = ({
	title,
	message,
	isError,
	buttonText,
	onClose,
	buttonAction
}) => {
	return (
		<Modal title={title} onClose={onClose}>
			<ModalBody>
				<form onSubmit={onClose} data-testid="modal-container">
					<Fieldset>
						<Message isError={isError}>{message}</Message>
					</Fieldset>
					<ModalActions>
						<Button
							onClick={buttonAction ? buttonAction : onClose}
							color="primary"
							data-testid="modal-submit-button"
						>
							{buttonText}
						</Button>
					</ModalActions>
				</form>
			</ModalBody>
		</Modal>
	);
};

export default GenericModalMessege;
