import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { setOrderItemOrderNumber } from 'routes/order-details/redux/actions';
import { Input } from 'components/form/input';

export const OrderItemOrderNumberCell: React.FC<{
	orderNumber: string;
	itemId: string;
}> = ({ orderNumber, itemId }) => {
	const dispatch = useDispatch();

	return (
		<Container>
			<Input
				placeholder="Add order number"
				defaultValue={orderNumber || ''}
				onBlur={(event) => {
					dispatch(setOrderItemOrderNumber(itemId, event.target.value));
				}}
			/>
		</Container>
	);
};

const Container = styled.div`
	margin-right: 20px;
`;
