import styled from '@emotion/styled';
import { palette } from '../../../../constants/styles';

export const Fieldset = styled.fieldset`
	border: 0;
	margin-bottom: 1rem;
`;

export const SubTitle = styled.label`
	font-size: 1rem;
	font-weight: 300;
`;

export const Message = styled.label<{ isError: boolean }>`
	color: ${(props) => (props.isError ? palette.dangerColor : palette.lightGray)};
	width: 350px;
	display: block;
	font-size: 0.9rem;
	margin-bottom: 0.5rem;
	white-space: break-spaces;
	word-break: break-word;
`;

export const Label = styled.label`
	display: block;
	text-transform: uppercase;
	font-size: 0.9rem;
	margin-bottom: 0.5rem;
	margin-right: 2%;
`;

export const ToggleContainer = styled.div`
	display: flex;
	align-items: flex-end;
`;
