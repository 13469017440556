import styled from '@emotion/styled';
import { palette } from 'constants/styles';

export const ListTitle = styled.div`
	text-align: start;
	margin-bottom: 10px;
`;

export const ListItem = styled.li`
	display: flex;
	flex-direction: row;
	margin: 2px;
`;

export const Label = styled.label`
	margin-left: 10px;
`;

export const ListAction = styled.div`
	margin-top: 10px;
`;

export const ResultsNumber = styled.div`
	display: flex;
	color: ${palette.lightGray};
	font-size: 14px;
	font-weight: 300;
	padding-right: 15px;
	margin-right: 15px;
`;

export const TableHeaderContainer = styled.div`
	display: flex;
	align-items: baseline;
	margin-bottom: 25px;
`;

export const TableTitle = styled.label`
	min-width: 135px;
	font-size: 28px;
	font-weight: 300;
`;
