import styled from '@emotion/styled';
import { palette } from 'constants/styles';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
`;

export const SidebarContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const SidebarItemContainer = styled.div`
	width: 130px;
	height: fit-content;
	margin-right: 40px;
`;

export const SidebarItem = styled(NavLink)`
	display: block;
	text-decoration: none;
	color: ${palette.black};
	font-family: 'Untitled Sans', sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 17px;
	padding: 10px;
	border-left: 2px solid ${palette.borderGray};
	&:hover {
		cursor: pointer;
		transition: 0.5s;
		background-color: ${palette.borderGray};
	}
	&.active {
		color: ${palette.primaryColor};
		transition: 0.5s;
		font-weight: 500;
		border-left: 2px solid ${palette.primaryColor};
		background-color: ${palette.white};
	}
`;

export const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const Title = styled.div`
	min-width: 130px;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 35px;
	margin-top: 8px;
`;

export const HeaderContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
`;

export const DashboardHeader = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 30px;
	white-space: nowrap;
`;

export const AutoCompleteContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-left: 50px;
	align-items: center;
`;

export const AutoCompleteTitle = styled.span`
	font-size: 26px;
	margin-right: 18px;
	font-weight: 300;
`;

export const DashboardContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;

export const CardsContainer = styled.div`
	display: grid;
	gap: 2rem;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;

export const BaseLabel = styled.span`
	color: ${palette.black};
`;

export const DangerLabel = styled.span`
	color: ${palette.dangerColor};
`;
