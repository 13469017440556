import { IFilterItem } from 'constants/types';

export const quickFilters: IFilterItem[] = [
	{
		id: 'search',
		displayName: 'Search',
		placeHolder: 'Search',
		controller: 'text'
	}
];
