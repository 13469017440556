import { APIAction } from 'redux/actions/network';
import { ExportUserRequest } from 'routes/users/redux/types';

export const EXPORT_USERS = '[users] EXPORT_USERS';

export const exportUsers = (params: ExportUserRequest): APIAction<never> => ({
	type: EXPORT_USERS,
	meta: { api: true },
	payload: {
		method: 'POST',
		path: `/export/user`,
		params,
		networkLabel: 'exportUsers'
	}
});
