import { FC } from 'react';
import { PAGE_LIMIT } from 'routes/inventory/config';
import Button from 'components/button';
import { ButtonContainer, ButtonSpace } from './retailer-details.style';
import { useURLState } from 'hooks';
import { PageInfo, PaginationParams } from 'routes/inventory/redux/types';

interface IPageNavigation {
	pageInfo: PageInfo;
	setPageInfo: (params: PaginationParams) => void;
}

export const PageNavigation: FC<IPageNavigation> = ({ pageInfo, setPageInfo }) => {
	const { urlState } = useURLState();

	const getNextPage = () => {
		const nextPage = { first: PAGE_LIMIT, after: pageInfo.endCursor };
		const params = urlState.search ? { ...nextPage, search: urlState.search } : nextPage;
		setPageInfo(params);
	};

	const getPreviousPage = () => {
		const prePage = { last: PAGE_LIMIT, before: pageInfo.startCursor };
		const params = urlState.search ? { ...prePage, search: urlState.search } : prePage;
		setPageInfo(params);
	};

	return (
		<ButtonContainer>
			<Button
				data-testid="btn-previous-page"
				disabled={!pageInfo.hasPreviousPage}
				onClick={() => getPreviousPage()}
			>
				Previous
			</Button>
			<ButtonSpace />
			<Button data-testid="btn-next-page" disabled={!pageInfo.hasNextPage} onClick={() => getNextPage()}>
				Next
			</Button>
		</ButtonContainer>
	);
};
