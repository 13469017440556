import React from 'react';
import styled from '@emotion/styled';
import { Card } from 'constants/types';
import ChangeRateLabel from 'routes/dashboard/components/change-rate-label';
import { palette } from 'constants/styles';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 310px;
	background: ${palette.primaryGray};
	padding: 20px 16px;
`;

const Name = styled.span`
	font-size: 16px;
	font-weight: 500;
`;

const CardHeaderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 18px;
`;

const Value = styled.span`
	font-size: 28px;
	font-weight: 300;
`;

const Detail = styled.div<{ isDanger?: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
	margin-bottom: 8px;
	color: ${(props) => (props?.isDanger ? palette.dangerColor : palette.black)};
`;

const CardList = styled.div`
	font-weight: 100;
	margin-top: 20px;
	overflow-y: auto;
`;

const DashboardCard: React.FC<Card> = ({ label, changeRate, total, details }) => {
	return (
		<Container>
			<CardHeaderContainer>
				<Name>{label}</Name>
				<ChangeRateLabel percentage={changeRate} />
			</CardHeaderContainer>

			<Value>{total}</Value>

			<CardList>
				{details.map((row) => (
					<Detail key={row.label} isDanger={row.isDanger}>
						<div>{row.label}</div>
						<div>{row.value}</div>
					</Detail>
				))}
			</CardList>
		</Container>
	);
};

export default DashboardCard;
