import * as AT from 'redux/actions/network';
import { handleActions } from 'redux-actions';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { State } from 'redux/types';

export interface NetworkState {
	[networkLabel: string]: number;
}

const initialState: NetworkState = {};

export const networkReducer = handleActions(
	{
		[AT.NETWORK_REQUEST_START](state, action: ReturnType<typeof AT.startNetworkRequest>) {
			const label = action.payload.label;

			return {
				...state,
				[label]: state[label] ? state[label] + 1 : 1
			};
		},

		[AT.NETWORK_REQUEST_END](state, action: ReturnType<typeof AT.endNetworkRequest>) {
			const label = action.payload.label;

			return {
				...state,
				[label]: state[label] ? state[label] - 1 : 0
			};
		}
	},
	initialState
);

export const useNetworkSelector: TypedUseSelectorHook<NetworkState> = (selector, ...args) =>
	useSelector((state: State) => selector(state.network), ...args);
