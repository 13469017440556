import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { IFilterState } from '../constants/types';

export function useURLState() {
	const { search } = useLocation();
	const navigateTo = useNavigate();

	const urlState = useMemo(() => {
		const query = new URLSearchParams(search);
		let result: any = {};

		query.forEach((value, key) => {
			if (result[key]) {
				result[key] = [result[key], value];
			} else {
				result[key] = value;
			}
		});

		return result;
	}, [search]);

	const setURLState = useCallback(
		(params: IFilterState, replace = false) => {
			const query = new URLSearchParams();

			Object.keys(params).forEach((key) => {
				const value = params[key];

				if (Array.isArray(params[key])) {
					query.delete(key);

					(value as Array<string>).forEach((value) => {
						if (value) {
							query.append(key, value);
						}
					});
				} else {
					if (!value) {
						query.delete(key);
					} else {
						query.set(key, value as string);
					}
				}
			});

			navigateTo(`?${query.toString()}`, { replace });
		},
		[navigateTo]
	);

	return { urlState, setURLState };
}
