import { FC } from 'react';
import styled from '@emotion/styled';

import Button from 'components/button';
import Modal, { ModalActions, ModalBody, ModalText } from 'components/modal';
import { TUserId, useResetUserPasswordMutation } from '../redux';

interface IResetPasswordModal {
	userId: TUserId;
	onClose: () => void;
}

export const ResetPasswordModal: FC<IResetPasswordModal> = ({ userId, onClose }) => {
	const [resetPassword, { isLoading, data }] = useResetUserPasswordMutation();
	const temporaryPassword = data?.password || '';
	const onResetClick = async () => {
		await resetPassword({ userId });
	};

	if (isLoading) {
		return (
			<Modal title="Reset password" onClose={onClose}>
				<ModalBody>Generating new password...</ModalBody>
			</Modal>
		);
	}

	if (data) {
		return (
			<Modal title="Password Successfully Reset" onClose={onClose}>
				<ModalBody>
					<ModalText>
						Here is your new temporary password:
						<TemporaryPassword>{temporaryPassword}</TemporaryPassword>
					</ModalText>
				</ModalBody>
			</Modal>
		);
	}

	return (
		<Modal title="Reset password" onClose={onClose}>
			<ModalBody>
				<ModalText>
					Once you’ll hit “Reset Password” you’ll get a temporary password to hand over to the client.
				</ModalText>
				<ModalActions>
					<Button color="danger" onClick={onResetClick}>
						Reset password
					</Button>
				</ModalActions>
			</ModalBody>
		</Modal>
	);
};

const TemporaryPassword = styled.div`
	display: block;
	font-weight: bold;
	margin-top: 10px;
`;
