import { APIAction } from 'redux/actions/network';
import {
	CancelOrderResponse,
	OrderDetailsResponse,
	OrderItemsResponse,
	OrderItemStatus,
	RefundItemRequest,
	RefundOrderResponse,
	UpdateOrderItemData
} from 'routes/order-details/redux/types';
import { CreateNoteResponse, DeleteNoteResponse, FetchNotesResponse } from 'redux/types';
import { UpdateOrder } from 'routes/orders/redux/types';

export const SET_ORDER_DETAILS = '[order-details] SET_ORDER_DETAILS';
export const FETCH_ORDER_DETAILS = '[order-details] FETCH_ORDER_DETAILS';
export const CANCEL_ORDER = '[order-details] CANCEL_ORDER';
export const REFUND_ORDER = '[order-details] REFUND_ORDER';
export const FETCH_ORDER_NOTES = '[order-details] FETCH_ORDER_NOTES';
export const SET_ORDER_NOTES = '[order-details] SET_ORDER_NOTES';
export const ADD_ORDER_NOTE = '[order-details] ADD_ORDER_NOTE';
export const DELETE_ORDER_NOTE = '[order-details] DELETE_ORDER_NOTE';
export const FETCH_ORDER_ITEMS = '[order-details] FETCH_ORDER_ITEMS';
export const SET_ORDER_ITEMS = '[order-details] SET_ORDER_ITEMS';
export const UPDATE_ORDER_ITEM = '[order-details] UPDATE_ORDER_ITEM';
export const SET_ORDER_ITEM_PRICE = '[order-details] SET_ORDER_ITEM_PRICE';
export const SET_ORDER_ITEM_ORDER_NUMBER = '[order-details] SET_ORDER_ITEM_ORDER_NUMBER';
export const SET_ORDER_ITEM_TRACKING_NUMBER = '[order-details] SET_ORDER_ITEM_TRACKING_NUMBER';
export const SET_ORDER_ITEM_TRACKING_STATUS = '[order-details] SET_ORDER_ITEM_TRACKING_STATUS';
export const UPDATE_ORDER_DETAILS = '[order-details] UPDATE_ORDER_DETAILS';
export const SET_MODAL_ERROR = '[order-details] SET_MODAL_ERROR';

export const setModalError = (error: any) => {
	const { message } = error.response.data;
	const errorMessage = Array.isArray(message) ? message[0] : message;

	return {
		type: SET_MODAL_ERROR,
		payload: { error: errorMessage }
	};
};

export const clearModalError = () => ({
	type: SET_MODAL_ERROR,
	payload: { error: null }
});

export const setOrderDetails = (response: OrderDetailsResponse) => ({
	type: SET_ORDER_DETAILS,
	payload: {
		generalInfo: response.generalInfo,
		paymentDetails: response.paymentDetails,
		clientInfo: response.clientInfo,
		feedback: response.feedback
	}
});

export const setOrderNotes = (response: FetchNotesResponse) => ({
	type: SET_ORDER_NOTES,
	payload: {
		notes: response.notes
	}
});

export const setOrderItems = (response: OrderItemsResponse) => ({
	type: SET_ORDER_ITEMS,
	payload: {
		items: response.items
	}
});

export const fetchOrderDetails = (orderId: string): APIAction<OrderDetailsResponse> => ({
	type: FETCH_ORDER_DETAILS,
	meta: { api: true },
	payload: {
		method: 'GET',
		path: `/order/${orderId}`,
		networkLabel: 'orderDetails',
		onSuccess: setOrderDetails
	}
});

export const cancelOrder = (
	orderId: string,
	items: Array<{
		itemUuid: string;
		returnReason: string;
	}>
): APIAction<CancelOrderResponse> => ({
	type: CANCEL_ORDER,
	meta: { api: true },
	payload: {
		method: 'PUT',
		path: `/order/${orderId}/cancel`,
		networkLabel: 'orderDetails',
		params: { items },
		onSuccess: [() => fetchOrderDetails(orderId)]
	}
});

export const refundOrder = (orderId: string, items: Array<RefundItemRequest>): APIAction<RefundOrderResponse> => ({
	type: REFUND_ORDER,
	meta: { api: true },
	payload: {
		method: 'POST',
		path: `/order/${orderId}/refund`,
		networkLabel: 'orderDetails',
		params: { items },
		onSuccess: [() => fetchOrderDetails(orderId)]
	}
});

export const fetchOrderNotes = (orderId: string): APIAction<FetchNotesResponse> => ({
	type: FETCH_ORDER_NOTES,
	meta: { api: true },
	payload: {
		method: 'GET',
		path: `/order/${orderId}/notes`,
		networkLabel: 'orderNotes',
		onSuccess: setOrderNotes
	}
});

export const addOrderNote = (orderId: string, text: string): APIAction<CreateNoteResponse> => ({
	type: ADD_ORDER_NOTE,
	meta: { api: true },
	payload: {
		method: 'POST',
		path: `/order/${orderId}/note`,
		networkLabel: 'orderNotes',
		params: { text },
		onSuccess: [() => fetchOrderNotes(orderId)]
	}
});

export const deleteOrderNote = (orderId: string, noteId: string): APIAction<DeleteNoteResponse> => ({
	type: DELETE_ORDER_NOTE,
	meta: { api: true },
	payload: {
		method: 'DELETE',
		path: `/order/${orderId}/note/${noteId}`,
		networkLabel: 'orderNotes',
		onSuccess: [() => fetchOrderNotes(orderId)]
	}
});

export const fetchOrderItems = (orderId: string): APIAction<OrderItemsResponse> => ({
	type: FETCH_ORDER_ITEMS,
	meta: { api: true },
	payload: {
		method: 'GET',
		path: `/order/${orderId}/items`,
		networkLabel: 'orderItems',
		onSuccess: setOrderItems
	}
});

export const updateOrderItem = (
	orderId: string,
	itemId: string,
	itemDetails: UpdateOrderItemData
): APIAction<never> => ({
	type: UPDATE_ORDER_ITEM,
	meta: { api: true },
	payload: {
		method: 'PATCH',
		path: `/order/${orderId}/item/${itemId}`,
		params: itemDetails,
		networkLabel: 'orderItems',
		onSuccess: [() => fetchOrderItems(orderId)]
	}
});

export const setOrderItemPrice = (itemId: string, price: number) => ({
	type: SET_ORDER_ITEM_PRICE,
	payload: { itemId, price }
});

export const setOrderItemOrderNumber = (itemId: string, orderNumber: string) => ({
	type: SET_ORDER_ITEM_ORDER_NUMBER,
	payload: { itemId, orderNumber }
});

export const setOrderItemTrackingNumber = (itemId: string, trackingNumber: string) => ({
	type: SET_ORDER_ITEM_TRACKING_NUMBER,
	payload: { itemId, trackingNumber }
});

export const setOrderItemStatus = (itemId: string, status: OrderItemStatus) => ({
	type: SET_ORDER_ITEM_TRACKING_STATUS,
	payload: { itemId, status }
});

export const updateOrderDetails = (orderId: string, order: UpdateOrder): APIAction<never> => ({
	type: UPDATE_ORDER_DETAILS,
	meta: { api: true },
	payload: {
		method: 'PATCH',
		path: `/order/${orderId}`,
		params: order,
		networkLabel: 'updateOrderDetails',
		onSuccess: [() => fetchOrderDetails(orderId)],
		onError: setModalError
	}
});
