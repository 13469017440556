import { DashboardData } from 'constants/types';
import { SessionDashboardResponse, SessionDashboardGroup } from 'apiSlices/types';
import { slaDataMapping } from '../config';

export const isOverdue = (text: string) => text?.toLowerCase()?.includes('overdue');

export const mapToDashboardStructure = (response: SessionDashboardResponse): DashboardData => {
	return Object.entries(slaDataMapping).map(([sectionName, sectionMap]) => {
		const sectionData = response[sectionName as SessionDashboardGroup];
		const details = Object.entries(sectionMap.fieldLabels)
			.map(([field, label]) => ({
				label: label,
				value: sectionData.data[field],
				isDanger: sectionMap.overdueFields?.includes(field)
			}))
			.filter((row) => row.value !== null);

		return {
			label: sectionMap.label,
			total: sectionData.total,
			changeRate: sectionData.diff,
			details
		};
	});
};
