export const numberToCurrency = (number: number) =>
	new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 0
	}).format(number);

export const formatMoney = ({
	amount,
	decimalCount = 2,
	currency = 'USD'
}: {
	amount: number;
	decimalCount?: number;
	currency: string;
}) => {
	return amount.toLocaleString(navigator.language, {
		style: 'currency',
		currencyDisplay: 'narrowSymbol',
		currency: currency,
		maximumFractionDigits: decimalCount
	});
};
