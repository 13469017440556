import React from 'react';
import styled from '@emotion/styled';
import InputController from 'components/form/input-controller';
import { comparatorValues } from 'utils/helpers';
import { Select } from 'components/form/select';
import { TComparatorValue } from '../../constants/types';

interface INumberController {
	name: string;
	comparatorValue: string;
	onComparatorChange: (comparator: TComparatorValue) => void;
	value: string[];
	onValueChange: (newValue: string[]) => void;
}

const NumberController: React.FC<INumberController> = ({
	name,
	comparatorValue = 'GT',
	onComparatorChange,
	value,
	onValueChange
}) => {
	const [fromValue, toValue] = value || [0, 0];

	return (
		<>
			<Select
				name={`${name}.comparator`}
				value={comparatorValue}
				onChange={(event) => onComparatorChange(event.target.value as TComparatorValue)}
			>
				{Object.keys(comparatorValues).map((key) => (
					<option value={key} key={key}>
						{comparatorValues[key as TComparatorValue]}
					</option>
				))}
			</Select>
			<Inputs>
				<InputContainer>
					<InputController
						type="number"
						name={`${name}.amount`}
						value={fromValue || ''}
						onChange={(event) => onValueChange([event.target.value, toValue])}
					/>
				</InputContainer>
				<InputContainer>
					{comparatorValue === 'BTW' && (
						<InputController
							type="number"
							name={`${name}.amount-to`}
							value={toValue || ''}
							onChange={(event) => onValueChange([fromValue, event.target.value])}
						/>
					)}
				</InputContainer>
			</Inputs>
		</>
	);
};

const Inputs = styled.div`
	display: flex;
	gap: 10px;
`;

const InputContainer = styled.div`
	flex: 1;
`;

export default NumberController;
