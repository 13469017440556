import styled from '@emotion/styled';

export const Textarea = styled.textarea`
	font-size: 0.9rem;
	font-weight: 300;
	width: 100%;
	min-height: 150px;
	outline: none;
	padding: 0.5rem;
	font-family: inherit;
	border-radius: 0.33rem;
	box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.1);
	border: 1px solid #aaa;

	:focus {
		border: 1px solid #8e8e93;
	}
`;
