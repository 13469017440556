import { SessionDashboardParams, SessionDashboardResponse } from './types';
import { mapToDashboardStructure } from '../routes/sessions/dashboard-utils/maps';
import { Card } from 'constants/types';
import { emptySplitApi } from 'redux/base-api';

const apiWithTag = emptySplitApi.enhanceEndpoints({ addTagTypes: ['Metrics'] });

const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getSessionMetrics: build.query<Card[], { params?: SessionDashboardParams }>({
			query: ({ params }) => ({
				url: '/analytics/sessions/dashboard',
				method: 'GET',
				params
			}),
			transformResponse: (response: SessionDashboardResponse) => mapToDashboardStructure(response),
			providesTags: () => [{ type: 'Metrics', id: 'SessionDashboard' }]
		})
	})
});

export const { useGetSessionMetricsQuery } = extendedApi;
