import { numberToCurrency } from 'utils/money';
import { CheckboxColumn, renderCheckboxColumn, renderOptionalCell } from 'components/table/table-utils';
import { ColumnType } from 'rc-table/es/interface';
import { OrderRecord } from './redux/types';
import { formatDateWithTime } from 'utils/dates';

export const getColumnsConfig = (options: CheckboxColumn<OrderRecord>): Array<ColumnType<OrderRecord>> => {
	return [
		renderCheckboxColumn<OrderRecord>(options),
		{
			title: 'Created',
			dataIndex: 'created',
			key: 'created',
			render: formatDateWithTime
		},
		{
			title: 'Client',
			dataIndex: 'clientName',
			key: 'clientName',
			render: renderOptionalCell
		},
		{
			title: 'Order Number',
			dataIndex: 'confirmationNumber',
			key: 'confirmationNumber',
			render: renderOptionalCell
		},
		{
			title: 'Stripe Charge',
			dataIndex: 'stripeCharge',
			key: 'stripeCharge',
			render: renderOptionalCell
		},
		{
			title: '# of items',
			dataIndex: 'items',
			key: 'items',
			render: renderOptionalCell
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: renderOptionalCell
		},
		{
			title: 'Total',
			dataIndex: 'total',
			key: 'total',
			render: numberToCurrency
		}
	];
};
