import { DateRange, Note, PaginationMetaMixin } from 'redux/types';
import { TComparatorValue } from 'constants/types';

export type TSessionId = string;
export type TSubscriptionId = string;

export interface SessionRecord {
	created: string;
	clientName: string;
	stylist: string;
	plan: string;
	bookingId: string;
	status: SessionStatus;
	total: number;
	dueDate: string;
}

export interface SessionsResponse {
	meta: PaginationMetaMixin;
	items: Array<SessionRecord>;
}

export type SessionType = 'all' | 'membership' | 'single';

export type SessionPlan = 'mini' | 'major' | 'closet cleanup' | 'lux';

export enum SessionStatus {
	none = 'none',
	pendingPayment = 'pending payment',
	booked = 'booked',
	active = 'active',
	completed = 'completed',
	reassigned = 'reasigned',
	canceled = 'canceled',
	frozen = 'freezed',
	pendingClientApproval = 'pending client approval'
}

export enum SubscriptionStatus {
	trialing = 'trialing',
	active = 'active',
	past_due = 'past_due',
	unpaid = 'unpaid',
	canceled = 'canceled',
	incomplete = 'incomplete',
	incomplete_expired = 'incomplete_expired',
}

export interface SessionsRequestParams {
	'created.from'?: string;
	'created.to'?: string;
	'total.amount'?: Array<number>;
	'total.comparator'?: TComparatorValue;
	search?: string;
	type?: SessionType;
	status?: SessionStatus;
	plan?: SessionPlan;
	stylist?: string;
	limit?: number;
	page?: number;
	sortBy?: SortItem[];
}

export interface SortItem {
	key: string;
	order: string;
}

export interface ExportSessionRequest {
	'customRange.from': string;
	'customRange.to': string;
	dateRange: DateRange;
	columns:
		| 'created'
		| 'sessionId'
		| 'stylist'
		| 'name'
		| 'gender'
		| 'spend'
		| 'age'
		| 'location'
		| 'ordersNum'
		| 'signupMethod'
		| 'signupPlatform'
		| 'bookingsNum'
		| 'status'
		| 'tip'
		| 'rating'
		| 'satisfaction'
		| 'hearAboutUs'
		| 'endDate'
		| 'refunds';
}

export enum CustomLabelType {
	base = 'base',
	danger = 'danger'
}

export interface SessionDetailsResponse {
	generalInfo: SessionGeneralInfo;
	paymentDetails: SessionPaymentDetails;
	sessionInfo: SessionSessionInfo;
	feedback: SessionFeedback;
}

export interface SessionGeneralInfo {
	created: string;
	uuid: string;
	total: number;
}

export interface SessionPaymentDetails {
	total: number;
	tax: number;
	amount: number;
}

export interface SessionSessionInfo {
	stylist: string;
	client: string;
	clientUuid: string;
	status: SessionStatus;
	plan: string;
	coupon: string;
	pricingModel: string;
	sla: string;
}

export interface SessionSubscriptionInfo {
	uuid: TSubscriptionId;
	status: SubscriptionStatus;
}

export interface SessionFeedback {
	satisfaction: number;
	rating: number;
	description: string;
}

export interface SessionDetailsResponse {
	generalInfo: SessionGeneralInfo;
	paymentDetails: SessionPaymentDetails;
	sessionInfo: SessionSessionInfo;
	subscriptionInfo: SessionSubscriptionInfo;
	feedback: SessionFeedback;
}

export interface CancelSessionResponse {
	uuid: TSessionId;
	status: SessionStatus;
}

export interface CancelSubscriptionResponse {
	uuid: TSubscriptionId;
	status: SubscriptionStatus;
}

export interface EndSessionResponse {
	success: boolean;
	errorMessage: string;
}

export interface RefundSessionResponse {
	success: boolean;
	errorMessage: string;
}

export interface SessionDetailsState {
	session: {
		generalInfo: SessionGeneralInfo;
		paymentDetails: SessionPaymentDetails;
		sessionInfo: SessionSessionInfo;
		feedback: SessionFeedback;
	} | null;
	notes: Array<Note>;
	boards: Array<SessionBoard>;
	items: Array<SessionItem>;
	orders: Array<SessionItem>;
}

export interface SessionBoard {
	imgUrl: string;
}

export interface SessionBoardsResponse {
	boards: Array<SessionBoard>;
}

export interface SessionItem {
	currency: string;
	imgUrl: string;
	designer: string;
	price: number;
}

export interface SessionItemsResponse {
	items: Array<SessionItem>;
}

export interface SessionOrderResponse {
	order: Array<SessionItem>;
}

export interface SessionRefund {
	refundAmountInCents: number;
	cancellationReason: 'duplicate' | 'fraudulent' | 'requested_by_customer';
	detailedDescription: string;
}
