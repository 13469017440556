import React from 'react';
import styled from '@emotion/styled';
import Checkbox, { ICheckboxTheme } from 'components/checkbox';

interface ICheckboxListController {
	items: Array<string>;
	value: Array<string>;
	onChange: (value: Array<string>) => void;
	columns?: number;
	theme?: ICheckboxTheme;
}

const CheckboxListController: React.FC<ICheckboxListController> = ({
	items = [],
	value = [],
	onChange,
	columns = 0,
	theme = 'primary'
}) => (
	<CheckboxList columns={columns}>
		{items.map((item) => {
			const isChecked = value.includes(item);

			return (
				<CheckboxLabel key={item} columns={columns}>
					<Checkbox
						theme={theme}
						isChecked={isChecked}
						onChange={() => {
							if (isChecked) {
								onChange(value.filter((i) => i !== item));
							} else {
								onChange([...value, item]);
							}
						}}
					/>
					<Text>{item}</Text>
				</CheckboxLabel>
			);
		})}
	</CheckboxList>
);

const CheckboxList = styled.div<{ columns: number }>`
	display: flex;
	flex-wrap: wrap;
	row-gap: 1rem;
	user-select: none;
	${({ columns }) => !columns && `gap: 1rem`}
`;

const CheckboxLabel = styled.label<{ columns: number }>`
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	text-transform: capitalize;
	${({ columns }) => columns && `flex-basis: ${100 / columns}%;`}
`;

const Text = styled.div`
	font-size: 14px;
	font-weight: 300;
	margin-left: 7px;
`;

export default CheckboxListController;
