import React, { useState } from 'react';
import styled from '@emotion/styled';

import Button from 'components/button';
import Modal, { ModalActions, ModalBody } from 'components/modal';
import { SessionRefund } from '../redux';
import { Textarea } from 'components/form/textarea';
import { Input } from 'components/form/input';
import { Select } from 'components/form/select';

interface ICancelBookingModal {
	onClose: () => void;
	onSubmit: (amount: number, reason: SessionRefund['cancellationReason'], description: string) => void;
}

export const SessionRefundModal: React.FC<ICancelBookingModal> = ({ onClose, onSubmit }) => {
	const [refundAmount, setRefundAmount] = useState(0);
	const [description, setDescription] = useState('');
	const [reason, setReason] = useState<SessionRefund['cancellationReason']>('requested_by_customer');

	return (
		<Modal title="Refund payment" onClose={onClose}>
			<ModalBody>
				<InputContainer>
					<Label>Amount</Label>
					<Input
						type="number"
						value={refundAmount}
						onChange={(event) => setRefundAmount(event.target.valueAsNumber)}
					/>
				</InputContainer>
				<InputContainer>
					<Label>Reason</Label>
					<Select
						value={reason}
						onChange={(event) => setReason(event.target.value as SessionRefund['cancellationReason'])}
					>
						<option value="duplicate">Duplicate</option>
						<option value="fraudulent">Fraudulent</option>
						<option value="requested_by_customer">Requested by customer</option>
					</Select>
				</InputContainer>
				<InputContainer>
					<Label>Description</Label>
					<Textarea value={description} onChange={(event) => setDescription(event.target.value)} />
				</InputContainer>
				<ModalActions>
					<Button color="primary" onClick={() => onSubmit(refundAmount * 100, reason, description)}>
						Complete refund
					</Button>
				</ModalActions>
			</ModalBody>
		</Modal>
	);
};

const InputContainer = styled.div`
	display: flex;
	align-items: baseline;
	margin-bottom: 20px;
`;

const Label = styled.div`
	font-weight: bold;
	margin-right: 20px;
	font-size: 14px;
	width: 120px;
`;
