import React, { SelectHTMLAttributes, useMemo } from 'react';
import styled from '@emotion/styled';
import { TFilterControllerOption } from '../../constants/types';

interface ISelectController extends SelectHTMLAttributes<HTMLSelectElement> {
	options: TFilterControllerOption[] | string[];
}

const SelectController: React.FC<ISelectController> = ({ name, value, options, onChange }) => {
	const processedOptions = useMemo<TFilterControllerOption[]>(
		() => options.map((option) => (typeof option === 'string' ? { value: option, label: option } : option)),
		[options]
	);

	return (
		<Select name={name} id={name} value={value} onChange={onChange}>
			{processedOptions.map((option) => {
				const { value, label } = option as TFilterControllerOption;
				return (
					<option key={value} value={value}>
						{label}
					</option>
				);
			})}
		</Select>
	);
};

const Select = styled.select`
	width: 100%;
	padding: 0.33rem;
	border-radius: 0.33rem;
	box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.1);
	border: 1px solid #aaa;
	outline: 0;
`;

export default SelectController;
