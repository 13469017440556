import React from 'react';
import styled from '@emotion/styled';
import Pagination from 'rc-pagination';

import { palette } from 'constants/styles';
import { useURLState } from 'hooks';
import { formatNumber } from 'utils/helpers';
import { PaginationMetaMixin } from '../redux/types';

interface ITableResults {
	paginationData: Partial<PaginationMetaMixin>;
	onPaginationChange?: () => void;
}

const TableResults: React.FC<ITableResults> = ({ paginationData, onPaginationChange }) => {
	const { urlState, setURLState } = useURLState();

	return (
		<ResultsContainer>
			<ResultsNumber>{formatNumber(paginationData.totalItems || 0)} results</ResultsNumber>
			<PaginationContainer>
				<Pagination
					current={Number(urlState.page) || 1}
					showTitle={false}
					total={paginationData.totalItems}
					defaultPageSize={paginationData.itemsPerPage || 50}
					prevIcon="&larr;"
					nextIcon="&rarr;"
					jumpPrevIcon="&hellip;"
					jumpNextIcon="&hellip;"
					onChange={(current) => {
						setURLState({
							...urlState,
							page: current
						});
						onPaginationChange && onPaginationChange();
					}}
					showSizeChanger={true}
				/>
			</PaginationContainer>
		</ResultsContainer>
	);
};

const ResultsContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 30px;
`;

const ResultsNumber = styled.div`
	display: flex;
	color: ${palette.lightGray};
	font-size: 14px;
	font-weight: 300;
	padding-right: 15px;
	margin-right: 15px;
	border-right: 1px solid ${palette.lightGray};
`;

const PaginationContainer = styled.div`
	font-size: 14px;
	font-weight: 300;

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex;

		li {
			padding: 0 0.5rem;
			cursor: pointer;
			color: ${palette.lightGray};

			&.rc-pagination-disabled {
				opacity: 0.3;
				cursor: auto;
			}

			&.rc-pagination-item-active {
				color: black;
				cursor: auto;
				font-weight: 500;
			}
		}
	}
`;

export default TableResults;
