import React from 'react';

import Button from 'components/button';
import Modal, { ModalActions, ModalBody, ModalText } from 'components/modal';
import { TUserId, useGetUserDetailsQuery, useMakeUserStylistMutation } from '../redux';

interface IMakeStylistModal {
	userId: TUserId;
	onClose: () => void;
}

export const MakeStylistModal: React.FC<IMakeStylistModal> = ({ userId, onClose }) => {
	const { data: userDetails } = useGetUserDetailsQuery({ userId: userId as TUserId }, { skip: !userId });
	const [makeUserStylist, { isLoading }] = useMakeUserStylistMutation();

	if (!userDetails) {
		return <></>;
	}

	const userName = userDetails.generalInfo.firstName + ' ' + userDetails.generalInfo.lastName;
	const isStylist = userDetails.generalInfo.badges.includes('isStylist');

	const onClick = async () => {
		const result = await makeUserStylist({ userId, params: { action: isStylist ? 'remove' : 'add' } });

		if (!('error' in result)) {
			onClose();
		}
	};

	return (
		<Modal title={isStylist ? 'Remove stylist' : 'Make a stylist'} onClose={onClose}>
			<ModalBody>
				{isStylist ? (
					<ModalText>
						Remove {userName} permission from being a stylist. This will remove their stylists profile and
						access to their bookings.
					</ModalText>
				) : (
					<ModalText>
						Give {userName} permission to become a stylist. This will allow them to create their stylist
						profile.
					</ModalText>
				)}
				<ModalActions>
					<Button color="primary" onClick={onClick} disabled={isLoading}>
						{isStylist ? 'Remove stylist' : 'Make a stylist'}
					</Button>
				</ModalActions>
			</ModalBody>
		</Modal>
	);
};
