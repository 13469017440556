import { IFilterItem } from 'constants/types';

export const quickFilters: IFilterItem[] = [
	{
		id: 'search',
		displayName: 'Search',
		placeHolder: 'Search',
		controller: 'text'
	},
	{
		id: 'type',
		displayName: 'Type',
		controller: 'text',
		controllerItems: [
			{ value: 'all', label: 'All' },
			{ value: 'membership', label: 'Membership' },
			{ value: 'single', label: 'Single' }
		]
	}
];

export const B2CPartnerMenuFilters: IFilterItem[] = [
	{
		id: 'created',
		displayName: 'Created date',
		controller: 'date-range'
	},
	{
		id: 'stylistUuid',
		displayName: 'Stylist',
		controller: 'autocomplete',
		placeHolder: 'Enter Stylist Name'
	},
	{
		id: 'plan',
		displayName: 'Plan',
		controller: 'radio-list',
		controllerItems: [
			{ value: 'mini', label: 'Mini' },
			{ value: 'major', label: 'Major' },
			{ value: 'lux', label: 'Lux' }
		]
	},
	{
		id: 'status',
		displayName: 'Status',
		controller: 'radio-list-column',
		controllerItems: [
			{ value: 'none', label: 'None' },
			{ value: 'pending payment', label: 'Pending payment' },
			{ value: 'booked', label: 'Booked' },
			{ value: 'active', label: 'Active' },
			{ value: 'completed', label: 'Completed' },
			{ value: 'reasigned', label: 'Reassigned' },
			{ value: 'canceled', label: 'Canceled' },
			{ value: 'freezed', label: 'Frozen' },
			{ value: 'pending client approval', label: 'Pending client approval' }
		]
	},
	{
		id: 'dueDate',
		displayName: 'Due Date',
		controller: 'radio-list',
		controllerItems: [
			{ value: 'onTime', label: 'On Time' },
			{ value: 'overdue', label: 'Overdue' },
			{ value: 'dueToday', label: 'Due Today' }
		]
	},
	{
		id: 'pendingAction',
		displayName: 'Pending Action',
		controller: 'radio-list',
		controllerItems: [
			{ value: 'pendingMoodboard', label: 'Pending moodboard' },
			{ value: 'pendingStyleboard', label: 'Pending styleboard' },
			{ value: 'pendingRestyle', label: 'Pending restyle' },
			{ value: 'pendingStylistResponse', label: 'Pending stylist response' },
			{ value: 'pendingEndSession', label: 'Pending end session' },
			{ value: 'pendingEndSessionApproval', label: 'Pending end session approval' }
		]
	},
	{
		id: 'total',
		displayName: 'Total',
		controller: 'number'
	}
];

export const B2BPartnerMenuFilters: IFilterItem[] = [
	{
		id: 'created',
		displayName: 'Created date',
		controller: 'date-range'
	},
	{
		id: 'stylistUuid',
		displayName: 'Stylist',
		controller: 'autocomplete',
		placeHolder: 'Enter Stylist Name'
	},
	{
		id: 'status',
		displayName: 'Status',
		controller: 'radio-list-column',
		controllerItems: [
			{ value: 'none', label: 'None' },
			{ value: 'pending payment', label: 'Pending payment' },
			{ value: 'booked', label: 'Booked' },
			{ value: 'active', label: 'Active' },
			{ value: 'completed', label: 'Completed' },
			{ value: 'reasigned', label: 'Reassigned' },
			{ value: 'canceled', label: 'Canceled' },
			{ value: 'freezed', label: 'Frozen' },
			{ value: 'pending client approval', label: 'Pending client approval' }
		]
	},
	{
		id: 'dueDate',
		displayName: 'Due Date',
		controller: 'radio-list',
		controllerItems: [
			{ value: 'onTime', label: 'On Time' },
			{ value: 'overdue', label: 'Overdue' },
			{ value: 'dueToday', label: 'Due Today' }
		]
	},
	{
		id: 'pendingAction',
		displayName: 'Pending Action',
		controller: 'radio-list',
		controllerItems: [
			{ value: 'pendingStyleboard', label: 'Pending styleboard' },
			{ value: 'pendingRestyle', label: 'Pending restyle' },
			{ value: 'pendingStylistResponse', label: 'Pending stylist response' }
		]
	},
	{
		id: 'total',
		displayName: 'Total',
		controller: 'number'
	}
];
