import { StylistNameParams, StylistNameResponse } from './types';
import { emptySplitApi } from 'redux/base-api';

const apiWithTag = emptySplitApi.enhanceEndpoints({ addTagTypes: ['StylistNames'] });

const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getStylistNames: build.query<StylistNameResponse[], StylistNameParams>({
			query: (params) => ({
				url: '/stylists',
				method: 'GET',
				params
			}),
			transformResponse: (response: { stylists: StylistNameResponse[] }) => response.stylists,
			providesTags: () => [{ type: 'StylistNames', id: 'LIST' }]
		})
	})
});

export const { useGetStylistNamesQuery } = extendedApi;
