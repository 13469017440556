import React, { useEffect, useState } from 'react';
import PageLayout from 'components/page-layout';
import { OrderRecord } from './redux/types';
import TableActions from 'components/table/table-actions';
import TypeFilters from 'components/table/type-filters';
import TableNoResults from 'components/table/table-no-results';
import Table from 'components/table/table';
import { menuFilters, quickFilters } from 'routes/orders/filters';
import { getColumnsConfig } from 'routes/orders/config';
import { useRowSelectionState } from 'utils/use-row-selection-state';
import { useNavigate } from 'react-router-dom';
import TableResults from 'components/table-results';
import { useNetworkSelector } from 'redux/reducers/network';
import { useOrdersSelector } from 'routes/orders/redux/reducer';
import { useDispatch } from 'react-redux';
import { useURLState } from 'hooks';
import { exportOrders, fetchOrders } from 'routes/orders/redux/actions';
import ExportModal from 'components/export/export-modal';
import { B2CPartnerId } from 'constants/global-consts';

const OrdersPage: React.FC = () => {
	const { isLoading, isExportingOrders } = useNetworkSelector((state) => ({
		isLoading: state.orders > 0,
		isExportingOrders: state.exportOrders > 0
	}));

	const { orders, paginationData, exportFields } = useOrdersSelector((state) => ({
		orders: state.orders,
		paginationData: state.paginationMeta,
		exportFields: state.metadata?.exportFields
	}));

	const [exportModalOpen, setExportModalOpen] = useState(false);

	const dispatch = useDispatch();
	const { urlState } = useURLState();
	const navigateTo = useNavigate();
	const rowKey = 'uuid';

	const { selectedRowsIds, isRowSelected, toggleRow, deselectAllRows, toggleAllRows } = useRowSelectionState({
		rowKey,
		records: orders
	});

	const columns = getColumnsConfig({
		allRowsSelected: selectedRowsIds.length === orders.length,
		isRowSelected,
		toggleAllRows,
		toggleRow
	});

	const onRowClick = (rowRecord: OrderRecord) => {
		navigateTo(`/orders/${rowRecord.uuid}`);
	};

	useEffect(() => {
		urlState.partnerId && dispatch(fetchOrders(urlState));
	}, [urlState, dispatch]);

	return (
		<PageLayout>
			{exportModalOpen && (
				<ExportModal
					exportType="users"
					exportFields={exportFields!}
					onClose={() => setExportModalOpen(false)}
					isInProgress={isExportingOrders}
					onSubmit={(form) => {
						dispatch(exportOrders({ ...form, partnerId: B2CPartnerId }));
					}}
				/>
			)}

			<TableActions
				tableTitle="Orders"
				selectedRows={selectedRowsIds}
				onDeselectAll={deselectAllRows}
				onExport={() => setExportModalOpen(true)}
				quickFilters={quickFilters}
				filters={menuFilters}
			/>

			<TypeFilters filters={quickFilters} />

			{isLoading ? (
				'Loading orders...'
			) : paginationData.totalItems ? (
				<>
					<TableResults paginationData={paginationData} />
					<Table<OrderRecord>
						columns={columns}
						records={orders}
						onRowClick={onRowClick}
						rowKey={rowKey}
						selectedRowsIds={selectedRowsIds}
						isClickable={true}
					/>
				</>
			) : (
				<TableNoResults />
			)}
		</PageLayout>
	);
};

export default OrdersPage;
