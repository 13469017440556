import React from 'react';
import styled from '@emotion/styled';
import { getServerUrl, ServerRoute } from 'utils/server-url';
import { ReactComponent as GoogleIcon } from 'assets/images/google.svg';

interface ILoginForm {
	message: string;
}

const LoginForm: React.FC<ILoginForm> = ({ message }) => {
	return (
		<Form>
			<ErrorMessageContainer>{message && <ErrorMessage>{message}</ErrorMessage>}</ErrorMessageContainer>
			<LoginButton
				onClick={() => {
					window.open(getServerUrl(ServerRoute.auth.google), '_self');
				}}
			>
				<GoogleIcon />
				Continue wishi Google
			</LoginButton>
			<LoginText>Log in is available only with your wishi email</LoginText>
		</Form>
	);
};

const Form = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 310px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const ErrorMessage = styled.span`
	color: #ff3b30;
	font-size: 12px;
`;

const ErrorMessageContainer = styled.div`
	height: 30px;
`;

const LoginButton = styled.button`
	border-radius: 5px;
	background: white;
	border: 0;
	padding: 15px 50px;
	font-size: 1.25rem;
	white-space: nowrap;
	display: flex;
	gap: 15px;
	cursor: pointer;
`;

const LoginText = styled.div`
	color: white;
	font-size: 0.9rem;
	font-weight: 100;
	margin: 15px;
	white-space: nowrap;
`;

export default LoginForm;
