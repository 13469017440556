import styled from '@emotion/styled';

interface Width {
	width?: string;
}

export const Select = styled.select<Width>`
	padding: 6px;
	width: ${(props) => (props.width ? props.width : '100%')};
	display: block;
	border-radius: 0.33rem;
	border: 1px solid #aaa;
	outline: 0;
`;
