export const TABLE_DATA_LIMIT = 10;

export interface LdUserData {
	name: string;
	email: string;
	key: string;
}

export interface RetalierTab {
	id: RetalierTabType;
	displayName: string;
}

export enum RetalierTabType {
	items = 'items',
	itemCategories = 'categories',
	globalCategories = 'globalCategories'
}

export enum ActionType {
	UpdateShop = 'Update Shop',
	SetRanking = 'Set Ranking',
	Active = 'Active',
	Deactivated = 'Deactivated'
}

export interface RetalierAction {
	key: ActionType;
	message: string;
	title: string;
	buttonText: string;
	subTitle?: string;
	error: string;
}
