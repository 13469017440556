import { ColumnType } from 'rc-table/es/interface';
import { RetailerItemRecord, RetailerRecord, ItemCategory, RetailerStatus } from 'routes/inventory/redux/types';
import { renderOptionalCell, renderBooleanCell, renderNumberCell, ItemNotFound } from 'components/table/table-utils';
import { RetalierAction, ActionType, RetalierTabType } from './types';
import { convertToLocalDateFormat, formateNumberToString } from 'utils/inventory-utils';
import { IFilterItem } from '../../constants/types';

export const PAGE_LIMIT = 50;

export const shopPriorityList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const retailerTabs: IFilterItem[] = [
	{
		id: 'type',
		displayName: 'Type',
		controller: 'text',
		controllerItems: [
			{ value: RetalierTabType.items, label: 'Items' },
			{ value: RetalierTabType.itemCategories, label: 'Categories' },
			{ value: RetalierTabType.globalCategories, label: 'Global Categories' }
		]
	}
];

export const renderRetailerDate = (record: RetailerRecord, format: string = 'MMM DD, hh:mm A') => {
	if (!record.updatedAt) return <ItemNotFound />;
	return convertToLocalDateFormat(record.updatedAt, format);
};

export const renderRetailerItemDate = (record: RetailerItemRecord, format: string = 'MMM DD, hh:mm A') => {
	if (!record.updatedAt) return <ItemNotFound />;
	return convertToLocalDateFormat(record.updatedAt, format);
};

export const renderRetailerTotalItems = (value: any, record: RetailerRecord) => {
	if (!record.totalItems) return <ItemNotFound />;
	return value ? renderOptionalCell(value) : formateNumberToString(record.totalItems);
};

export const getRetaliersColumnsConfig = (): ColumnType<RetailerRecord>[] => [
	{
		title: 'Last Updated',
		dataIndex: 'updatedAt',
		key: 'updatedAt',
		render: (_, record: RetailerRecord) => renderRetailerDate(record)
	},
	{
		title: 'Shop',
		dataIndex: 'name',
		key: 'name',
		render: renderOptionalCell
	},
	{
		title: 'Total Number Of Items',
		dataIndex: 'totalItems',
		key: 'totalItems',
		render: (value, record: RetailerRecord) => renderRetailerTotalItems(value, record)
	},
	{
		title: 'Rank in Shop',
		dataIndex: 'priority',
		key: 'priority',
		render: renderNumberCell
	},
	{
		title: 'Status',
		dataIndex: 'active',
		key: 'active',
		render: (_, record: RetailerRecord) => (record?.active ? RetailerStatus.Active : RetailerStatus.Inactive)
	},
	{
		title: 'Affiliation',
		dataIndex: 'feed',
		key: 'feed',
		render: renderOptionalCell
	}
];

export const itemCategoriesColumnsConfig = (): ColumnType<ItemCategory>[] => [
	{
		title: 'Wishi category',
		dataIndex: 'general',
		key: 'general',
		render: renderOptionalCell
	},
	{
		title: 'Retailer Category',
		dataIndex: 'primary',
		key: 'primary',
		render: renderOptionalCell
	},
	{
		title: 'Secondary',
		dataIndex: 'secondary',
		key: 'secondary',
		render: renderOptionalCell
	},
	{
		title: 'Active',
		dataIndex: 'active',
		key: 'active',
		render: renderBooleanCell
	}
];

export const retailerItemsColumnsConfig = (): ColumnType<RetailerItemRecord>[] => [
	{
		title: 'Last modified',
		dataIndex: 'updatedAt',
		key: 'updatedAt',
		render: (_, record: RetailerItemRecord) => renderRetailerItemDate(record)
	},
	{
		title: 'Sku',
		dataIndex: 'sku',
		key: 'sku',
		render: renderOptionalCell
	},
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		render: renderOptionalCell
	},
	{
		title: 'Brand',
		dataIndex: 'brand',
		key: 'brand',
		render: renderOptionalCell
	},
	{
		title: 'Size',
		dataIndex: 'size',
		key: 'size',
		render: renderOptionalCell
	},
	{
		title: 'Category',
		dataIndex: 'category',
		key: 'category',
		render: renderOptionalCell
	}
];

export const retailerActions: RetalierAction[] = [
	{
		key: ActionType.SetRanking,
		message: 'This will determine the order in which retailers are presented on the shop.',
		title: 'Set Shop Ranking',
		buttonText: 'Save changes',
		subTitle: 'Shop Ranking',
		error: 'Fail to update retailer shop ranking'
	},
	{
		key: ActionType.Active,
		message: 'This will add <retailer> to the retailers list.',
		title: 'Active Shop',
		buttonText: 'Active retailer',
		error: 'Fail to active retailer'
	},
	{
		key: ActionType.Deactivated,
		message: 'This will remove <retailer> from the retailers list.',
		title: 'Deactivated Shop',
		buttonText: 'Deactivated retailer',
		error: 'Fail to deactive retailer shop ranking'
	}
];

export const itemCategoryAction = {
	message: 'Please select a category that this will be shown on the wishi shop.\nRetailer category: <category>',
	title: 'Edit Category',
	buttonText: 'Save changes',
	subTitle: 'Assign to Wishi category',
	error: 'Fail to update item category'
};
