import { ValueData } from 'constants/types';

export const formatDashboardValue = (value: ValueData, compact: boolean = true) => {
	if (value.type === 'money') {
		return new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 0,
			maximumFractionDigits: compact ? 1 : 2,
			notation: compact ? 'compact' : 'standard',
			compactDisplay: 'short'
		}).format(value.value as number);
	}

	if (value.type === 'number') {
		return new Intl.NumberFormat('en-US', {
			style: 'decimal',
			minimumFractionDigits: 0,
			maximumFractionDigits: compact ? 1 : 2,
			notation: compact ? 'compact' : 'standard',
			compactDisplay: 'short'
		}).format(value.value as number);
	}

	if (value.type === 'percentage') {
		return `${Number(value.value).toFixed(0)}%`;
	}

	return value.value.toString();
};
