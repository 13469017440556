import React from 'react';
import { OrderItemRecord } from 'routes/order-details/redux/types';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateOrderItem } from 'routes/order-details/redux/actions';
import { useOrderDetailsSelector } from 'routes/order-details/redux/reducer';
import Button from 'components/button';

export const SaveOrderItemCell: React.FC<{
	itemId: string;
	record: OrderItemRecord;
}> = ({ itemId, record }) => {
	const { item } = useOrderDetailsSelector((state) => ({
		item: state.items.find((item) => item.uuid === itemId)!
	}));
	const { id: orderId } = useParams();
	const dispatch = useDispatch();

	return (
		<Button
			color="primary"
			onClick={() => {
				dispatch(
					updateOrderItem(orderId!, itemId, {
						price: record.amount.price,
						status: item.status,
						orderNumber: item.orderNumber!,
						trackingNumber: item.trackingNumber!,
					})
				);
			}}
		>
			Save
		</Button>
	);
};
