import React from 'react';
import styled from '@emotion/styled';
import { palette } from 'constants/styles';
import { IActionItem } from 'constants/types';

interface ITableDropdown {
	actionsList: Array<IActionItem>;
}

const TableActionsDropdown: React.FC<ITableDropdown> = ({ actionsList }) => {
	return (
		<Container>
			{actionsList.map((action) => (
				<ActionRow
					onClick={() => {
						action.onActivate();
					}}
					key={action.text}
				>
					{action.text}
				</ActionRow>
			))}
		</Container>
	);
};

const Container = styled.div`
	width: 140px;
	border-radius: 4px;
	border: 2px solid ${palette.primaryGray};
	background-color: white;
	box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.03);
`;

const ActionRow = styled.div`
	font-size: 14px;
	color: ${palette.primaryColor};
	font-weight: 500;
	cursor: pointer;
	padding: 7px;

	&:hover {
		background-color: ${palette.primaryGray};
		color: black;
	}
`;
export default TableActionsDropdown;
