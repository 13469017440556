import { format } from 'date-fns';

const currentYear = String(new Date().getFullYear());

export const formatDateWithTime = (date: string | null) => {
	if (!date) return 'N/A';

	const formattedDate = format(new Date(date), 'MMM d, yyyy hh:mm a');

	return formattedDate.includes(currentYear) ? formattedDate.replace(`${currentYear} `, '') : formattedDate;
};

export const formatDate = (date: string) => {
	const formattedDate = format(new Date(date), 'MMM d, yyyy');

	return formattedDate.includes(currentYear) ? formattedDate.replace(`${currentYear} `, '') : formattedDate;
};

export const formatDateForController = (date?: string | Date) => new Date(date || Date.now()).toString('yyyy-MM-dd');
