import { IFilterItem } from 'constants/types';

export const quickFilters: IFilterItem[] = [
	{
		id: 'search',
		displayName: 'Search',
		placeHolder: 'Search',
		controller: 'text'
	},
	{
		id: 'type',
		displayName: 'Type',
		controller: 'text',
		controllerItems: [
			{ value: 'all', label: 'All' },
			{ value: 'customers', label: 'Customers' },
			{ value: 'members', label: 'Members' }
		]
	}
];

export const menuFilters: Array<IFilterItem> = [
	{
		id: 'created',
		displayName: 'Created date',
		controller: 'date-range'
	},
	{
		id: 'spent',
		displayName: 'Spend',
		controller: 'number'
	}
];
