import { Container, Sidebar } from './components';
import PageLayout from 'components/page-layout';
import { sidebarItems } from './config';
import { Outlet } from 'react-router-dom';

export const Sessions = () => {
	return (
		<PageLayout>
			<Container>
				<Sidebar title="Sessions" items={sidebarItems} />
				<Outlet />
			</Container>
		</PageLayout>
	);
};
