import React from 'react';

import Button from 'components/button';
import Modal, { ModalActions, ModalBody, ModalText } from 'components/modal';

interface IGenericActionModal {
	title: string;
	body: string;
	actionButtonText: string;
	onClose: () => void;
	onSubmit: () => void;
}

const GenericActionModal: React.FC<IGenericActionModal> = ({ title, body, actionButtonText, onClose, onSubmit }) => {
	return (
		<Modal title={title} onClose={onClose}>
			<ModalBody>
				<ModalText>{body}</ModalText>
				<ModalActions>
					<Button color="primary" onClick={onSubmit}>
						{actionButtonText}
					</Button>
				</ModalActions>
			</ModalBody>
		</Modal>
	);
};

export default GenericActionModal;
