import { Dictionary } from 'lodash';
import { DashboardData } from 'constants/types';
import { ComparePeriod, DatePeriod } from 'routes/dashboard/utils/dates';

export interface DashboardRequestParams {
	rangeFrom: Date;
	rangeTo: Date;
	compareFrom: Date;
	compareTo: Date;
}

export interface DashboardGroupData {
	total: number;
	diff: number;
	data: Dictionary<number>;
}

export enum DashboardGroup {
	USERS = 'users',
	SESSIONS = 'sessions',
	MEMBERSHIPS = 'memberships',
	STYLE_BOARDS = 'styleBoards',
	SERVICE_REVENUE = 'serviceRevenue',
	SALES_REVENUE = 'salesRevenue',
	GROSS_REVENUE = 'grossRevenue',
	EMAILS = 'emails'
}

export type DashboardResponse = { [key in DashboardGroup]: DashboardGroupData };

export interface DashboardState {
	metrics: DashboardData;
	range: DatePeriod;
	compare: ComparePeriod;
}
