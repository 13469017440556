import { combineReducers } from 'redux';
import { authReducer } from './auth';
import { dashboardReducer } from 'routes/dashboard/redux/reducer';
import { ordersReducer } from 'routes/orders/redux/reducer';
import { couponsReducer } from 'routes/coupons/redux/reducer';
import { networkReducer } from './network';
import { orderDetailsReducer } from 'routes/order-details/redux/reducer';
import { emptySplitApi } from 'redux/base-api';
import { partners } from 'redux/partners-slice';

const rootReducer = combineReducers({
	network: networkReducer,
	auth: authReducer,
	dashboard: dashboardReducer,
	orders: ordersReducer,
	orderDetails: orderDetailsReducer,
	coupons: couponsReducer,
	partners: partners.reducer,
	[emptySplitApi.reducerPath]: emptySplitApi.reducer
});

export default rootReducer;
