import { IFilterItem, IFilterState, TDateFilterProps, TNumberFilterProps } from '../constants/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { fastPruneEmpty } from '../utils/helpers';

const objToURLParams = (obj: any) => {
	const result: [string, string][] = [];
	Object.keys(obj).forEach((key) => {
		const value = obj[key];
		if (Array.isArray(value)) {
			value.forEach((v) => result.push([key, v]));
		} else {
			result.push([key, value]);
		}
	});

	return result;
};

export const useFiltersURLState = (filters: IFilterItem[]) => {
	const navigateTo = useNavigate();
	const { search } = useLocation();
	const urlParams = useMemo(() => new URLSearchParams(search), [search]);
	const filtersURLState = useMemo(() => {
		const state: IFilterState = {};

		filters.forEach((filter) => {
			const { id, controller } = filter;

			switch (controller) {
				case 'number':
					const amount = urlParams.getAll(`${id}.amount`);
					const comparator = urlParams.get(`${id}.comparator`);
					state[id] = { amount, comparator } as TNumberFilterProps;
					break;

				case 'date-range':
					const from = urlParams.get(`${id}.from`);
					const to = urlParams.get(`${id}.to`);
					state[id] = { from, to } as TDateFilterProps;
					break;

				default:
					state[id] = urlParams.get(id) as string;
			}
		});

		return fastPruneEmpty(state) || {};
	}, [filters, urlParams]);

	const activeFiltersCount = Object.keys(filtersURLState).length;

	const setFiltersURLState = (filtersState: IFilterState) => {
		const result: Record<string, string | string[] | undefined> = {};

		urlParams.forEach((value, key) => (result[key] = value));

		delete result.page; // We would like to delete page after every filter change to prevent empty ui states

		filters.forEach((filter) => {
			const { id, controller } = filter;
			const filterState = filtersState[id];

			switch (controller) {
				case 'number':
					const { amount, comparator } = (filterState as TNumberFilterProps) || {};
					result[`${id}.amount`] = amount;
					result[`${id}.comparator`] = comparator;
					break;
				case 'date-range':
					const { from, to } = (filterState as TDateFilterProps) || {};
					result[`${id}.from`] = from;
					result[`${id}.to`] = to;
					break;
				default:
					result[id] = filterState as string;
			}
		});

		const prunedParams = fastPruneEmpty(result) || {};

		navigateTo(`?${new URLSearchParams(objToURLParams(prunedParams)).toString()}`, { replace: true });
	};

	return { filtersURLState, activeFiltersCount, setFiltersURLState };
};
