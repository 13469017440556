import Table from 'components/table/table';
import React, { useEffect, useMemo, useState } from 'react';
import { PAGE_LIMIT, retailerItemsColumnsConfig } from 'routes/inventory/config';
import { PaginationParams, RetailerItemRecord } from 'routes/inventory/redux/types';
import { useURLState } from 'hooks';
import { useGetRetailerItemsQuery } from 'routes/inventory/redux/inventory-slice';
import TableNoResults from 'components/table/table-no-results';
import { PageNavigation } from '../retailers/page-navigation';
import TableHeader from './table-header';

const RetailerItems: React.FC<{ retailerId: string }> = ({ retailerId }) => {
	const [pageInfo, setPageInfo] = useState<PaginationParams>({});
	const { data, isLoading } = useGetRetailerItemsQuery({
		retailerId,
		params: pageInfo
	});
	const items = useMemo(() => data?.results ?? [], [data?.results]);
	const rowKey = 'id';
	const columns = retailerItemsColumnsConfig();
	const { urlState } = useURLState();

	useEffect(() => {
		if (!pageInfo.first && !pageInfo.before) setPageInfo({ ...pageInfo, first: PAGE_LIMIT });
	}, [pageInfo]);

	useEffect(() => {
		const params = urlState.search ? { search: urlState.search, first: PAGE_LIMIT } : {};
		setPageInfo(params);
	}, [urlState.search]);

	if (isLoading) return <div>Loading Retailer Items...</div>;
	if (!data) return <TableNoResults />;

	return (
		<>
			<TableHeader title="" total={data.pageInfo.total} />

			<Table<RetailerItemRecord>
				dataTestId="retailer-items-table"
				columns={columns}
				rowKey={rowKey}
				records={items}
				isClickable={true}
				isAllRowClickable={true}
			/>

			<PageNavigation pageInfo={data.pageInfo} setPageInfo={setPageInfo} />
		</>
	);
};

export default RetailerItems;
