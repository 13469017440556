import styled from '@emotion/styled';
import { ColumnType } from 'rc-table/es/interface';
import Checkbox from 'components/checkbox';
import { OrderItemRecord } from 'routes/order-details/redux/types';
import { formatNumber } from 'utils/helpers';
import { TABLE_DATA_LIMIT } from 'routes/inventory/types';

export function renderOptionalCell(cellValue: string) {
	return cellValue || <ItemNotFound />;
}

export function renderNumberCell(cellValue: number) {
	return formatNumber(cellValue) || <ItemNotFound />;
}

export function renderBooleanCell(cellValue: boolean) {
	return cellValue?.toString() || <ItemNotFound />;
}

export function renderCheckboxColumn<RecordType>(options: CheckboxColumn<RecordType>): ColumnType<RecordType> {
	return {
		title: (
			<CheckboxWrapper>
				<Checkbox isChecked={options.allRowsSelected} onChange={options.toggleAllRows} />
			</CheckboxWrapper>
		),
		dataIndex: '',
		key: 'checkbox',
		render: (_, row) => {
			return (
				<CheckboxWrapper>
					<Checkbox isChecked={options.isRowSelected(row)} onChange={() => options.toggleRow(row)} />
				</CheckboxWrapper>
			);
		},
		width: 36
	};
}

export function renderItemInfo(cellValue: OrderItemRecord['info']) {
	const { imgUrl, productUrl, retailer } = cellValue;

	return (
		<ImageContainer>
			<ItemImage src={imgUrl} alt="Item image" />
			<ImageItemLink href={productUrl} target="_blank">
				{retailer}
			</ImageItemLink>
		</ImageContainer>
	);
}

export interface CheckboxColumn<RecordType> {
	isRowSelected: (row: RecordType) => boolean;
	toggleRow: (row: RecordType) => void;
	toggleAllRows: () => void;
	allRowsSelected: boolean;
}

export function customPagination(page: number, array: Array<any>) {
	const from_index = page * TABLE_DATA_LIMIT - TABLE_DATA_LIMIT;
	const to_index = from_index + TABLE_DATA_LIMIT;
	return array.slice(from_index, to_index);
}

export const ItemNotFound = styled.span`
	display: flex;
	border: solid 1px #979797;
	width: 25px;
	height: 1px;
`;

const ImageContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 82px;
	margin-top: 20px;
`;

const ItemImage = styled.img`
	width: 82px;
	height: 94px;
	border-radius: 4px;
`;

const ImageItemLink = styled.a`
	margin: 9px 0;
	color: black;
	font-size: 12px;
	font-weight: 400;
	text-align: center;
`;

const CheckboxWrapper = styled.div`
	margin-left: 4px;
`;
