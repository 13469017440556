import styled from '@emotion/styled';
import { palette } from 'constants/styles';

interface IButton {
	size?: 'small' | 'normal';
	color?: 'primary' | 'danger';
	marginLeft?: string;
	marginRight?: string;
}

const Button = styled.button<IButton>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${(props) => (props.size === 'small' ? '28px' : '30px')};
	line-height: ${(props) => (props.size === 'small' ? '28px' : '30px')};
	border: solid 1px
		${(props) =>
			props.color === 'primary'
				? palette.primaryColor
				: props.color === 'danger'
				? palette.dangerColor
				: '#dbdbdb'};
	border-radius: 4px;
	background-color: ${(props) =>
		props.color === 'primary' ? palette.primaryColor : props.color === 'danger' ? palette.dangerColor : 'white'};
	font-size: 14px;
	font-weight: 300;
	color: ${(props) => (props.color === 'primary' || props.color === 'danger' ? 'white' : 'black')};
	padding: 0 0.5rem;

	&:hover {
		border: solid 1px ${palette.primaryColor};
		cursor: pointer;
	}

	&:disabled {
		opacity: 0.5;
		cursor: auto;
	}
`;

export default Button;
