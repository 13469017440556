import React, { useState } from 'react';
import styled from '@emotion/styled';

import Modal, { ModalActions, ModalBody } from 'components/modal';
import Button from 'components/button';
import InputController from 'components/form/input-controller';
import RadioGroupController from 'components/form/radio-group-controller';
import DateController from 'components/form/date-controller';
import { CreateCouponRequest } from 'routes/coupons/redux/types';
import { useCouponsSelector } from 'routes/coupons/redux/reducer';
import SelectController from 'components/form/select-controller';
import { useAsyncModalState } from 'utils/use-async-modal-state';

interface IAddCouponModal {
	action: 'add' | 'edit';
	onClose: () => void;
	onSubmit: (coupon: CreateCouponRequest) => void;
	coupon?: CreateCouponRequest;
	isInProgress: boolean;
	error: string | null;
}

const discountTypes = ['percentage', 'value'];
const couponTypes = ['Unlimited', 'Single Use', 'Cap'];

const AddEditCouponModal: React.FC<IAddCouponModal> = ({ action, onClose, coupon, onSubmit, isInProgress, error }) => {
	const { campaigns } = useCouponsSelector((state) => ({
		campaigns: state.campaigns
	}));

	const [form, setForm] = useState<CreateCouponRequest>({
		campaign: coupon?.campaign || '',
		code: coupon?.code || '',
		couponType: coupon?.couponType || 'Single Use',
		discountType: coupon?.discountType || 'percentage',
		discountValue: coupon?.discountValue || 1,
		expirationDate: coupon?.expirationDate || '',
		maxCap: coupon?.maxCap || 1
	});

	const { modalState, setModalState } = useAsyncModalState({
		isInProgress,
		error,
		onClose
	});

	const submitForm = () => {
		setModalState('pending');
		onSubmit(form);
	};

	const onChange = (property: string, value: string | number) => {
		setForm((currentFormState: CreateCouponRequest) => ({
			...currentFormState,
			[property]: value
		}));
	};

	const title = (action === 'add' ? 'Add new' : 'Edit') + ' coupon';
	const buttonText = (action === 'add' ? 'Add new' : 'Update') + ' coupon';

	return (
		<Modal title={title} onClose={onClose}>
			<ModalBody>
				<form
					onSubmit={(event) => {
						event.preventDefault();
						submitForm();
					}}
				>
					<Fieldset>
						<Label htmlFor="code">Promo code name</Label>
						<InputController
							type="text"
							name="code"
							id="code"
							value={form.code}
							onChange={(event) => onChange(event.target.name, event.target.value)}
							disabled={action === 'edit'}
						/>
					</Fieldset>
					<Fieldset>
						<Label htmlFor="discountType">Discount Type</Label>
						<RadioGroupController
							name="discountType"
							value={form.discountType}
							onChange={(value) => onChange('discountType', value)}
							items={discountTypes}
						/>
					</Fieldset>
					<Fieldset>
						<Label htmlFor="discountValue">Discount value</Label>
						<InputController
							type="number"
							name="discountValue"
							id="discountValue"
							value={form.discountValue}
							onChange={(event) => onChange(event.target.name, event.target.valueAsNumber)}
						/>
					</Fieldset>
					<Fieldset>
						<Label htmlFor="couponType">Coupon type</Label>
						<RadioGroupController
							name="couponType"
							value={form.couponType}
							onChange={(value) => onChange('couponType', value)}
							items={couponTypes}
						/>
					</Fieldset>
					{form.couponType === 'Cap' && (
						<Fieldset>
							<Label htmlFor="maxCap">Max cap</Label>
							<InputController
								type="number"
								name="maxCap"
								id="maxCap"
								value={form.maxCap}
								onChange={(event) => onChange(event.target.name, event.target.valueAsNumber)}
							/>
						</Fieldset>
					)}
					<Fieldset>
						<Label htmlFor="campaign">Campaign</Label>
						<SelectController
							options={[''].concat(campaigns)}
							name="campaign"
							id="campaign"
							value={form.campaign}
							onChange={(event) => onChange(event.target.name, event.target.value)}
						/>
					</Fieldset>
					<Fieldset>
						<Label htmlFor="expirationDate">Expiration date</Label>
						<DateController
							name="expirationDate"
							id="expirationDate"
							value={form.expirationDate}
							onChange={(event) => onChange(event.target.name, event.target.value)}
						/>
					</Fieldset>
					{error && (
						<Fieldset>
							<ErrorMessage>{error}</ErrorMessage>
						</Fieldset>
					)}
					<ModalActions>
						<Button color="primary" disabled={modalState === 'pending'}>
							{buttonText}
						</Button>
					</ModalActions>
				</form>
			</ModalBody>
		</Modal>
	);
};

const Fieldset = styled.fieldset`
	border: 0;
	margin-bottom: 1rem;
`;

const Label = styled.label`
	display: block;
	text-transform: uppercase;
	font-size: 0.9rem;
	margin-bottom: 0.5rem;
`;

const ErrorMessage = styled.label`
	color: red;
	width: 400px;
	display: block;
	font-size: 0.9rem;
	margin-bottom: 0.5rem;
`;

export default AddEditCouponModal;
