import {
	CouponCampaignsResponse,
	CouponsRequestParams,
	CouponsResponse,
	CreateCouponRequest,
	UpdateCouponRequest
} from 'routes/coupons/redux/types';
import { APIAction } from 'redux/actions/network';

export const SET_COUPONS = '[coupons] SET_COUPONS';
export const FETCH_COUPONS = '[coupons] FETCH_COUPONS';
export const FETCH_COUPON_CAMPAIGNS = '[coupons] FETCH_COUPON_CAMPAIGNS';
export const SET_COUPON_CAMPAIGNS = '[coupons] SET_COUPON_CAMPAIGNS';
export const DEACTIVATE_COUPONS = '[coupons] DEACTIVATE_COUPONS';
export const ADD_COUPON = '[coupons] ADD_COUPON';
export const UPDATE_COUPON = '[coupons] UPDATE_COUPON';
export const SET_MODAL_ERROR = '[coupon-details] SET_MODAL_ERROR';

export const setCoupons = (response: CouponsResponse) => ({
	type: SET_COUPONS,
	payload: { coupons: response.items, paginationMeta: response.meta }
});

export const setCouponCampaigns = (response: CouponCampaignsResponse) => ({
	type: SET_COUPON_CAMPAIGNS,
	payload: { campaigns: response }
});

export const setModalError = (error: any) => {
	const { message } = error.response.data;
	const errorMessage = Array.isArray(message) ? message[0] : message;

	return {
		type: SET_MODAL_ERROR,
		payload: { error: errorMessage }
	};
};

export const clearModalError = () => ({
	type: SET_MODAL_ERROR,
	payload: { error: null }
});

export const fetchCoupons = (params?: CouponsRequestParams): APIAction<CouponsResponse> => ({
	type: FETCH_COUPONS,
	meta: { api: true },
	payload: {
		method: 'GET',
		path: '/coupon/paginate',
		networkLabel: 'coupons',
		params,
		onSuccess: setCoupons
	}
});

export const fetchCouponCampaigns = (): APIAction<CouponCampaignsResponse> => ({
	type: FETCH_COUPON_CAMPAIGNS,
	meta: { api: true },
	payload: {
		method: 'GET',
		path: '/coupon/campaigns',
		networkLabel: 'couponCampaigns',
		onSuccess: setCouponCampaigns
	}
});

export const deactivateCoupons = (
	couponCodes: Array<string>,
	urlState: CouponsRequestParams
): APIAction<Array<string>> => ({
	type: DEACTIVATE_COUPONS,
	meta: { api: true },
	payload: {
		method: 'DELETE',
		path: '/coupon',
		networkLabel: 'deactivateCoupons',
		params: {
			codes: couponCodes
		},
		onSuccess: [() => fetchCoupons(urlState)]
	}
});

export const addCoupon = (coupon: CreateCouponRequest, urlState: CouponsRequestParams): APIAction<never> => ({
	type: ADD_COUPON,
	meta: { api: true },
	payload: {
		method: 'POST',
		path: '/coupon',
		networkLabel: 'addCoupon',
		params: coupon,
		onSuccess: [() => fetchCoupons(urlState)],
		onError: setModalError
	}
});

export const updateCoupon = (couponCode: string, coupon: UpdateCouponRequest): APIAction<never> => ({
	type: UPDATE_COUPON,
	meta: { api: true },
	payload: {
		method: 'PATCH',
		path: `/coupon/${couponCode}`,
		networkLabel: 'updateCoupon',
		params: coupon,
		onSuccess: []
	}
});
