import React, { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TableActions from 'components/table/table-actions';
import { SessionRecord } from 'routes/sessions/redux/types';
import TypeFilters from 'components/table/type-filters';
import TableNoResults from 'components/table/table-no-results';
import Table from 'components/table/table';
import { B2BPartnerMenuFilters, B2CPartnerMenuFilters, quickFilters } from 'routes/sessions/filters';
import { customStyle, getColumnsConfig } from 'routes/sessions/config';
import { useRowSelectionState } from 'utils/use-row-selection-state';
import { useURLState } from 'hooks';
import { useNetworkSelector } from 'redux/reducers/network';
import TableResults from 'components/table-results';
import { exportSessions } from 'routes/sessions/redux/actions';
import { useDispatch } from 'react-redux';
import ExportModal from 'components/export/export-modal';
import { TableContainer } from '../components';
import { useGetStylistNamesQuery } from 'apiSlices/stylist-slice';
import { useGetMetadataQuery } from 'apiSlices';
import { useGetSessionsQuery } from '../redux';
import { useSelectedPartnerId } from 'redux/partners-slice';
import { isB2CPartnerId } from 'utils/helpers';

export const SessionsPage: FC = () => {
	const [exportModalOpen, setExportModalOpen] = useState(false);
	const dispatch = useDispatch();
	const { urlState } = useURLState();
	const selectedPartnerId = useSelectedPartnerId();
	const isB2Cpartner = isB2CPartnerId(selectedPartnerId);
	const navigateTo = useNavigate();
	const rowKey = 'bookingId';
	const { data: metadata } = useGetMetadataQuery({});
	const { data: stylists } = useGetStylistNamesQuery({ partnerId: selectedPartnerId }, { skip: !selectedPartnerId });
	const { data: sessionsData, isFetching: isFetchingSessions } = useGetSessionsQuery(urlState, {
		skip: !urlState.partnerId
	});

	const { isExportingSessions } = useNetworkSelector((state) => ({
		isExportingSessions: state.exportSessions > 0
	}));

	const stylistNamesList = useMemo(
		() => stylists?.map(({ name, uuid }) => ({ label: name, value: uuid })) ?? [],
		[stylists]
	);

	const filters = isB2Cpartner ? B2CPartnerMenuFilters : B2BPartnerMenuFilters;

	const { selectedRowsIds, isRowSelected, toggleRow, deselectAllRows, toggleAllRows } = useRowSelectionState({
		rowKey,
		records: sessionsData?.items || []
	});

	const columns = getColumnsConfig(selectedPartnerId, {
		allRowsSelected: selectedRowsIds.length === sessionsData?.items.length,
		isRowSelected,
		toggleAllRows,
		toggleRow
	});

	const onRowClick = (rowRecord: SessionRecord) => {
		navigateTo(`/sessions/${rowRecord.bookingId}`);
	};

	return (
		<>
			<TableContainer>
				<TableActions
					tableTitle=""
					selectedRows={selectedRowsIds}
					onDeselectAll={deselectAllRows}
					onExport={() => setExportModalOpen(true)}
					quickFilters={quickFilters}
					filters={filters}
					customStyle={customStyle}
					autoCompleteOptions={{ stylistUuid: stylistNamesList }}
				/>
				{isB2Cpartner && <TypeFilters filters={quickFilters} />}
				{isFetchingSessions ? (
					<div>Loading sessions...</div>
				) : sessionsData?.items.length ? (
					<>
						<TableResults paginationData={sessionsData?.meta} />
						<Table<SessionRecord>
							columns={columns}
							records={sessionsData?.items}
							onRowClick={onRowClick}
							rowKey={rowKey}
							selectedRowsIds={selectedRowsIds}
							isClickable={true}
							dataTestId="sessions-table"
						/>
					</>
				) : (
					<TableNoResults />
				)}
			</TableContainer>

			{exportModalOpen && (
				<ExportModal
					exportType="sessions"
					exportFields={metadata?.sessions.exportFields || []}
					onClose={() => setExportModalOpen(false)}
					isInProgress={isExportingSessions}
					onSubmit={(form) => {
						dispatch(exportSessions({ ...form, partnerId: selectedPartnerId }));
					}}
				/>
			)}
		</>
	);
};
