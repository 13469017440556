import { useEffect } from 'react';
import styled from '@emotion/styled';
import PageLayout from 'components/page-layout';
import DashboardCard from 'routes/dashboard/components/dashboard-card';
import { useDispatch } from 'react-redux';
import { fetchMetrics } from 'routes/dashboard/redux/actions';
import { useURLState } from 'hooks';
import { useDashboardSelector } from 'routes/dashboard/redux/reducer';
import { Card } from 'constants/types';
import DatePeriodPicker from 'components/date-period-picker';

const DashboardPage = () => {
	const { metrics, range, compare } = useDashboardSelector((state) => ({
		metrics: state.metrics,
		range: state.range,
		compare: state.compare
	}));

	const dispatch = useDispatch();
	const { urlState } = useURLState();

	useEffect(() => {
		const { rangeFrom, rangeTo, compareFrom, compareTo } = urlState;
		if (rangeFrom && rangeTo && compareFrom && compareTo) {
			dispatch(fetchMetrics(urlState));
		}
	}, [urlState, dispatch]);

	return (
		<PageLayout>
			<PageActions>
				<DatePeriodPicker range={range} compare={compare} />
			</PageActions>

			<CardsContainer>
				{metrics.map((card: Card, index) => (
					<DashboardCard key={index} {...card} />
				))}
			</CardsContainer>
		</PageLayout>
	);
};

const CardsContainer = styled.div`
	display: grid;
	gap: 2rem;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;

const PageActions = styled.div`
	margin-bottom: 30px;
`;

export default DashboardPage;
