import React, { useEffect, useState } from 'react';
import Modal, { ModalActions, ModalBody } from 'components/modal';
import Button from 'components/button';
import Toggle from 'components/toggle';
import { Fieldset, Message, SubTitle, Label, ToggleContainer } from './modal.style';
import { Select } from 'components/form/select';
import { GeneralCategory, ItemCategory } from 'routes/inventory/redux/types';
import { itemCategoryAction } from 'routes/inventory/config';
import { useUpdateItemCategoryMutation } from 'routes/inventory/redux/inventory-slice';

interface IUpdateCategoryModal {
	retailerId: string;
	category: ItemCategory;
	onClose: () => void;
}

const UpdateItemCategoryModal: React.FC<IUpdateCategoryModal> = ({ retailerId, category, onClose }) => {
	const [selectedCategory, setSelectedCategory] = useState<ItemCategory>(category);
	const [updateItemCategory, { isSuccess, isError }] = useUpdateItemCategoryMutation();

	const updateCategory = () => {
		const category = {
			active: selectedCategory.active,
			general: selectedCategory.general
		};
		const params = {
			category,
			retailerId,
			categoryId: selectedCategory.itemCategoryID
		};
		updateItemCategory({ params });
	};

	useEffect(() => {
		if (isSuccess) onClose();
	}, [isSuccess, onClose]);

	const onCategoryChange = (value: GeneralCategory) => setSelectedCategory({ ...selectedCategory, general: value });

	const onStatusChange = (value: boolean) => setSelectedCategory({ ...selectedCategory, active: value });

	const getMessage = () => itemCategoryAction.message.replace('<category>', category.general);

	return (
		<Modal title={itemCategoryAction.title} onClose={onClose}>
			<ModalBody>
				<div data-testid="modal-container">
					<Fieldset>
						<ToggleContainer>
							<Label htmlFor="active">Active : </Label>
							<Toggle
								id="category-toggle"
								theme="primary"
								isChecked={selectedCategory.active}
								onChange={(event) => onStatusChange(event)}
							/>
						</ToggleContainer>
					</Fieldset>

					<Fieldset>
						<Message isError={false}>{getMessage()}</Message>
						{isError && <Message isError={isError}>{itemCategoryAction.error}</Message>}
					</Fieldset>

					<Fieldset>
						<SubTitle>{itemCategoryAction.subTitle}</SubTitle>
					</Fieldset>

					<Fieldset>
						<Select
							width="160px"
							value={selectedCategory.general}
							onChange={(event) => onCategoryChange(event.target.value as GeneralCategory)}
						>
							{Object.values(GeneralCategory).map((category, index) => (
								<option value={category} key={index}>
									{category}
								</option>
							))}
						</Select>
					</Fieldset>

					<ModalActions>
						<Button onClick={updateCategory} color="primary" data-testid="modal-submit-button">
							{itemCategoryAction.buttonText}
						</Button>
					</ModalActions>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default UpdateItemCategoryModal;
