import { ActionCreator, Dispatch, MiddlewareAPI } from 'redux';
import { castArray, pickBy } from 'lodash';
import axios from 'axios';
import { endNetworkRequest, startNetworkRequest } from 'redux/actions/network';
import { ReduxAction, State } from 'redux/types';
import { signOut } from 'redux/actions/auth';
import { storageParams } from 'routes/login/consts';

function dispatchActions(
	dispatch: Dispatch<ReduxAction>,
	actionCreators: ActionCreator<ReduxAction> | Array<ActionCreator<ReduxAction>>,
	response?: any
) {
	castArray(actionCreators).forEach((actionCreator: ActionCreator<ReduxAction>) => {
		const action = actionCreator(response);

		return action && dispatch(action);
	});
}

export const apiMiddleware =
	({ dispatch, getState }: MiddlewareAPI<Dispatch, State>) =>
	(next: Dispatch) =>
	(action: ReduxAction) => {
		if (!action?.meta?.api) {
			return next(action);
		}

		const { path, baseUrl, onFetchStart, onSuccess, onError, networkLabel, params, method } = action.payload;

		next(action);
		dispatch(startNetworkRequest(networkLabel));
		if (onFetchStart) dispatchActions(dispatch, onFetchStart);

		axios
			.request({
				url: (process.env.REACT_APP_API_GW_URL || baseUrl) + path,
				method,
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${getState().auth.apiToken}`
				},
				params: ['GET', 'HEAD', 'OPTIONS', 'DELETE'].includes(method) ? pickBy(params, Boolean) : undefined,
				data: ['POST', 'PUT', 'PATCH'].includes(method) ? pickBy(params, Boolean) : undefined
			})
			.then((response) => {
				dispatch(endNetworkRequest(networkLabel));

				if (onSuccess) {
					dispatchActions(dispatch, onSuccess, response.data);
				}
			})
			.catch((error) => {
				dispatch(endNetworkRequest(networkLabel));

				if (error?.response?.status === 401) {
					localStorage.removeItem(storageParams.accessToken);
					dispatch(signOut());
				}

				if (onError) {
					dispatchActions(dispatch, onError, error);
				}
			});
	};
