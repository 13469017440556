import React, { InputHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { Input } from 'components/form/input';

interface IDateController extends InputHTMLAttributes<HTMLInputElement> {}

const DateController: React.FC<IDateController> = ({ name, value, onChange }) => (
	<Container>
		<InputContainer>
			<Input type="date" name={name} value={value} onChange={onChange} />
		</InputContainer>
	</Container>
);

const Container = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
`;

const InputContainer = styled.div`
	flex: 1;
`;

export default DateController;
