import { DateRange, MetadataResponse, PaginationMetaMixin } from 'redux/types';
import { UpdateOrderClientDetails } from 'routes/order-details/redux/types';

export interface OrdersState {
	orders: Array<OrderRecord>;
	paginationMeta: Partial<PaginationMetaMixin>;
	metadata: MetadataResponse['orders'] | null;
}

export interface OrderRecord {
	created: string;
	clientName: string;
	uuid: string;
	stripeCharge: string;
	items: number;
	status: OrderStatus;
	total: number;
}

export interface OrdersResponse {
	meta: PaginationMetaMixin;
	items: Array<OrderRecord>;
}

export type OrderType = 'all' | 'open' | 'ordered' | 'returned' | 'return in progress' | 'canceled';

export enum OrderStatus {
	open = 'open',
	received = 'received',
	ordered = 'ordered',
	shipped = 'shipped',
	returned = 'returned',
	completed = 'completed',
	returnInProgress = 'return in progress',
	canceled = 'canceled',
	arrived = 'arrived'
}

export interface OrdersRequestParams {
	'created.from'?: string;
	'created.to'?: string;
	'total.amount'?: Array<number>;
	'total.comparator'?: 'GT' | 'LT' | 'EQ' | 'BTW';
	search?: string;
	type?: OrderType;
	status?: OrderStatus;
	limit?: number;
	page?: number;
}

export interface ExportOrderRequest {
	'customRange.from': string;
	'customRange.to': string;
	dateRange: DateRange;
	columns:
		| 'created'
		| 'orderId'
		| 'clientName'
		| 'clientId'
		| 'email'
		| 'gender'
		| 'age'
		| 'shippingAddress'
		| 'stripeChargeId'
		| 'status'
		| 'itemsNum'
		| 'brand'
		| 'retailer'
		| 'orderCategory'
		| 'wishiTotal'
		| 'retailerTotal'
		| 'refundOrExtraCharge'
		| 'itemUrl'
		| 'size';
}

export interface UpdateOrderDetails {
	status?: OrderStatus;
}

export interface UpdateOrder {
	client?: UpdateOrderClientDetails;
	order?: UpdateOrderDetails;
}
