import { Outlet } from 'react-router-dom';
import PageLayout from 'components/page-layout';

export const Users = () => {
	return (
		<PageLayout>
			<Outlet />
		</PageLayout>
	);
};
