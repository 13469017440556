import { renderItemInfo, renderOptionalCell } from 'components/table/table-utils';
import { ColumnType } from 'rc-table/es/interface';
import { OrderItemRecord, OrderItemStatus } from 'routes/order-details/redux/types';
import { OrderItemOrderNumberCell } from 'routes/order-details/components/order-item-order-number-cell';
import { OrderItemTrackingNumberCell } from 'routes/order-details/components/order-item-tracking-number-cell';
import { OrderStatusCell } from 'routes/order-details/components/order-status-cell';
import { SaveOrderItemCell } from 'routes/order-details/components/save-order-item-cell';
import React from 'react';
import { OrderPriceCell } from 'routes/order-details/components/order-price-cell';

export const getColumnsConfig: Array<ColumnType<OrderItemRecord>> = [
	{
		title: 'Item',
		dataIndex: 'info',
		key: 'info',
		render: renderItemInfo
	},
	{
		title: 'Size & Color',
		dataIndex: 'sizeAndColor',
		key: 'size&sizeAndColor',
		render: renderOptionalCell
	},
	{
		title: 'Amount',
		dataIndex: 'amount',
		key: 'amount',
		render: (_, record: OrderItemRecord) => <OrderPriceCell record={record.amount} itemId={record.uuid} />
	},
	{
		title: 'Order#',
		dataIndex: 'orderNumber',
		key: 'orderNumber',
		render: (orderNumber: string, record: OrderItemRecord) => (
			<OrderItemOrderNumberCell orderNumber={orderNumber} itemId={record.uuid} />
		)
	},
	{
		title: 'Tracking#',
		dataIndex: 'trackingNumber',
		key: 'trackingNumber',
		render: (trackingNumber: string, record: OrderItemRecord) => (
			<OrderItemTrackingNumberCell trackingNumber={trackingNumber} itemId={record.uuid} />
		)
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
		render: (status: OrderItemStatus, record: OrderItemRecord) => (
			<OrderStatusCell status={status} itemId={record.uuid} />
		)
	},
	{
		title: '',
		dataIndex: 'uuid',
		key: 'uuid',
		render: (uuid: string, record: OrderItemRecord) => <SaveOrderItemCell itemId={uuid} record={record} />
	}
];
