import React from 'react';
import Select, { components, Props, StylesConfig } from 'react-select';
import { AiOutlineCalendar, AiOutlineCheck } from 'react-icons/ai';

export interface IOption {
	value: string;
	label: string;
	controlLabel: string;
}

interface IAdvancedSelectController extends Props<IOption, false> {
	options: IOption[];
}

const PeriodSelectController: React.FC<IAdvancedSelectController> = ({ name, value, options, onChange }) => {
	const DropdownIndicator = (props: any) => (
		<components.DropdownIndicator {...props}>
			<AiOutlineCalendar />
		</components.DropdownIndicator>
	);

	const SingleValue = (props: any) => (
		<components.SingleValue {...props}>{props.data.controlLabel}</components.SingleValue>
	);

	const Option = (props: any) => (
		<components.Option {...props}>
			{props.isSelected ? <AiOutlineCheck /> : <div />}
			{props.data.label}
		</components.Option>
	);

	return (
		<Select
			isSearchable={false}
			name={name}
			id={name}
			value={value}
			onChange={onChange}
			options={options}
			styles={styleConfig}
			components={{ DropdownIndicator, SingleValue, Option }}
		/>
	);
};

const styleConfig: StylesConfig<IOption, false> = {
	container: (provided) => ({
		...provided,
		fontSize: '14px',
		fontWeight: 300
	}),
	control: (provided) => ({
		...provided,
		flexDirection: 'row-reverse',
		alignContent: 'center',
		borderRadius: '0.33rem',
		border: 'solid 1px #dbdbdb',
		boxShadow: 'inset 2px 2px 2px rgba(0, 0, 0, 0.1)',
		height: '30px',
		minHeight: '30px',
		'&:hover': {
			borderColor: 'hsl(0, 0%, 60%)'
		}
	}),
	valueContainer: (provided) => ({
		...provided,
		paddingLeft: 0
	}),
	singleValue: (provided) => ({
		...provided,
		marginTop: '3px'
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		color: 'black',
		fontSize: '16px',
		'&:hover': {
			color: 'inherit'
		}
	}),
	indicatorSeparator: () => ({
		display: 'none'
	}),
	option: (base, state) => ({
		...base,
		display: 'grid',
		gridTemplateColumns: '1fr 9fr',
		columnGap: '5px',
		paddingLeft: '5px',
		color: `${state.isSelected ? 'coral' : 'black'}`,
		whiteSpace: 'nowrap',
		backgroundColor: 'transparent',
		'&:hover': {
			backgroundColor: 'aliceblue'
		}
	})
};

export default PeriodSelectController;
