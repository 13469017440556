import React from 'react';

import Button from 'components/button';
import Modal, { ModalActions, ModalBody, ModalText } from 'components/modal';
import ErrorBar from 'components/error-bar';
import { TUserId, useDeleteUsersMutation } from '../redux';
import { useSelectedPartnerId } from 'redux/partners-slice';
import { B2CPartnerId } from 'constants/global-consts';

interface IDeleteUsersModal {
	userIdsToDelete: TUserId[];
	onClose: () => void;
}

export const DeleteUsersModal: React.FC<IDeleteUsersModal> = ({ userIdsToDelete, onClose }) => {
	const [deleteUsers, { isLoading, error }] = useDeleteUsersMutation();
	const isSingular = userIdsToDelete.length === 1;
	const partnerId = useSelectedPartnerId() ?? B2CPartnerId;
	const onDeleteClick = async () => {
		const result = await deleteUsers({ uuids: userIdsToDelete, partnerId });

		if (!('error' in result)) {
			onClose();
		}
	};

	return (
		<Modal title={isSingular ? 'Delete user' : 'Delete users'} onClose={onClose}>
			{error && <ErrorBar>{error}</ErrorBar>}

			<ModalBody>
				<ModalText>
					This will permanently remove the {isSingular ? "customer's" : "customers'"} information and
					immediately cancel any current subscriptions. Past payments or invoices associated with the customer
					will still remain. This action cannot be undone.
				</ModalText>
				<ModalActions>
					<Button color="danger" onClick={onDeleteClick} disabled={isLoading}>
						Delete users
					</Button>
				</ModalActions>
			</ModalBody>
		</Modal>
	);
};
