import { SidebarItemContainer, SidebarContainer, SidebarItem, Title } from './sessions.style';

interface Item {
	link: string;
	name: string;
	dataTestId: string;
}
interface ISidebar {
	title: string;
	items: Item[];
}

export const Sidebar: React.FC<ISidebar> = ({ title, items }) => {
	return (
		<SidebarContainer>
			{title && <Title>{title}</Title>}
			<SidebarItemContainer>
				{items.map((item, index) => (
					<SidebarItem to={item.link} key={index}>
						{item.name}
					</SidebarItem>
				))}
			</SidebarItemContainer>
		</SidebarContainer>
	);
};
