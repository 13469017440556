import { APIAction } from 'redux/actions/network';
import { ExportSessionRequest } from 'routes/sessions/redux/types';

export const EXPORT_SESSIONS = '[sessions] EXPORT_SESSIONS';

export const exportSessions = (params: ExportSessionRequest): APIAction<never> => ({
	type: EXPORT_SESSIONS,
	meta: { api: true },
	payload: {
		method: 'POST',
		path: `/export/session`,
		params,
		networkLabel: 'exportSessions'
	}
});
