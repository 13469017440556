import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as SettingsIcon } from 'assets/images/settings.svg';
import { palette } from 'constants/styles';
import Navigation from 'components/navigation';
import UserPanel from 'components/user-panel';
import { PartnerPicker } from './partner-picker';

const TopBar = () => {
	return (
		<Container>
			<Header>
				<PartnerPicker />
				<FlexContainer>
					<Settings />
					<UserPanel />
				</FlexContainer>
			</Header>

			<Navigation />
		</Container>
	);
};

const Container = styled.div`
	position: sticky;
	top: 0;
	z-index: 1;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 50px;
	background-color: ${palette.primaryGray};
	top: 0;
	padding: 0 40px;
`;

const FlexContainer = styled.div`
	display: flex;
	align-items: center;
`;

const Settings = styled(SettingsIcon)`
	cursor: pointer;
`;

export default TopBar;
