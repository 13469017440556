import {
	ItemCategory,
	FileCategory,
	PaginatedResults,
	PaginationParams,
	ItemCategoryParams,
	RetailerRecord,
	RetailerItemRecord
} from './types';
import { emptySplitApi } from 'redux/base-api';

const apiWithTag = emptySplitApi.enhanceEndpoints({
	addTagTypes: ['Retailers', 'Retailer', 'FileCategory', 'ShopRank', 'ItemCategories', 'RetailerItems']
});

const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getRetailers: build.query<PaginatedResults<RetailerRecord>, { params?: PaginationParams }>({
			query: ({ params }) => ({
				url: '/inventory/retailers',
				method: 'GET',
				params
			}),
			providesTags: () => [{ type: 'Retailers', id: 'LIST' }]
		}),
		getRetailerById: build.query<RetailerRecord, string>({
			query: (id) => `/inventory/retailers/${id}`,
			providesTags: (result, error, id) => [{ type: 'Retailer', id }]
		}),
		getGlobalCategoriesById: build.query<FileCategory[], string>({
			query: (id) => `/inventory/retailers/${id}/file-categories`,
			transformResponse: (response: { fileCategories: FileCategory[] }) => response.fileCategories,
			providesTags: (result, error, id) => [{ type: 'FileCategory', id }]
		}),
		updateGlobalCategories: build.mutation<any, { id: string; params: FileCategory }>({
			query: ({ id, params }) => ({
				url: `/inventory/retailers/${id}/file-categories`,
				method: 'PATCH',
				body: params
			}),
			invalidatesTags: (result, error, { id }) => [{ type: 'FileCategory', id }]
		}),
		updateShopPriority: build.mutation<any, { id: string; priority: number }>({
			query: ({ id, priority }) => ({
				url: `/inventory/retailers/${id}/priority`,
				method: 'PATCH',
				body: { priority }
			}),
			invalidatesTags: (result, error, { id }) => [{ type: 'Retailer', id }]
		}),
		updateRetalierStatus: build.mutation<any, { id: string; active: boolean }>({
			query: ({ id, active }) => ({
				url: `/inventory/retailers/${id}/active`,
				method: 'PATCH',
				body: { active }
			}),
			invalidatesTags: (result, error, { id }) => [{ type: 'Retailer', id }]
		}),
		updateRetalierShop: build.mutation<any, { id: string }>({
			query: ({ id }) => ({
				url: `/inventory/retailers/${id}/sync`,
				method: 'POST'
			}),
			invalidatesTags: (result, error, { id }) => [{ type: 'Retailer', id }]
		}),
		getItemCategories: build.query<ItemCategory[], string>({
			query: (id) => `/inventory/retailers/${id}/item-categories`,
			transformResponse: (response: { itemCategories: ItemCategory[] }) => response.itemCategories,
			providesTags: (result, error, id) => [{ type: 'ItemCategories', id }]
		}),
		updateItemCategory: build.mutation<any, { params: ItemCategoryParams }>({
			query: ({ params }) => ({
				url: `/inventory/retailers/${params.retailerId}/item-categories/${params.categoryId}`,
				method: 'PATCH',
				body: params.category
			}),
			invalidatesTags: (result, error, { params }) => [{ type: 'ItemCategories', id: params.retailerId }]
		}),
		getRetailerItems: build.query<
			PaginatedResults<RetailerItemRecord>,
			{ params?: PaginationParams; retailerId: string }
		>({
			query: ({ retailerId, params }) => ({
				url: `/inventory/retailers/${retailerId}/items`,
				method: 'GET',
				params
			}),
			providesTags: (result, error, { retailerId }) => [{ type: 'RetailerItems', id: retailerId }]
		})
	})
});

export const {
	useGetRetailersQuery,
	useGetRetailerByIdQuery,
	useGetGlobalCategoriesByIdQuery,
	useUpdateGlobalCategoriesMutation,
	useUpdateShopPriorityMutation,
	useUpdateRetalierShopMutation,
	useUpdateRetalierStatusMutation,
	useGetItemCategoriesQuery,
	useUpdateItemCategoryMutation,
	useGetRetailerItemsQuery
} = extendedApi;
