import { CustomLabelType } from '../redux/types';
import { BaseLabel, DangerLabel } from './sessions.style';

interface ICustomLabel {
	type: CustomLabelType;
	text: string;
}

const LABEL_TYPES: Record<string, any> = {
	[CustomLabelType.base]: BaseLabel,
	[CustomLabelType.danger]: DangerLabel
};

const CustomLabel: React.FC<ICustomLabel> = ({ type = 'base', text }) => {
	const Label = LABEL_TYPES[type];
	return <Label data-testid="dueDate-label">{text}</Label>;
};

export default CustomLabel;
