export enum DatePeriod {
	TODAY = 'Today',
	LAST_7_DAYS = 'Last 7 days',
	LAST_4_WEEKS = 'Last 4 weeks',
	LAST_3_MONTHS = 'Last 3 months',
	LAST_6_MONTHS = 'Last 6 months',
	LAST_12_MONTHS = 'Last 12 months',
	CURRENT_MONTH = 'Current month',
	CURRENT_QUARTER = 'Current quarter',
	CURRENT_YEAR = 'Current year'
}

export enum ComparePeriod {
	PREVIOUS_PERIOD = 'Previous period',
	PREVIOUS_MONTH = 'Previous month',
	PREVIOUS_QUARTER = 'Previous quarter',
	PREVIOUS_YEAR = 'Previous year',
	NONE = 'No comparison'
}

export const DatePeriodReverseMap = new Map<string, DatePeriod>(
	Object.values(DatePeriod).map((memberValue) => [`${memberValue}`, memberValue] as const)
);
export const ComparePeriodReverseMap = new Map<string, ComparePeriod>(
	Object.values(ComparePeriod).map((memberValue) => [`${memberValue}`, memberValue] as const)
);

export const getPeriodDate = (period: DatePeriod) => {
	switch (period) {
		case DatePeriod.TODAY:
			return Date.today();
		case DatePeriod.LAST_7_DAYS:
			return Date.last().week();
		case DatePeriod.LAST_4_WEEKS:
			return (4).weeks().ago();
		case DatePeriod.LAST_3_MONTHS:
			return (3).months().ago();
		case DatePeriod.LAST_6_MONTHS:
			return (6).months().ago();
		case DatePeriod.LAST_12_MONTHS:
			return Date.last().year();
		case DatePeriod.CURRENT_MONTH:
			return Date.today().set({ day: 1 });
		case DatePeriod.CURRENT_QUARTER: {
			const monthNum = Date.today().getMonth();
			return Date.today().set({
				day: 1,
				month: Math.floor(monthNum / 3) * 3
			});
		}
		case DatePeriod.CURRENT_YEAR: {
			return Date.today().set({
				day: 1,
				month: 0
			});
		}
	}
};

export const getComparedPeriod = (
	period: DatePeriod,
	compared: ComparePeriod
): [compareFrom: Date, compareTo: Date] => {
	switch (compared) {
		case ComparePeriod.NONE:
			return [getPeriodDate(period), Date.today()];
		case ComparePeriod.PREVIOUS_PERIOD:
			switch (period) {
				case DatePeriod.TODAY:
					return [Date.previous().day(), Date.previous().day()];
				case DatePeriod.LAST_7_DAYS:
					return [(2).weeks().ago(), (1).week().ago().addDays(-1)];
				case DatePeriod.LAST_4_WEEKS:
					return [(8).weeks().ago(), (4).weeks().ago().addDays(-1)];
				case DatePeriod.LAST_3_MONTHS:
					return [(6).months().ago(), (3).months().ago().addDays(-1)];
				case DatePeriod.LAST_6_MONTHS:
					return [(12).months().ago(), (6).months().ago().addDays(-1)];
				case DatePeriod.LAST_12_MONTHS:
					return [(24).months().ago(), (12).months().ago().addDays(-1)];
				case DatePeriod.CURRENT_MONTH:
					return [getPeriodDate(period).addMonths(-1), getPeriodDate(period).addDays(-1)];
				case DatePeriod.CURRENT_QUARTER:
					return [getPeriodDate(period).addMonths(-3), getPeriodDate(period).addDays(-1)];
				case DatePeriod.CURRENT_YEAR:
					return [getPeriodDate(period).addYears(-1), getPeriodDate(period).addDays(-1)];
			}
			break;
		case ComparePeriod.PREVIOUS_MONTH:
			return [Date.today().set({ day: 1 }).addMonths(-1), Date.today().set({ day: 1 }).addDays(-1)];
		case ComparePeriod.PREVIOUS_QUARTER:
			const monthNum = Date.today().getMonth();
			const firstOfThisQuarter = Date.today().set({
				day: 1,
				month: Math.floor(monthNum / 3) * 3
			});
			return [new Date(firstOfThisQuarter).addMonths(-3), firstOfThisQuarter.addDays(-1)];
		case ComparePeriod.PREVIOUS_YEAR:
			return [
				Date.today().set({ month: 0, day: 1 }).addYears(-1),
				Date.today().set({ month: 0, day: 1 }).addDays(-1)
			];
	}
};

const shortDateFormat = 'MMM d, yyyy';

export const getShortDatePeriod = (period: DatePeriod) =>
	`${getPeriodDate(period).toString(shortDateFormat)} - ${Date.today().toString(shortDateFormat)}`;

export const getShortComparePeriod = (period: DatePeriod, compare: ComparePeriod) => {
	const [from, to] = getComparedPeriod(period, compare);
	return `${from.toString(shortDateFormat)} - ${to.toString(shortDateFormat)}`;
};
