import React from 'react';
import styled from '@emotion/styled';
import { palette } from 'constants/styles';
import Check from 'assets/images/check.svg';

export type ICheckboxTheme = 'primary' | 'secondary';

export type IRadioTheme = 'primary' | 'secondary';

interface ICheckbox {
	theme?: ICheckboxTheme;
	isChecked: boolean;
	onChange: (isChecked: boolean) => void;
}

const Checkbox: React.FC<ICheckbox> = ({ isChecked, onChange, theme = 'primary' }) => (
	<StyledCheckbox
		theme={theme}
		type="checkbox"
		checked={isChecked}
		onChange={(event) => onChange(event.target.checked)}
		onClick={(event) => {
			event.stopPropagation();
		}}
	/>
);

const StyledCheckbox = styled.input<{ theme: ICheckboxTheme }>`
	appearance: none;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 14px;
	height: 14px;
	border: 1px solid #979797;
	outline: 0;
	border-radius: 4px;
	font-size: 9px;
	font-weight: 100;
	cursor: pointer;

	&:checked {
		border: 1px solid ${({ theme }) => (theme === 'primary' ? palette.primaryColor : palette.darkGray)};
		background-color: ${({ theme }) => (theme === 'primary' ? palette.primaryColor : palette.darkGray)};

		&:after {
			width: 14px;
			height: 14px;
			content: '';
			background: url('${Check}') no-repeat center/contain;
		}
	}
`;

export default Checkbox;
