import { FC } from 'react';
import styled from '@emotion/styled';
import { palette } from 'constants/styles';
import { formatMoney } from 'utils/money';
import { useGetSessionItemsQuery } from '../redux';

interface IItems {
	sessionId: string;
}

export const ItemsTab: FC<IItems> = ({ sessionId }) => {
	const { data: items, isFetching } = useGetSessionItemsQuery({ sessionId });

	if (isFetching) {
		return <div>Loading items</div>;
	}

	if (items?.length === 0) {
		return <div>No items</div>;
	}

	return (
		<Container>
			{items?.map((item, index) => (
				<Item key={index}>
					<Image src={item.imgUrl} />

					<Designer>{item.designer ?? '-'}</Designer>
					<Price>
						{formatMoney({
							amount: item.price,
							currency: item.currency
						})}
					</Price>
				</Item>
			))}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const Item = styled.div`
	border: 1px solid #dbdbdb;
	text-align: center;
	margin-right: 5px;
	margin-bottom: 5px;
`;

const Designer = styled.div`
	margin-top: 3px;
	color: ${palette.lightGray};
	font-weight: 100;
	font-size: 14px;
`;

const Price = styled.div`
	margin: 3px 0;
	font-weight: 100;
	font-size: 14px;
`;

const Image = styled.div<{ src: string }>`
	height: 180px;
	width: 150px;
	background: url(${(props) => props.src}) no-repeat center/contain;
`;
