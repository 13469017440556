import React, { useState } from 'react';
import styled from '@emotion/styled';
import { palette } from 'constants/styles';
import { ReactComponent as Chevron } from 'assets/images/chevron.svg';
import RatingStars from 'components/rating-stars';
import CopyToClipboard from './copy-to-clipboard';
import { NavLink } from 'react-router-dom';

type SingleProperty = {
	label: string;
	value: string | number;
	isDanger?: boolean;
	link?: string;
};

interface IDetailsList {
	detailsList: SingleProperty[];
	title: string;
	isEditable: boolean;
	onEditClick?: () => void;
}

const copyToClipboardFields = ['Firebase token', 'Email', 'Shipping address'];
const starsFields = ['Rating', 'Satisfaction'];
const linkFields = ['Client', 'Full Name'];

const DetailsList: React.FC<IDetailsList> = ({ detailsList, title, isEditable, onEditClick }) => {
	const [isContentVisible, setIsContentVisible] = useState(true);

	const getRatingValue = (value: string | number) => {
		return <Value>{Number.isInteger(value) ? <RatingStars number={Number(value)} /> : '—'}</Value>;
	};

	const getClipboardValue = (label: string, value: any) => <CopyToClipboard name={label} value={String(value)} />;

	const getLinkValue = (value: string | number, link: string) => <Link to={link}>{value}</Link>;

	const getSimpleValue = (value: any, isDanger?: boolean) => <Value isDanger={isDanger}>{value || '—'}</Value>;

	return (
		<Container>
			<ContainerHeader>
				<Title onClick={() => setIsContentVisible(!isContentVisible)}>
					<ChevronContainer isContentVisible={isContentVisible}>
						<Chevron />
					</ChevronContainer>
					{title}
				</Title>
				{isEditable && <EditButton onClick={onEditClick}>Edit</EditButton>}
			</ContainerHeader>

			{isContentVisible && (
				<ListContainer>
					{detailsList.map(({ label, value, link, isDanger }) => {
						return (
							<ListItemContainer key={label}>
								<Subject>{label}</Subject>
								{starsFields.includes(label)
									? getRatingValue(value)
									: copyToClipboardFields.includes(label)
									? getClipboardValue(label, value)
									: linkFields.includes(label)
									? getLinkValue(value, link as string)
									: getSimpleValue(value, isDanger)}
							</ListItemContainer>
						);
					})}
				</ListContainer>
			)}
		</Container>
	);
};

const Container = styled.div`
	margin-top: 40px;
`;

const ContainerHeader = styled.div`
	display: flex;
	justify-content: space-between;
`;

const Title = styled.div`
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	cursor: pointer;
	display: flex;
`;

const EditButton = styled.div`
	color: ${palette.primaryColor};
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
`;

const ChevronContainer = styled.span<{ isContentVisible: boolean }>`
	width: 12px;
	height: 12px;
	margin-top: 2px;
	margin-right: 8px;
	display: inline-flex;
	align-items: center;
	transform: rotate(${({ isContentVisible }) => (isContentVisible ? '180deg' : '0')});
	transition: transform 0.2s ease-in-out;
`;

const ListContainer = styled.div`
	padding: 20px 24px 0 24px;
`;

const ListItemContainer = styled.div`
	margin-bottom: 9px;
`;

const Subject = styled.div`
	font-size: 12px;
	color: ${palette.lightGray};
	font-weight: 300;
	margin-bottom: 5px;
`;

const Value = styled.div<{ isDanger?: boolean }>`
	font-size: 12px;
	font-weight: 300;
	color: ${(props) => (props.isDanger ? palette.dangerColor : palette.black)};
`;

const Link = styled(NavLink)`
	font-size: 12px;
	font-weight: 400;
	color: ${palette.primaryColor};
	text-decoration: none;
`;

export default DetailsList;
