import rootReducer from 'redux/reducers/root';
import { apiMiddleware } from 'redux/middleware/api';
import { configureStore } from '@reduxjs/toolkit';
import { PreloadedState } from 'redux';
import { emptySplitApi } from './base-api';

const isDev = process.env.NODE_ENV !== 'production';

export function setupStore(reducer: any = rootReducer, preloadedState?: PreloadedState<RootState>) {
	return configureStore({
		reducer,
		devTools: isDev,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false
			}).concat(emptySplitApi.middleware, apiMiddleware),
		preloadedState
	});
}

const store = setupStore();

export default store;
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
