import { UserRecord } from 'routes/users/redux/types';
import { numberToCurrency } from 'utils/money';
import { CheckboxColumn, renderCheckboxColumn, renderOptionalCell } from 'components/table/table-utils';
import { formatDateWithTime } from 'utils/dates';
import { B2CPartnerId } from 'constants/global-consts';
import { ColumnType } from 'rc-table/es/interface';
import { ActivityRecord } from '../../constants/types';
import { TPartnerId } from 'redux/partners-slice';

const columnsConfigurationByPartner: Record<TPartnerId | 'default', any> = {
	[B2CPartnerId]: [
		{
			title: 'Created',
			dataIndex: 'created',
			key: 'date',
			render: formatDateWithTime
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			render: renderOptionalCell
		},
		{
			title: 'Username',
			dataIndex: 'username',
			key: 'username',
			render: renderOptionalCell
		},
		{
			title: 'Wishi ID',
			dataIndex: 'wishiId',
			key: 'wishiId',
			render: renderOptionalCell
		},
		{
			title: 'stripe ID',
			dataIndex: 'stripeId',
			key: 'stripeId',
			render: renderOptionalCell
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			render: renderOptionalCell
		},
		{
			title: 'Spend',
			dataIndex: 'spend',
			key: 'spend',
			render: numberToCurrency
		}
	],
	default: [
		{
			title: 'Created',
			dataIndex: 'created',
			key: 'date',
			render: formatDateWithTime
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			render: renderOptionalCell
		},
		{
			title: 'Username',
			dataIndex: 'username',
			key: 'username',
			render: renderOptionalCell
		},
		{
			title: 'Wishi ID',
			dataIndex: 'wishiId',
			key: 'wishiId',
			render: renderOptionalCell
		},
		{
			title: 'Partner User Id',
			dataIndex: 'partnerUserId',
			key: 'partnerUserId',
			render: renderOptionalCell
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			render: renderOptionalCell
		},
		{
			title: 'Spend',
			dataIndex: 'spend',
			key: 'spend',
			render: numberToCurrency
		}
	],
	combined: [
		{
			title: 'Created',
			dataIndex: 'created',
			key: 'date',
			render: formatDateWithTime
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			render: renderOptionalCell
		},
		{
			title: 'Partner',
			dataIndex: 'partnerName',
			key: 'partnerName',
			render: renderOptionalCell
		},
		{
			title: 'Username',
			dataIndex: 'username',
			key: 'username',
			render: renderOptionalCell
		},
		{
			title: 'Wishi ID',
			dataIndex: 'wishiId',
			key: 'wishiId',
			render: renderOptionalCell
		},
		{
			title: 'Partner User Id',
			dataIndex: 'partnerUserId',
			key: 'partnerUserId',
			render: renderOptionalCell
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			render: renderOptionalCell
		}
	],
};

export const getColumnsConfig = (partnerId: TPartnerId | undefined, options: CheckboxColumn<UserRecord>) => {
	const id = !parseInt(partnerId?? '') ? 'combined' : partnerId;
	const columnsConfig =
		columnsConfigurationByPartner[id as TPartnerId] || columnsConfigurationByPartner.default;
	return [renderCheckboxColumn<UserRecord>(options), ...columnsConfig];
};

export const getUserDetailsColumnsConfig: ColumnType<ActivityRecord>[] = [
	{
		title: 'Created',
		dataIndex: 'date',
		key: 'date',
		render: renderOptionalCell
	},
	{
		title: 'Action',
		dataIndex: 'action',
		key: 'action',
		render: renderOptionalCell
	},
	{
		title: 'Amount',
		dataIndex: 'amount',
		key: 'amount',
		render: numberToCurrency
	},
	{
		title: 'More',
		dataIndex: 'more',
		key: 'more',
		render: renderOptionalCell
	}
];
