import { handleActions } from 'redux-actions';
import { DashboardState } from 'routes/dashboard/redux/types';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { State } from 'redux/types';
import { FETCH_METRICS, SET_METRICS, setMetrics } from 'routes/dashboard/redux/actions';
import { ComparePeriod, DatePeriod } from 'routes/dashboard/utils/dates';
import { mapToDashboardStructure } from 'routes/dashboard/utils/maps';

require('datejs');

const initialState: DashboardState = {
	metrics: [],
	range: DatePeriod.LAST_4_WEEKS,
	compare: ComparePeriod.PREVIOUS_PERIOD
};

export const dashboardReducer = handleActions(
	{
		[FETCH_METRICS]: (state) => ({
			...state,
			metrics: []
		}),

		[SET_METRICS]: (state, action: ReturnType<typeof setMetrics>) => ({
			...state,
			metrics: mapToDashboardStructure(action.payload)
		})
	},
	initialState
);

export const useDashboardSelector: TypedUseSelectorHook<DashboardState> = (selector, ...args) =>
	useSelector((state: State) => selector(state.dashboard), ...args);
