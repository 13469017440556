import React, { useState } from 'react';
import Modal, { ModalActions, ModalBody, ModalText } from 'components/modal';
import Button from 'components/button';
import { Select } from 'components/form/select';
import { OrderStatus } from 'routes/orders/redux/types';

interface IUpdateStatusModal {
	onClose: () => void;
	onSave: (status: OrderStatus) => void;
}

const UpdateOrderModal: React.FC<IUpdateStatusModal> = ({ onClose, onSave }) => {
	const [status, setStatus] = useState<OrderStatus>(OrderStatus.open);

	return (
		<Modal title="Update order status" onClose={onClose}>
			<ModalBody>
				<ModalText>Status:</ModalText>
				<Select
					name={`order.status`}
					value={status}
					onChange={(event) => setStatus(event.target.value as OrderStatus)}
				>
					{Object.keys(OrderStatus).map((key) => (
						<option value={key} key={key}>
							{key}
						</option>
					))}
				</Select>
				<ModalActions>
					<Button color="primary" onClick={() => onSave(status)}>
						Save
					</Button>
				</ModalActions>
			</ModalBody>
		</Modal>
	);
};

export default UpdateOrderModal;
