import React, { useState } from 'react';
import styled from '@emotion/styled';

import Modal, { ModalActions, ModalBody, ModalFooter } from 'components/modal';
import Button from 'components/button';
import InputController from 'components/form/input-controller';
import RadioGroupController from 'components/form/radio-group-controller';
import { formatDateForController } from 'utils/dates';
import SelectController from 'components/form/select-controller';
import { getEnumKeys } from 'utils/helpers';
import ErrorBar from 'components/error-bar';
import {
	TUserId,
	Gender,
	UserBodyEnum,
	UserData,
	UserDetailsResponse,
	useAddUserMutation,
	useUpdateUserMutation
} from '../redux';
import { TAPIError } from 'redux/types';

interface IEditUserModal {
	action: 'add' | 'edit';
	onClose: () => void;
	userId?: TUserId;
	user?: UserDetailsResponse;
}

const genders = [
	{ value: 'male', label: 'Male' },
	{ value: 'female', label: 'Female' },
	{ value: 'neutral', label: 'Neutral' }
];

export const AddEditUserModal: React.FC<IEditUserModal> = ({ onClose, action, userId = '', user }) => {
	const [form, setForm] = useState<UserData>({
		userFirstName: user?.generalInfo.firstName || '',
		userLastName: user?.generalInfo.lastName || '',
		userEmail: user?.technicalInfo.email || '',
		userName: user?.generalInfo.username || '',
		userPhoneNumber: '',
		userBirthDate: formatDateForController(user?.personalInfo.birthday),
		userLocations: user?.personalInfo.location || '',
		userBodyType: (user?.personalInfo.bodyType as keyof UserBodyEnum) || '',
		userGender: (user?.personalInfo.gender as Gender) || ''
	});
	const addUserMutation = useAddUserMutation();
	const updateUserMutation = useUpdateUserMutation();
	const [mutation, { isLoading, error }] = action === 'add' ? addUserMutation : updateUserMutation;
	const title = action === 'add' ? 'Add new user' : 'Edit user';
	const buttonText = action === 'add' ? 'Add new user' : 'Update user';
	const errorMessage = error && (error as TAPIError).data.message;

	const submitForm = async () => {
		const result = await mutation({ userId, userData: form });

		if (!('error' in result)) {
			onClose();
		}
	};

	const onChange = (property: string, value: string) => {
		setForm((currentFormState: UserData) => ({
			...currentFormState,
			[property]: value
		}));
	};

	return (
		<Modal title={title} onClose={onClose}>
			{errorMessage && <ErrorBar>{Array.isArray(errorMessage) ? errorMessage[0] : errorMessage}</ErrorBar>}
			<form
				onSubmit={(event) => {
					event.preventDefault();
					submitForm();
				}}
			>
				<ModalBody>
					<Fieldset>
						<Label htmlFor="userFirstName">First name</Label>
						<InputController
							name="userFirstName"
							value={form.userFirstName}
							onChange={(event) => onChange(event.target.name, event.target.value)}
						/>
					</Fieldset>
					<Fieldset>
						<Label htmlFor="userLastName">Last name</Label>
						<InputController
							name="userLastName"
							value={form.userLastName}
							onChange={(event) => onChange(event.target.name, event.target.value)}
						/>
					</Fieldset>
					<Fieldset>
						<Label htmlFor="userName">Username</Label>
						<InputController
							name="userName"
							value={form.userName}
							onChange={(event) => onChange(event.target.name, event.target.value)}
						/>
					</Fieldset>
					<Fieldset>
						<Label htmlFor="userGender">Gender</Label>
						<RadioGroupController
							name="userGender"
							value={form.userGender}
							onChange={(value) => onChange('userGender', value)}
							items={genders}
						/>
					</Fieldset>
					<Fieldset>
						<Label htmlFor="userEmail">Email</Label>
						<InputController
							name="userEmail"
							value={form.userEmail}
							onChange={(event) => onChange(event.target.name, event.target.value)}
						/>
					</Fieldset>
					<Fieldset>
						<Label htmlFor="userBirthDate">Birthday</Label>
						<InputController
							type="date"
							name="userBirthDate"
							value={form.userBirthDate}
							onChange={(event) => onChange(event.target.name, event.target.value)}
						/>
					</Fieldset>
					<Fieldset>
						<Label htmlFor="userLocations">Location</Label>
						<InputController
							name="userLocations"
							value={form.userLocations}
							onChange={(event) => onChange(event.target.name, event.target.value)}
						/>
					</Fieldset>
					<Fieldset>
						<Label htmlFor="userBodyType">Body type</Label>
						<SelectController
							name="userBodyType"
							options={getEnumKeys(UserBodyEnum)}
							value={form.userBodyType}
							onChange={(event) => onChange(event.target.name, event.target.value)}
						/>
					</Fieldset>
					{action === 'add' && (
						<Fieldset>
							<Label htmlFor="userPassword">Password</Label>
							<InputController
								name="userPassword"
								value={form.userPassword}
								onChange={(event) => onChange(event.target.name, event.target.value)}
							/>
						</Fieldset>
					)}
				</ModalBody>
				<ModalFooter>
					<ModalActions>
						<Button color="primary" disabled={isLoading}>
							{buttonText}
						</Button>
					</ModalActions>
				</ModalFooter>
			</form>
		</Modal>
	);
};

const Fieldset = styled.fieldset`
	border: 0;
	margin-bottom: 1rem;
`;

const Label = styled.label`
	display: block;
	text-transform: uppercase;
	font-size: 0.9rem;
	margin-bottom: 0.5rem;
`;
