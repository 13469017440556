export const SET_API_TOKEN = '[auth] SET_API_TOKEN';
export const SIGN_OUT = '[auth] SIGN_OUT';

export const setAPIToken = (apiToken: string) => ({
	type: SET_API_TOKEN,
	payload: {
		apiToken
	}
});

export const signOut = () => ({
	type: SIGN_OUT
});
