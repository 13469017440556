import React, { useState } from 'react';

import Button from 'components/button';
import Modal, { ModalActions, ModalBody, ModalText } from 'components/modal';
import { Textarea } from 'components/form/textarea';

interface IAddNoteModal {
	onClose: () => void;
	onSave: (note: string) => void;
}

const AddNoteModal: React.FC<IAddNoteModal> = ({ onClose, onSave }) => {
	const [note, setNote] = useState('');

	return (
		<Modal title="Add note" onClose={onClose}>
			<ModalBody>
				<ModalText>Your note will be visible to all admin viewers</ModalText>
				<Textarea value={note} onChange={(event) => setNote(event.target.value)} />
				<ModalActions>
					<Button color="primary" onClick={() => onSave(note)}>
						Save
					</Button>
				</ModalActions>
			</ModalBody>
		</Modal>
	);
};

export default AddNoteModal;
