import React from 'react';
import styled from '@emotion/styled';
import LoginForm from './components/login-form';
import heroImage from 'assets/images/login-cover-image.png';
import textLogo from 'assets/images/wishi-text-logo.png';
import { useSearchParams } from 'react-router-dom';
import { loginParams } from 'routes/login/consts';

const LoginPage = () => {
	const [searchParams] = useSearchParams();
	const message = searchParams.get(loginParams.message) ?? '';

	return (
		<Container>
			<CoverImage />

			<FormContainer>
				<LogoContainer>
					<Logo />
				</LogoContainer>
				<LoginTitle>Welcome to Wishi’s admin</LoginTitle>
				<LoginForm message={message} />
			</FormContainer>
		</Container>
	);
};

const Container = styled.div`
	height: 100%;
	display: flex;
`;

const CoverImage = styled.div`
	width: 50%;
	background: url('${heroImage}') no-repeat top/cover;
`;

const LogoContainer = styled.div`
	padding: 70px;
`;

const Logo = styled.div`
	background: url('${textLogo}') no-repeat center/contain;
	width: 102px;
	height: 31px;
	margin: auto;
`;

const LoginTitle = styled.div`
	font-size: 2rem;
	color: white;
	margin: auto;
	text-align: center;
	text-shadow: 5px 5px 5px rgba(255, 255, 255, 0.4);
`;

const FormContainer = styled.div`
	position: relative;
	width: 50%;
	background-color: #000;
`;

export default LoginPage;
