import styled from '@emotion/styled';

export const Input = styled.input`
	width: 100%;
	padding: 0.33rem;
	border-radius: 0.33rem;
	box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.1);
	border: 1px solid #aaa;
	outline: 0;
`;
