import React, { InputHTMLAttributes } from 'react';
import styled from '@emotion/styled';

interface ITextController extends InputHTMLAttributes<HTMLInputElement> {
	disabled?: boolean;
}

const InputController: React.FC<ITextController> = ({ type = 'text', name, value, onChange, disabled }) => (
	<Input type={type} name={name} id={name} value={value} onChange={onChange} disabled={disabled} />
);

const Input = styled.input`
	width: 100%;
	padding: 0.33rem;
	border-radius: 0.33rem;
	box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.1);
	border: 1px solid #aaa;
	outline: 0;
`;

export default InputController;
